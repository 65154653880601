import React, { Component, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, DatePicker, Input, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import axios from "axios";
import '../../App.css';
const Mahasabha = (props) => {
  let familyId;

  const history = useHistory();
  const [userData, setUserData] = useState([]);
  const [imagePath, setImagePath] = useState([]);
  const path = imagePath;
  const memberDetailList = userData.map((memberDetails) => memberDetails);
  let isHead = userData.find((memberDetails) => memberDetails.is_head == "1");
  const headingStyle = {
    color: "white",
    backgroundColor: "#4D4D4D",
    paddingTop: "6em",
    paddingBottom: "1em",
  };

  const AddMahasabhaMember = () => {
    history.push({ pathname: "/AddMahasabha" });
  };

  useEffect(() => {
    if (localStorage.getItem("loggedfamilyId") != undefined) {
      familyId = localStorage.getItem("loggedfamilyId");
    }

    if (
      localStorage.getItem("loggedToken") == undefined &&
      localStorage.getItem("loggedUserId") == undefined
    ) {
      history.push({ pathname: "/login" });
    }

    getUserDetailsByID();
  }, []);

  const GoToChetna = () => {
    history.push({ pathname: "/chetna" });
  };

  const getUserDetailsByID = async (e) => {
    try {
      const postData = {
        user_id:
          loggedUserId == undefined
            ? localStorage.getItem("loggedUserId")
            : loggedUserId,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            token == undefined ? localStorage.getItem("loggedToken") : token
          }`,
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/viewownmember",
        body,
        config
      );
      if (res.data.data.data.length > 0) {
        setUserData(res.data.data.data);
        setImagePath(res.data.imagepath);
      } else {
        alert("Error while fetching member details");
        return false;
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };
  return (
    <div>
      <div style={{ backgroundColor: "#FFFBCC", height: "25em" }}>
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            पारिवारिक{" "}
            <b style={{ color: "#e47257" }}>
              विवरण
              <p style={{ color: "White" }}>मुख्य पृष्ठ / पारिवारिक विवरण</p>
            </b>
          </h2>
        </div>
      </div>
      <div className="row" style={{ backgroundColor: "#FFFBCC"}}>
        <div className="col-md-8 column">
          <div
            className="row"
            style={{ paddingLeft: "10em", backgroundColor: "#FFFBCC" }}
          >
            <div>
              <label htmlFor="MemberName">
                <b>परिवार ID No.</b>
              </label>
              <Input
                id="MemberID"
                name="memberID"
                className="rounded"
                disabled={true}
                value={
                  familyId == undefined
                    ? localStorage.getItem("loggedfamilyId")
                    : familyId
                }
              />
            </div>
          </div>
          <div
            className="row"
            style={{ paddingLeft: "10em", backgroundColor: "#FFFBCC" }}
          >
            <div className="col-5">
              <Button
                type="button"
                style={{ backgroundColor: "#660000" }}
                size="large"
                className="btnText rounded mt-2"
              >
                <span style={{ color: "white" }}> + परिवार सदस्य जोड़े</span>
              </Button>
            </div>
            <div className="col-3 text-right">
              <Button
                type="button"
                style={{ backgroundColor: "#660000" }}
                size="large"
                className="btnText rounded mt-2"
              >
                <span style={{ color: "white" }}> + महासभा से जुड़े</span>
              </Button>
            </div>
            <div className="col-2">
              <Button
                type="button"
                style={{ backgroundColor: "#660000" }}
                size="large"
                className="btnText rounded mt-2"
              >
                <span style={{ color: "white" }} onClick={GoToChetna}>
                  {" "}
                  + चेतना सम्मान 2021
                </span>
              </Button>
            </div>
          </div>
          <div
            className="row"
            style={{ paddingLeft: "10em", backgroundColor: "#FFFBCC" }}
          >
            <div className="mt-5">
              <center>
                <h4>परिवार के सभी सदस्यों की जानकारी</h4>
              </center>
            </div>
          </div>
          <div
            className="row"
            style={{ paddingLeft: "10em", backgroundColor: "#FFFBCC" }}
          >
            <div>
              <Button
                type="button"
                style={{ backgroundColor: "#660000" }}
                size="large"
                className="btnText rounded mt-2"
              >
                <span style={{ color: "white" }} onClick={AddMahasabhaMember}>
                  {" "}
                  + Add New
                </span>
              </Button>
            </div>
          </div>
          <div
            className="row"
            style={{ paddingLeft: "10em", backgroundColor: "#FFFBCC" }}
          >
            <div className="mt-2">
              <div className="cart-table">
                <table className="main-table table-responsiv">
                  <tbody>
                    <tr>
                      <th className="col1">नाम</th>
                      <th className="col2">सदस्य ID No.</th>
                      <th className="col2">पिता / पति का नाम</th>
                      <th className="col2">पंजीकृत</th>
                      <th className="col2">स्थति</th>
                      <th className="col2">Action</th>
                    </tr>
                    {memberDetailList.map((e, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            <img
                              style={{ width: "60px", height: "50px" }}
                              src={path + "/" + e.profile_image}
                              alt=""
                            />
                            {e.is_head == "1" ? (
                              <Link
                                to={{
                                  pathname: "/member_profile",
                                  state: { userIdToBeEdited: e.id },
                                }}
                              >
                                {e.mobile}
                              </Link>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/family_member",
                                  state: { userIdToBeEdited: e.id },
                                }}
                              >
                                {e.mobile}
                              </Link>
                            )}
                          </td>
                          <td>{e.unique_id}</td>
                          <td>{e.father_name}</td>
                          <td>{e.is_head == "1" ? "Family Head" : "Member"}</td>
                          <td>
                            <span>
                              {e.final_approval == "1" ? "Approved" : "Pending"}
                            </span>
                          </td>
                          <td>
                            <a href="/copymember/7390">Copy</a> <br /> | <br />
                            {e.is_head == "1" ? (
                              <Link
                                to={{
                                  pathname: "/member_profile",
                                  state: { userIdToBeEdited: e.id },
                                }}
                              >
                                Edit
                              </Link>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/family_member",
                                  state: { userIdToBeEdited: e.id },
                                }}
                              >
                                Edit
                              </Link>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <aside class="col-md-3 sidebar column">
          <div class="widget">
            <a href="#" target="_blank">
              <img
                itemprop="image"
                src="https://golapurvajainsamaj.com/public/upload/advertisement/382166.jpg"
                width="100%"
                alt=""
              />
            </a>
          </div>
          <div class="widget mb-5">
            <a href="#" target="_blank">
              <img
                itemprop="image"
                src="https://golapurvajainsamaj.com/public/upload/advertisement/382166.jpg"
                width="100%"
                alt=""
              />
            </a>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Mahasabha;

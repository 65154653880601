import React from "react";
import { Link } from "react-router-dom";

const MainFooter = (props) => {
  return (
    <div className="block">
      <div className="container">
        <div className="footer-widgets">
          <div className="row">
            <div className="col-md-3 col-sm-6 column">
              <div className="widget">
                <div className="contact-widget">
                  <h5>
                    हमारा <span>परिचय</span>
                  </h5>
                  <p>
                    भरत क्षेत्र में अवसर्पिणी काल के तीसरे सुखमा दुखमा काल के
                    अंत समय में भोगभूमि का अवसान और कर्म भूमि के प्रादुर्भाव कल
                    में चौदह कुलकरो में अंतिम कुलकर नाभिराय-मरुदेवी के प्रथम
                    तीर्थकर ऋषभनाथ हुए ।
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-6 col-md-2 col-sm-6 column">
              <div className="widget">
                <div className="widget-title">
                  <h4>
                    उपयोगी <span>लिंक</span>
                  </h4>
                </div>
                <ul>
                  <li>
                    <Link to="/" title="">
                      मुख्य पृष्ठ
                    </Link>
                  </li>
                  <li>
                    <Link to="contact" title="">
                      संपर्क
                    </Link>
                  </li>
                  <li>
                    <Link to="feedback" title="">
                      फीडबैक
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-6 col-md-2 col-sm-6 column">
              <div className="widget">
                <div className="widget-title">
                  <h4>
                    उपयोगी <span>लिंक</span>
                  </h4>
                </div>
                <ul>
                  <li>
                    <Link
                      to={{
                        pathname: "/PhotoGallery",
                      }}
                      title=""
                    >
                      फोटो गैलरी
                    </Link>
                  </li>
                  <li>
                    <Link to="/old_members" title="">
                      महासभा के सदस्य{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="e_patrika" title="">
                      ई-पत्रिका
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 column">
              <div className="widget">
                <div className="widget-title">
                  <h4>
                    उपयोगी <span>लिंक</span>
                  </h4>
                </div>
                <ul>
                  <li>
                    <Link to="/consultant" title="">
                      सलाहकार
                    </Link>
                  </li>
                  <li>
                    <Link to={{
                              pathname: "/janganna"
                            }} title="">
                      जनगणना
                    </Link>
                  </li>
                  <li>
                    <Link to="/sanskar" title="">
                      मेट्रोमोनियल
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 column">
              <div className="widget">
                <div className="contact-widget">
                  <h5>
                    <span>संपर्क</span>
                  </h5>
                  <ul>
                    <li>
                      <i className="fa fa-map-marker"></i>द्वितीय तल, तीर्थकर
                      परिसर, डॉ.प. पन्नालाल जैन साहित्याचार्य मार्ग,कटरा बाजार,
                      सागर (म.प्र.)
                    </li>
                    <li>
                      <i className="fa fa-phone"></i>07582-243101
                    </li>
                    <li>
                      <i className="fa fa-envelope"></i>
                      golapurvmahasabha@gmail.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;

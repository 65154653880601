import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "antd/dist/antd.css";
import axios from "axios";
import { Zoom } from "react-slideshow-image";
import Image from "react-image-enlarger";
import "react-slideshow-image/dist/styles.css";
import VolunteerImage from "../../assets/img/volunteer.jpg";

const zoomOutProperties = {
  duration: 3000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  scale: 0.4,
  arrows: true,
};

function SingleSource({ src }) {
  const [zoomed, setZoomed] = useState(false);

  return (
    <div className="col-3">
      <div style={{ margin: "0.25rem" }}>
        <Image
          style={{ width: "272px", height: "197px" }}
          zoomed={zoomed}
          src={src}
          onClick={() => setZoomed(true)}
          onRequestClose={() => setZoomed(false)}
        />
      </div>
    </div>
  );
}

const LandingPage = () => {
  const [fadeImages, setFadeImages] = useState([]);
  const [patrikas, setPatrikas] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [events, setEvents] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [pdfPath, setPDFPath] = useState("");
  useEffect(() => {
    getHomeBannerData();
  }, []);

  function PatrikaSrc({ src, detail }) {
    let pdfLink = pdfPath + "/" + detail.file2;
    const [zoomed, setZoomed] = useState(false);

    return (
      <div className="col-3">
        <div style={{ margin: "0.25rem" }}>
          <Image
            style={{ width: "338px", height: "350px" }}
            zoomed={zoomed}
            src={src}
            onClick={() => setZoomed(true)}
            onRequestClose={() => setZoomed(false)}
          />
        </div>
        <div class="story-detail">
          <center>
            <h3>
              <a itemprop="url" href={pdfLink} title="">
                {detail.content_1}
              </a>
            </h3>
          </center>
        </div>
      </div>
    );
  }

  const Slideshow = () => {
    return (
      <div className="slide-container">
        <Zoom {...zoomOutProperties}>
          {fadeImages.map((each, index) => (
            <img
              key={index}
              style={{ width: "100%", height: "575px" }}
              src={imagePath + "/" + each.file1}
            />
          ))}
        </Zoom>
      </div>
    );
  };

  const getHomeBannerData = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/home",
        config
      );
      if (res.data.success == 1) {
        setImagePath(res.data.file1);
        setPDFPath(res.data.file2);
        setFadeImages(res.data.homebanner);
        setGalleryImages(res.data.photo_galarys);
        setPatrikas(res.data.Patrikas);
        setEvents(res.data.events);
      } else {
        alert("Error while fetching member details");
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h6 style={{ backgroundColor: "#F1F1F1", marginBottom: "0" }}>&nbsp;</h6>
      <Slideshow />
      <section style={{ background: "#f1f1ff" }}>
        <div class="block whitish parallax-sec">
          <div class="container">
            <div class="row">
              <div class="col-md-12 column">
                <div class="urgent-cause">
                  <div class="row">
                    <div class="col-md-12 column">
                      <div class="urgentcause-detail">
                        <center>
                          <h3
                            style={{
                              color: "#600",
                              float: "left",
                              fontSize: "24px",
                              fontWeight: "700",
                              margin: "0 0 20px",
                              textTransform: "uppercase",
                              width: "100%",
                            }}
                          >
                            अखिल भारतवर्षीय दिगम्बर जैन गोलापूर्व महासभा
                          </h3>
                          <p
                            style={{
                              fontSize: "15px",
                              fontFamily: "arimo",
                              color: "#505050",
                              lineHeight: "28px",
                            }}
                          >
                            भरत क्षेत्र में अवसर्पिणी काल के तीसरे सुखमा दुखमा
                            काल के अंत समय में भोगभूमि का अवसान और कर्म भूमि के
                            प्रादुर्भाव काल में चौदह कुलकरो में अंतिम कुलकर
                            नाभिराय-मरुदेवी के प्रथम तीर्थकर ऋषभनाथ हुए । भगवान
                            ऋषभ देव ने कर्म भूमि के प्रारम्भ में जहाँ असि, मसि,
                            कृषि, शिल्प, विद्या और वाणिज्य आदि षट्कर्म का उपदेश
                            दिया और कर्म के आधार पर क्षत्रिय, वैश्य और षूद्व
                            वर्ण की स्थापना की। वही सामाजिक व्यवस्था को
                            व्यवस्थित बनाने के लिए सोमवंश, उग्रवंश और नाथवंश आदि
                            वंशो की स्थापना की। आप स्वयं इक्ष्वाकुवंशी कहलाये।
                            इन्ही वंशो का आगे विस्तार होता गया और उनके वंशो का
                            उद्भव होता गया। अंत�...
                          </p>
                          <p>
                            <Link
                              to={{
                                pathname: "/AboutUs"
                              }}
                            >
                              Read More <i class="fa fa-angle-double-right"></i>{" "}
                            </Link>
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style={{ backgroundColor: "#fffbcc", paddingBottom: "2em" }}>
        <div>
          <h2
            style={{
              color: "#3f3f3f",
              float: "left",
              fontSize: "28px",
              fontWeight: "600",
              marginTop: "1em",
              width: "100%",
            }}
          >
            फोटो{" "}
            <span
              style={{
                color: "#3f3f3f",
                fontSize: "24px",
                fontWeight: "300",
                marginTop: "1em",
                width: "100%",
              }}
            >
              गैलरी
            </span>
          </h2>
        </div>
        <div className="galleryImages row">
          {galleryImages.map((image) => (
            <SingleSource key={image} src={imagePath + "/" + image.file1} />
          ))}
        </div>
      </div>
      <section style={{ background: "#f1f1ff" }}>
        <div class="block">
          <div class="container">
            <div class="row">
              <div class="col-md-12 column">
                <div class="title">
                  <h2
                    style={{
                      color: "#3f3f3f",
                      float: "left",
                      fontSize: "28px",
                      fontWeight: "600",
                      marginTop: "1em",
                      width: "100%",
                    }}
                  >
                    गोलापूर्व जैन
                    <span
                      style={{
                        color: "#3f3f3f",
                        fontSize: "24px",
                        fontWeight: "300",
                        marginTop: "1em",
                        width: "100%",
                      }}
                    >
                      {" "}
                      त्रैमासिक पत्रिका
                    </span>
                  </h2>
                  <span></span>
                </div>
                <div class="successful-stories">
                  <div class="row">
                    {patrikas.map((image) => (
                      <PatrikaSrc
                        key={image}
                        src={imagePath + "/" + image.file1}
                        detail={image}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="block gray">
          <div class="container">
            <div class="row">
              <div class="col-md-8 column">
                <div class="title style2">
                  <h2
                    style={{
                      color: "#3f3f3f",
                      float: "left",
                      fontSize: "28px",
                      fontWeight: "600",
                      marginTop: "1em",
                      width: "100%",
                    }}
                  >
                    आगामी{" "}
                    <span
                      style={{
                        color: "#3f3f3f",
                        fontSize: "24px",
                        fontWeight: "300",
                        marginTop: "1em",
                        width: "100%",
                      }}
                    >
                      कार्यक्रम
                    </span>
                  </h2>
                </div>
                <div className="events">
                  <div class="event-toggle">
                    {events.map((each) => (
                      <div>
                        <div>
                          {each.content_1 != null && each.content_2 != null ? (
                            <strong>
                              From {each.content_1} to {each.content_2}
                            </strong>
                          ) : each.content_1 != null ? (
                            <strong>{each.content_1}</strong>
                          ) : (
                            ""
                          )}
                        </div>
                        <div class="event-bar">
                          <div class="col-xs-7">
                            <div
                              className="eventDesc"
                              dangerouslySetInnerHTML={{
                                __html: each.bodytext,
                              }}
                            />
                          </div>
                          <hr />
                        </div>
                      </div>
                    ))}
                    ;
                  </div>
                </div>
              </div>
              <div class="col-md-4 column">
                <div class="volunteer">
                  <img itemprop="image" src={VolunteerImage} alt="" />
                  <div className="volunteer-overlay">
                    <div className="volunteer-inner">
                      <span>
                        अखिल भारतवर्षीय दिगम्बर जैन गोलापूर्व महासभा में सदस्य
                        के रूप में{" "}
                      </span>
                      <strong style={{ color: "#e47257" }}>
                        आपका स्वागत है
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;

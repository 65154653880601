import React, { Component, useState, useEffect } from "react";
import render from "react-dom";
import { Button, DatePicker, Input, Select } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import "../../App.css";
import Image from "react-image-enlarger";

const headingStyle = {
  color: "white",
  backgroundColor: "#4D4D4D",
  paddingTop: "6em",
  paddingBottom: "1em",
};

function SingleSource({ src }) {
    const [zoomed, setZoomed] = useState(false);
  
    return (
      <div className="col-3 mb-3">
        <div style={{ margin: "0.25rem" }}>
          <Image
            style={{ width: "270px", height: "324x" }}
            zoomed={zoomed}
            src={src}
            onClick={() => setZoomed(true)}
            onRequestClose={() => setZoomed(false)}
          />
        </div>
      </div>
    );
  }

const ShubhkaamnaMessage = () => {
    const [galleryImages, setGalleryImages] = useState([]);
    const [imagePath, setImagePath] = useState("");
    useEffect(() => {
        getMessageData();
      }, []);

  const getMessageData = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/staticlists/message",
        config
      );
      if (res.data.success == 1) {
        setImagePath(res.data.file1);
        setGalleryImages(res.data.records);
      } else {
        alert("Error while fetching member details");
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: "#FFFBCC" }}>
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
          शुभकामना{" "}
            <b style={{ color: "#e47257" }}>
            सन्देश
              <p style={{ color: "White" }}>मुख्य पृष्ठ / शुभकामना सन्देश</p>
            </b>
          </h2>
        </div>   
        <div className="galleryImages row">
          {galleryImages.map((image) => (
            <SingleSource key={image} src={imagePath + "/" + image.file1} />
          ))}
        </div>    
      </div>
    </div>
  );
};

export default ShubhkaamnaMessage;

import React, { Component, useState, useEffect } from "react";
import render from "react-dom";
import { Button, DatePicker, Input, Select } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import "../../App.css";
import Image from "react-image-enlarger";
import { Pagination } from "antd";

const headingStyle = {
  color: "white",
  backgroundColor: "#4D4D4D",
  paddingTop: "6em",
  paddingBottom: "1em",
};

function SingleSource({ src }) {
  const [zoomed, setZoomed] = useState(false);

  return (
    <div className="col-3 mb-3">
      <div style={{ margin: "0.25rem" }}>
        <Image
          zoomed={zoomed}
          src={src}
          onClick={() => setZoomed(true)}
          onRequestClose={() => setZoomed(false)}
        />
      </div>
    </div>
  );
}

const PhotoGallery = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [total, setTotal] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const totalCount = total;
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  useEffect(() => {
    getMessageData();
    onChange(1);
  }, []);

  const onChange = (value) => {
    setCurrent(value);
    setMinValue((value - 1) * pageSize);
    setMaxValue(value * pageSize);
  };

  const getMessageData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/staticlists/gallery",
        config
      );

      if (res.data.success == 1) {
        setImagePath(res.data.file1);
        setGalleryImages(res.data.records.data);
        setTotal(res.data.records.total);
      } else {
        alert("Error while fetching member details");
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ backgroundColor: "#FFFBCC" }}>
      <div>
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            शुभकामना{" "}
            <b style={{ color: "#e47257" }}>
              सन्देश
              <p style={{ color: "White" }}>मुख्य पृष्ठ / शुभकामना सन्देश</p>
            </b>
          </h2>
        </div>
        <div className="photoGalleryImages row">
          {galleryImages.slice(minValue, maxValue).map((image) => (
            <SingleSource key={image} src={imagePath + "/" + image.file1} />
          ))}
        </div>
        <div className="text-center">
        <Pagination
          total={totalCount}
          current={current}
          pageSize={pageSize}
          onChange={onChange}
          style={{ bottom: "0px" }}
        />
      </div>
      </div>
    </div>
  );
};

export default PhotoGallery;

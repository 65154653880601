import React, { Component, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, DatePicker, Input, Select, Upload } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import { Pagination } from "antd";

const Janganna = (props) => {
  let statesList;
  let citiesList;
  let token;
  const history = useHistory();
  const [selectedState, setSelectedState] = useState([]);
  const states = selectedState.map((state) => state);
  const [selectedCity, setSelectedCity] = useState([]);
  const cities = selectedCity.map((city) => city);
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [memberName, setMemberName] = useState("");
  const [familyId, setFamilyId] = useState("");
  const [withNRI, setWithNRI] = useState("");
  const [cityVillage, setCityVillage] = useState();
  const [search, setSearch] = useState(false);
  const [imagePath, setImagePath] = useState([]);
  const path = imagePath;
  const [total, setTotal] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const totalCount = total;
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(4);

  const [jangannaList, setJangannaList] = useState([]);
  const jangannaMembers =
    jangannaList.length > 0 ? jangannaList.map((jan) => jan) : [];

  const headingStyle = {
    color: "white",
    backgroundColor: "#4D4D4D",
    paddingTop: "6em",
    paddingBottom: "1em",
  };

  useEffect(async () => {
    if (
      localStorage.getItem("loggedToken") == undefined &&
      localStorage.getItem("loggedUserId") == undefined
    ) {
      history.push({ pathname: "/login" });
    }
    await getStates();
    onChange(1);
    setSearch(false);
  }, [setSelectedState]);

  const onChange = (value) => {
    setCurrent(value);
    setMinValue((value - 1) * pageSize);
    setMaxValue(value * pageSize);
  };

  const getStates = async () => {
    await axios
      .post("https://api.golapurvajainsamaj.com/api/v1/staticlists/getstate")
      .then((response) => {
        setSelectedState(response.data.data);
        statesList = response.data.data;
      });
  };

  const getCities = async (e) => {
    await axios
      .post(
        "https://api.golapurvajainsamaj.com/api/v1/staticlists/getcity?state_id=" +
          e
      )
      .then((response) => {
        setSelectedCity(response.data.data);
        citiesList = response.data.data;
      });
  };

  const changeState = async (event) => {
    setState(event);
    await getCities(event);
  };

  const changeCity = (event) => {
    setCity(event);
  };
  const handleReset = () => {
    setWithNRI("");
    setMemberName("");
    setFamilyId("");
    setState("");
    setCity("");
    setCityVillage("");
  };

  const handleSubmit = async (e) => {
    setSearch(true);
    try {
      const postData = {
        is_nri: withNRI,
        fullname: memberName,
        unique_id: familyId,
        state_id: state,
        district_id: city,
        city_name: cityVillage,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            token == undefined ? localStorage.getItem("loggedToken") : token
          }`,
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/jansearch",
        body,
        config
      );
      if (res.data.data.data.length > 0) {
        setJangannaList(res.data.data.data);
        setImagePath(res.data.imagepath);
        setTotal(res.data.data.total);
      } else {
        setJangannaList(0);
        setTotal(0);
        return false;
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: "#FFFBCC", height: "25em" }}>
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            हमारा{" "}
            <b style={{ color: "#e47257" }}>
              परिचय
              <p style={{ color: "White" }}>
                मुख्य पृष्ठ / दिगम्बर जैन गोलापूर्व परिवार जनगणना
              </p>
            </b>
          </h2>
        </div>
      </div>
      <div>
        <div class="col-md-12 column">
          <div
            class="who-we-are"
            style={{
              backgroundColor: "#FFFBCC",
              paddingLeft: "5em",
              paddingRight: "6em",
            }}
          >
            <div class="row">
              <div class="col-md-3 column">
                <form method="GET">
                  <div class="form-group">
                    <label htmlFor="Name">
                      <b>नाम</b>
                    </label>
                    <br />
                    <Input
                      id="Name"
                      placeholder="नाम"
                      name="Name"
                      className="rounded"
                      value={memberName}
                      onChange={(event) => setMemberName(event.target.value)}
                    />
                  </div>
                  <div class="form-group mt-2">
                    <label htmlFor="FamilyId">
                      <b>Family Id</b>
                    </label>
                    <br />
                    <Input
                      id="FamilyId"
                      placeholder="FamilyId"
                      name="FamilyId"
                      className="rounded"
                      value={familyId}
                      onChange={(event) => setFamilyId(event.target.value)}
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label htmlFor="WithNRI">
                      <b>With NRI</b>
                    </label>
                    <br />
                    <Select
                      showSearch
                      id="WithNRI"
                      placeholder="-- Select --"
                      name="WithNRI"
                      className="rounded"
                      value={withNRI}
                      onChange={(event) => setWithNRI(event)}
                      style={{ width: 280 }}
                    >
                      <Option value="0">India</Option>
                      <Option value="1">NRI</Option>
                    </Select>{" "}
                  </div>

                  <div class="form-group mt-3">
                    <label htmlFor="State">
                      <b>राज्य</b>
                    </label>
                    <br />
                    <Select
                      id="state"
                      showSearch
                      name="state"
                      className="rounded"
                      placeholder="-- राज्य --"
                      optionFilterProp="children"
                      value={state}
                      onChange={changeState}
                    >
                      {states.map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  <div class="form-group mt-3">
                    <label htmlFor="district">
                      <b>जिला</b>
                    </label>
                    <br />
                    <Select
                      id="district"
                      showSearch
                      name="district"
                      className="rounded"
                      placeholder="-- जिला --"
                      optionFilterProp="children"
                      value={city}
                      onChange={changeCity}
                    >
                      {cities.map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Select>
                  </div>

                  <div class="form-group">
                    <label htmlFor="cityVillage">
                      <b>शहर / गांव</b>
                    </label>
                    <Input
                      id="cityVillage"
                      placeholder="शहर / गांव"
                      name="pincode"
                      className="rounded"
                      value={cityVillage}
                      onChange={(event) => setCityVillage(event.target.value)}
                    />
                  </div>

                  <div class="form-group mt-3">
                    <Button
                      type="primary"
                      size="large"
                      className="submit"
                      onClick={handleSubmit}
                    >
                      Search Now
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      size="large"
                      className="reset"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </div>
                </form>
              </div>
              {!search && jangannaMembers.length == 0 ? (
                <div class="col-md-9 column">
                  <div class="title style3">
                    <span>अखिल भारतवर्षीय दिगम्बर जैन </span>
                    <h2>
                      गोलापूर्व{" "}
                      <span style={{ color: "#e47257", fontSize: "28px" }}>
                        महासभा
                      </span>
                    </h2>
                  </div>
                  <div class="who-we-text">
                    <p>
                      आप सभी समाजजनो को सूचित करते हुये अति हर्ष हो रहा है कि
                      अखिल भारतवर्षीय दिगंबर जैन गोलापूर्व महासभा के मार्गदर्शन,
                      परामर्श एवं सानिध्य में सक्रिय एवं सार गर्वित कार्य करने
                      का एक प्रयास हमारी टीम द्वारा किया जा रहा है । दिगंबर जैन
                      गोलापूर्व समाज की अति महत्वपूर्ण एवं महत्वाकांक्षी योजना
                      को मुहूर्त रूप प्रदान करने के लिए समग्र भारत तथा विश्व के
                      अन्य देशों में निवासरत प्रत्येक दिगंबर गोलापूर्व जैन
                      व्यक्ति एवं परिवार का एक डेटाबेस तैयार करना तथा इस डेटाबेस
                      को व्यक्ति ,परिवार एवं समाज हित में उपयोगी बनाना। इसके
                      अंतर्गत हमारी टीम द्वारा एक वेबसाइट.
                      www.golapurvajainsamaj.com का निर्माण किया गया है ,जिसका
                      अवलोकन समस्त समाजजनो द्वारा सरलता से इन्टरनेट पर कर सकते
                      है अभी तक प्राप्त कुछ जानकारी के आधार पर परिवारों से
                      विभिन्न माध्यमों से संपर्क किया जा रहा है । इस वेबसाइट के
                      माध्यम से समाज के समस्त परिवारों की सूची, उनकी संक्षिप्त
                      जानकारी, विभिन्न व्यवसाय, नौकरीपेशे से जुड़े लोगों के बारे
                      में एवं युवाओं के मेट्रीमोनियल डेटाबेस की जानकारी प्राप्त
                      की जा सकेगी। , समाज के समस्त परिवार ऑन लाइन या ऑफ़ लाइन
                      फॉर्म भरकर अपने परिवार की जानकारी वेबसाइट में समाविष्ट कर
                      सकते हैं। समस्त सम्माननीय सक्रिय समाज जनो से ये आशा है कि
                      जनगणना से संबंधित ये जानकारी समस्त परिवारों तक पहुचाने एवं
                      उनके ऑन लाइन फॉर्म भरने मे अपना अतुलनीय एवं महत्वपूर्ण
                      सक्रिय सहयोग समाज के हित मे प्रदान करेगे ,आपके समर्पित
                      सहयोग से ही वर्ष 2020 के अंत तक इस वेबसाइट द्वारा दिगंबर
                      जैन गोलापूर्व समाज की जनगणना की सम्पूर्ण जानकारियों को
                      उपलब्ध कराने प्रयास हमारी टीम द्वारा किया जा सकेगा ।
                    </p>
                    <div class="our-objective" style={{ fontSize: "16px" }}>
                      <ul>
                        <li>
                          {" "}
                          समाज को नेतृत्व प्रदान करने वाले अखिल भारतवर्षीय
                          दिगंबर जैन गोलापूर्व महासभा के महत्वपूर्ण संगठन की
                          सुदृढ़ता।
                        </li>
                        <li>
                          समग्र दिगंबर गोलापूर्व जैन समाज के मास्टर डेटाबेस/
                          डिजिटल डायरेक्ट्री का निर्माण।
                        </li>
                        <li>
                          धार्मिक एवं सामाजिक कार्यक्रमों की समस्त महत्वपूर्ण
                          गतिविधियों की सूचना एवं खबरों का त्वरित प्रसारण।।
                        </li>
                        <li>
                          समाज के विवाह योग्य समस्त युवाओं के मेट्रीमोनियल
                          डेटाबेस का निर्माण।।
                        </li>
                        <li>
                          सरकार द्वारा अल्पसंख्यक एवं आरक्षण संबंधी समस्त
                          महत्वपूर्ण लाभों का सधर्मी बंधुओं को समुचित जानकारी ।।
                        </li>
                        <li>
                          किसी भी राष्ट्रीय ,क्षेत्रीय एवं परिवारिक आपदा के समय
                          सधार्मिक बंधुओं से संपर्क साधना एवं यथासंभव राहत
                          पहुंचाना।।
                        </li>
                        <li>
                          वंशवृक्ष से परिवार के समस्त सदस्यों की जानकारी।।
                        </li>
                        <li>
                          धार्मिक एवं सामाजिक गतिविधियों के संचालन में
                          कार्यकर्ता शक्ति का समुचित सदुपयोग।।
                        </li>
                        <li>
                          समाज की प्रतिभाओं को समाजोपयोगी कार्यों के लिए प्रेरित
                          करना।।
                        </li>
                        <li>
                          डिजिटल संचार माध्यमों का अधिकाधिक उपयोग कर समय, संसाधन
                          एवं खर्च बचाना।
                        </li>
                        <li>
                          समाज के व्यवसायियों की नेटवर्किंग करना तथा आपस में
                          व्यापार के अवसर उपलब्ध करवाना।।
                        </li>
                        <li>
                          प्लेसमेंट सर्विसेज की तरह समाज के प्रतिष्ठानों में
                          उपलब्ध जोब्स की जानकारी।।
                        </li>
                        <li>
                          समय-समय पर आने वाली सरकारी/गैर सरकारी योजनाओं की सूचना
                          समाज तक पहुंचाना।।
                        </li>
                        <li>
                          प्रत्येक दिगंबर जैन गोलापूर्व सदस्य एवं परिवारों को
                          महासभा के महत्वपूर्ण संगठन से आत्मीय जुड़ाव ।।
                        </li>
                        <li>
                          प्रत्येक सदस्य की रुचि के अनुरूप महासभा में होने वाली
                          गतिविधयों की जानकारी उपलब्ध करवाना।।
                        </li>
                        <li>
                          भावीपीढ़ी का समाज एवं अखिल भारतवर्षीय दिगंबर जैन
                          गोलापूर्व महासभा से जुड़ाव एवं पारस्परिक सौहार्द की
                          अभिवृद्धि|| ।
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-9 column">
                  {jangannaMembers.length == 0 ? (
                    <div>No Records found!</div>
                  ) : jangannaMembers.length > 0 ? (
                    jangannaMembers.slice(minValue, maxValue).map((e, key) => {
                      return (
                        <div>
                          <div
                            class=""
                            style={{
                              background: "#fff",
                              border: "1px solid #ccc",
                              marginBottom: "20px",
                            }}
                          >
                            <div class="row">
                              <div
                                class="container d-flex align-items-center"
                                style={{ width: "100%" }}
                              >
                                <div class="col-md-4 col-sm-6 pl-0">
                                  <br />
                                  <div class="img-hover-border d-inline-block">
                                    <img
                                      class="img-fullwidth"
                                      style={{
                                        padding: "0 5px 0 0",
                                        width: "100%",
                                      }}
                                      src={imagePath + "/" + e.profile_image}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div style={{ height: "100%", width: "65%" }}>
                                  <br />
                                  <table class="table table-bordered table-striped membersFont">
                                    <colgroup>
                                      {" "}
                                      <col class="col-5" /> <col />{" "}
                                    </colgroup>
                                    <tbody>
                                      <tr>
                                        <td scope="row">नाम</td>
                                        <td>{e.fullname}</td>
                                      </tr>

                                      <tr>
                                        <td scope="row">पिता</td>
                                        <td>{e.father_name}</td>
                                      </tr>
                                      <tr>
                                        <td scope="row">शहर / गांव</td>
                                        <td>{e.city_name}</td>
                                      </tr>

                                      <tr>
                                        <td scope="row">पूर्व निवासी</td>
                                        <td>{e.previousplace}</td>
                                      </tr>

                                      <tr>
                                        <td scope="row">कुल महिला सदस्य</td>
                                        <td>{e.femalecond}</td>
                                      </tr>
                                      <tr>
                                        <td scope="row">कुल पुरुष सदस्य</td>
                                        <td>{e.malecond}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    ""
                  )}
                  <div className="text-center">
                    {jangannaMembers.length > 0 ? (
                      <Pagination
                      total={totalCount}
                      current={current}
                      pageSize={pageSize}
                      onChange={onChange}
                      style={{ bottom: "0px" }}
                      />
                    ) : (
                      ""
                    )}
                    ;
                  </div>
                </div>
              )}
              ;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Janganna;

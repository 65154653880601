import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import istyleded from './Navbar.module.css';
const Navbar = props => {
  

  return (
    <div className="menu">
		<div className={`container ${istyleded.navcontainer}`}>
            <div className="logo">
                <Link to={{
                              pathname: "/"
                            }} title="">
                    <img
                        alt="Logo"
                        src={`/img/logo.png`}
                    />
                </Link>
            </div>
            <div className={istyleded.navbar}>
                <Link to="/">मुख्य पृष्ठ</Link>
                <div className={istyleded.dropdown}>
                    <button className={istyleded.dropbtn}>हमारे बारे में
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className={istyleded.dropdown_content}>
                        <Link  to={{
                              pathname: "/AboutUs"
                            }}>हमारा परिचय </Link>
                        <Link to={{
                              pathname: "/Objective"
                            }}>हमारे उद्देश्य</Link>
                        <Link to={{
                              pathname: "/ShubhkaamnaMessage"
                            }}>शुभकामना सन्देश</Link>
                        <Link to="#">समाज की विशिष्ट विभूतियां</Link>
                    </div>
                </div>
                <div className={istyleded.dropdown}>
                    <button className={istyleded.dropbtn}>महासभा के सदस्य
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className={istyleded.dropdown_content}>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 2 },
                            }}>हमारे आधार  स्तब्ध -संस्थापक</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 3 },
                            }}>निदेशक मंडल</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 4 },
                            }}>महासभा के ट्रस्टी</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 5 },
                            }}>प्रबंधन कार्यकारणी</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 6 },
                            }}>शिरोमणि संरक्षक</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 7 },
                            }}>परम संरक्षक</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 8 },
                            }}>विशिष्ट संरक्षक</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 9 },
                            }}>गौरव संरक्षक</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 10 },
                            }}>संरक्षक</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 11 },
                            }}>सम्मानीय सदस्य</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 12 },
                            }}>नए सदस्य</Link>
                    </div>
                </div>
                <div className={istyleded.dropdown}>
                    <button className={istyleded.dropbtn}>जनगणना
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className={istyleded.dropdown_content}>
                        <Link to={{
                              pathname: "/janganna"
                            }}>जनगणना</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 13 },
                            }}>संयोजक टीम</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 21 },
                            }}>स्मृति शेष</Link>
                    </div>
                </div>
                <div className={istyleded.dropdown}>
                    <button className={istyleded.dropbtn}>मेट्रोमोनियल
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className={istyleded.dropdown_content}>
                        <Link to={{
                              pathname: "/matrimonyProfile"
                            }}>प्रोफाइल सर्च</Link>
                        <Link to="#">सक्सेस स्टोरी</Link>
                    </div>
                </div>
                <div className={istyleded.dropdown}>
                    <button className={istyleded.dropbtn}>सलाहकार
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className={istyleded.dropdown_content}>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 14 },
                            }}>कानून संबंधी</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 15 },
                            }}>व्यापार और रोजगार संबंधी</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 16 },
                            }}>चिकित्सा संबंधी</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 17 },
                            }}>बीमा संबंधी</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 18 },
                            }}>छात्रवति संबंधी</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 19 },
                            }}>शिक्षा संबंधी</Link>
                        <Link to={{
                              pathname: "/team",
                              state: { teamId: 20 },
                            }}>सरकारी / गैर सरकारी योजनाए</Link>
                    </div>
                </div>
                <div className={istyleded.dropdown}>
                    <button className={istyleded.dropbtn}>गतिविधियां
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className={istyleded.dropdown_content}>
                        <Link to="#">आगामी कार्यक्रम</Link>
                        <Link to="#">ई-पत्रिका</Link>
                        <Link to="#">लेख</Link>
                        <Link to="#">बुंदेलखंड भवन</Link>
                        <Link to="#">महिला प्रकोष्ठ</Link>
                        <Link to={{
                              pathname: "/chetna"
                            }}>चेतना सम्मान समारोह</Link>
                    </div>
                </div>
                <div className={istyleded.dropdown}>
                    <button className={istyleded.dropbtn}>संपर्क
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className={istyleded.dropdown_content}>
                        <Link to="#">संपर्क</Link>
                        <Link to="#">फीडबैक</Link>
                    </div>
                </div>
            </div> 
		</div>
	</div>
  );
};

export default Navbar;

import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from './Header';
const LeftHeader = props => {
  return (
    <ul className="inline-list">
      <li><i className="fa fa-home"></i>नमकमंडी कटरा बाजार सागर</li>
      <li><i className="fa fa-phone"></i>07582-243101</li>
      <li><i className="fa fa-users"></i>Family &amp; Members: 6810</li>
    </ul>
  );
};
export default LeftHeader;
import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageHeader from '../../common/PageHeader';
const ForGotPass = props => {
  

  return (
    <Fragment>
        <PageHeader pageheadertitle={'Forgot Password'} />
        <section>
          <div className="block gray">
              <div className="container">
                <div className="row">
                    <div className="col-md-12 column">
                          <div className="clearfix"></div><br />
                          <div className="ask-question">	
                            <p>Forgot Password</p>
                            <form className="contact-form white-bg">
                                <div className="form-group">
                                  <input 
                                    className="temp_input_field" 
                                    type="text" 
                                    placeholder="Mobile No" 
                                    name="mobile"
                                  />
                                </div>
                                <div className="col-md-12">
                                  <p><Link to="/login" title="">Login</Link></p>
                                </div>
                                <div className="col-md-12">
                                  <button type="submit" className="submit">Submit</button>
                                </div>
                            </form>
                          </div>
                    </div>
                </div>
              </div>
          </div>
        </section>
    </Fragment>
  );
};

export default ForGotPass;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import istyleded from './Header.module.css';

const WithoutLoggedInHeader = props => {
  return (
    <ul className={`inline-list registration-btn ${istyleded.float_right}`}>
        <li><Link to="login" title=""><i className="fa fa-sign-in"></i>LOGIN</Link></li>
        <li><Link to="register" title=""><i className="fa fa-user"></i>REGISTER</Link></li>
    </ul>
  );
};
export default WithoutLoggedInHeader;
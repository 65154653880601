import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MainFooter from  './MainFooter';

const Footer = props => {
  return (
    <footer>
      <MainFooter />
    </footer>
  );
};
export default Footer;

import React, { Component, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, DatePicker, Input, Select, Upload } from "antd";
import "antd/dist/antd.css";
import axios from "axios";

const ChangePassword = (props) => {
  const history = useHistory();
  const headingStyle = {
    color: "white",
    backgroundColor: "#4D4D4D",
    paddingTop: "6em",
    paddingBottom: "1em",
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordErr, setCurrentPasswordErr] = useState({});

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState({});

  const [reEnterNewPassword, setReEnterNewPassword] = useState("");
  const [reEnterNewPasswordErr, setReEnterNewPasswordErr] = useState({});

  useEffect(() => {
    debugger;
    if (
      localStorage.getItem("loggedToken") == undefined &&
      localStorage.getItem("loggedUserId") == undefined
    ) {
      history.push({ pathname: "/login" });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      alert("all good!!");
    }
  };

  const formValidation = () => {
    const currentPasswordErr = {};
    const newPasswordErr = {};
    const reEnterNewPasswordErr = {};
    let isValid = true;
    if (currentPassword == "") {
      currentPasswordErr.EmptyCurrentPassword = "Enter the current password";
      isValid = false;
    }

    if (newPassword == "") {
      newPasswordErr.EmptyNewPassword = "Enter the new password";
      isValid = false;
    }

    if (reEnterNewPassword == "") {
      reEnterNewPasswordErr.EmptyReEnterPassword = "Re Enter the new password";
      isValid = false;
    }

    if (isValid == true) {
      if (newPassword.trim() != reEnterNewPassword.trim()) {
        alert("Password and confirm password does not match.");
        isValid = false;
      }
    }

    setCurrentPasswordErr(currentPasswordErr);
    setNewPasswordErr(newPasswordErr);
    setReEnterNewPasswordErr(reEnterNewPasswordErr);
    return isValid;
  };

  return (
    <div>
      <div style={{ backgroundColor: "#FFFBCC", height: "25em" }}>
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            Change Password
            <b style={{ color: "#e47257" }}>
              <p style={{ color: "White" }}>मुख्य पृष्ठ / Change Password</p>
            </b>
          </h2>
        </div>
      </div>
      <div style={{ backgroundColor: "#FFFBCC" }}>
        <form
          method="POST"
          class="contact-form white-bg"
          id="change_pass"
          enctype="multipart/form-data"
          style={{ paddingTop: 0 }}
        >
          <div className="row" style={{ paddingLeft: "20em" }}>
            <div className="col-8">
              <br />
              <Input
                id="CurrentPassword"
                placeholder="Current Password"
                name="currentPassword"
                className="rounded"
                type="password"
                value={currentPassword}
                onChange={(event) => setCurrentPassword(event.target.value)}
              />
              <br />
              {Object.keys(currentPasswordErr).map((key) => {
                return (
                  <div style={{ color: "red", float: "left" }}>
                    {currentPasswordErr[key]}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row" style={{ paddingLeft: "20em" }}>
            <div className="col-8">
              <Input
                id="NewPassword"
                placeholder="New Password"
                name="newPassword"
                type="password"
                className="rounded"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
              />
              <br />
              {Object.keys(newPasswordErr).map((key) => {
                return (
                  <div style={{ color: "red", float: "left" }}>
                    {newPasswordErr[key]}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row" style={{ paddingLeft: "20em" }}>
            <div className="col-8">
              <Input
                id="ReEnterPassword"
                placeholder="Re Enter Password"
                name="reEnterPassword"
                type="password"
                className="rounded"
                value={reEnterNewPassword}
                onChange={(event) => setReEnterNewPassword(event.target.value)}
              />
              <br />
              {Object.keys(reEnterNewPasswordErr).map((key) => {
                return (
                  <div style={{ color: "red", float: "left" }}>
                    {reEnterNewPasswordErr[key]}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="col-12"
            style={{ paddingBottom: "10px", color: "white" }}
          >
            <Button
              type="primary"
              size="large"
              className="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Header from '../Elements/Header/Header';
import Footer from '../Elements/Footer/Footer';


const Mainlayout = props => {
const { children } = props;
  return (
    <Fragment>
        <Header />
      {children}
        <Footer />
    </Fragment>
  );
};

Mainlayout.propTypes = {
  children: PropTypes.node
};

export default Mainlayout;

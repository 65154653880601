import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const PageHeader = props => {
  return (
    <div className="page-top">
      <div className="container">
        <div className="page-title">
          <h2>{props.pageheadertitle}  <strong></strong></h2>
          <ul>
            <li><Link to="/" title="">मुख्य पृष्ठ</Link></li>
            <li>{props.pageheadertitle}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
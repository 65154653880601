import React, { Component, useState, useEffect } from "react";
import render from "react-dom";
import { Button, DatePicker, Input, Select } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { Pagination } from "antd";

const headingStyle = {
  backgroundColor: "#4D4D4D",
  paddingTop: "6em",
  paddingBottom: "1em",
};

const elementsBorder = {
  border: "1px solid #600",
};

const ProfileSearch = () => {
  let statesList;
  let citiesList;
  let token;
  const history = useHistory();
  const location = useLocation();
  const { userIdToBeEdited } =
    location.state != undefined ? location.state : "";

  const [selectedState, setSelectedState] = useState([]);
  const states = selectedState.map((state) => state);
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [selectedCity, setSelectedCity] = useState([]);
  const cities = selectedCity.map((city) => city);

  const [memberName, setMemberName] = useState("");
  const [gender, setGender] = useState("");
  const [ageFrom, setAgeFrom] = useState("");
  const [ageTo, setAgeTo] = useState("");
  const [sanskarNo, setSanskarNo] = useState("");
  const [occupation, setOccupation] = useState("");
  const [education, setEducation] = useState("");
  const [cityVillage, setCityVillage] = useState();
  const [search, setSearch] = useState(false);
  const [imagePath, setImagePath] = useState([]);
  const path = imagePath;
  const [total, setTotal] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const totalCount = total;
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [profileSearchList, setProfileSearchList] = useState([]);
  const profileMembers =
    profileSearchList.length > 0 ? profileSearchList.map((mat) => mat) : [];

  const onChange = (value) => {
    setCurrent(value);
    setMinValue((value - 1) * pageSize);
    setMaxValue(value * pageSize);
  };

  useEffect(async () => {
    if (
      localStorage.getItem("loggedToken") == undefined &&
      localStorage.getItem("loggedUserId") == undefined
    ) {
      history.push({ pathname: "/login" });
    }
    setSearch(false);
    await getStates();
    onChange(1);
  }, [setSelectedState]);

  const getStates = async () => {
    await axios
      .post("https://api.golapurvajainsamaj.com/api/v1/staticlists/getstate")
      .then((response) => {
        setSelectedState(response.data.data);
        statesList = response.data.data;
      });
  };

  const getCities = async (e) => {
    await axios
      .post(
        "https://api.golapurvajainsamaj.com/api/v1/staticlists/getcity?state_id=" +
          e
      )
      .then((response) => {
        setSelectedCity(response.data.data);
        citiesList = response.data.data;
      });
  };

  const changeState = async (event) => {
    setState(event);
    await getCities(event);
  };

  const changeCity = (event) => {
    setCity(event);
  };

  const getMemberDetails = async (e) => {
    setSearch(true);
    try {
      const postData = {
        name: memberName,
        gender: gender,
        age_from: ageFrom,
        age_to: ageTo,
        occupation: occupation,
        seducation: education,
        state_id: state,
        district_id: city,
        scity1: cityVillage,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            token == undefined ? localStorage.getItem("loggedToken") : token
          }`,
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/metrosearch",
        body,
        config
      );
      if (res.data.Members.data.length > 0) {
        setProfileSearchList(res.data.Members.data);
        setImagePath(res.data.profile_img);
        setTotal(res.data.Members.total);
      } else {
        setProfileSearchList(0);
        setTotal(0);
        return false;
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };

  const handleReset = () => {
    setMemberName("");
    setGender("");
    setAgeFrom("");
    setAgeTo("");
    setOccupation("");
    setEducation("");
    setState("");
    setCity("");
    setCityVillage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getMemberDetails();
  };

  return (
    <div>
      <div className="mt-3 mb-3">
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            <b style={{ color: "#e47257" }}>
              प्रोफ़ाइल
              <p style={{ color: "White" }}>मुख्य पृष्ठ / प्रोफ़ाइल</p>
            </b>
          </h2>
        </div>
        <form>
          {!search && profileMembers.length == 0 ? (
            <div className="container">
              <div className="row" style={{ backgroundColor: "white" }}>
                <div className="col-6">
                  <div className="row">
                    <h2>
                      <span
                        style={{
                          fontSize: 28,
                          fontWeight: 600,
                          color: "#e47257",
                        }}
                      >
                        Profile Search
                      </span>
                    </h2>
                  </div>
                  <div className="row" style={{ fontWeight: 700 }}>
                    <div class="col-6">
                      <label>Total Male:</label> 0
                    </div>
                    <div class="col-6">
                      <label>Total Female:</label> 0
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div class="col-12">
                      <label htmlFor="Name">
                        <b>Name</b>
                      </label>
                      <br />
                      <Input
                        id="Name"
                        style={elementsBorder}
                        placeholder="Keyword"
                        name="name"
                        className="rounded"
                        value={memberName}
                        onChange={(event) => setMemberName(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div class="col-4">
                      <label htmlFor="Gender">
                        <b>Gender</b>
                      </label>
                      <br />
                      <Select
                        showSearch
                        id="Gender"
                        placeholder="-- Select --"
                        name="gender"
                        className="rounded"
                        value={gender}
                        onChange={(event) => setGender(event.target.value)}
                        style={{ width: 150, border: "1px solid #600" }}
                      >
                        <Option value="1">Male</Option>
                        <Option value="2">Female</Option>
                      </Select>
                    </div>
                    <div class="col-4">
                      <label htmlFor="AgeFrom">
                        <b>Age From</b>
                      </label>
                      <br />
                      <Select
                        showSearch
                        id="AgeFrom"
                        placeholder="-- Select --"
                        name="AgeFrom"
                        className="rounded"
                        value={ageFrom}
                        onChange={(event) => setAgeFrom(event.target.value)}
                        style={{ width: 150, border: "1px solid #600" }}
                      >
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                      </Select>
                    </div>
                    <div class="col-4">
                      <label htmlFor="AgeTo">
                        <b>Age To</b>
                      </label>
                      <br />
                      <Select
                        showSearch
                        id="AgeTo"
                        placeholder="-- Select --"
                        name="AgeTo"
                        className="rounded"
                        value={ageTo}
                        onChange={(event) => setAgeTo(event.target.value)}
                        style={{ width: 160, border: "1px solid #600" }}
                      >
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div class="col-4">
                      <label htmlFor="SanskarNo">
                        <b>Sanskar No.</b>
                      </label>
                      <br />
                      <Input
                        id="SanskarNo"
                        style={elementsBorder}
                        name="SanskarNo"
                        className="rounded"
                        value={sanskarNo}
                        onChange={(event) => setSanskarNo(event.target.value)}
                      />
                    </div>
                    <div class="col-4">
                      <label htmlFor="Occupation">
                        <b>Occupation</b>
                      </label>
                      <br />
                      <Select
                        showSearch
                        id="Occupation"
                        placeholder="-- Select --"
                        name="Occupation"
                        className="rounded"
                        value={occupation}
                        onChange={(event) => setOccupation(event.target.value)}
                        style={{ width: 150, border: "1px solid #600" }}
                      >
                        <option value="Business">Business</option>
                        <option value="Job">Job</option>
                        <option value="Professional">Professional</option>
                        <option value="Self Employed">Self Employed</option>
                        <option value="Student">Student</option>
                        <option value="House Work">House Work</option>
                        <option value="Retired Person">Retired Person</option>
                      </Select>
                    </div>
                    <div class="col-4">
                      <label htmlFor="Education">
                        <b>Education</b>
                      </label>
                      <br />
                      <Select
                        showSearch
                        id="Education"
                        placeholder="-- Select --"
                        name="Education"
                        className="rounded"
                        value={education}
                        onChange={(event) => setEducation(event.target.value)}
                        style={{ width: 160, border: "1px solid #600" }}
                      >
                        <option value="10">10th</option>
                        <option value="12">12th</option>
                        <option value="graduate">Graduate</option>
                        <option value="post_graduate">Post Graduate</option>
                        <option value="phd">Ph.D</option>
                        <option value="other">Other</option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-4">
                      <label htmlFor="State">
                        <b>राज्य</b>
                      </label>
                      <br />
                      <Select
                        id="state"
                        showSearch
                        name="state"
                        style={{ width: 150, border: "1px solid #600" }}
                        placeholder="-- Select --"
                        value={state}
                        onChange={changeState}
                      >
                        {states.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Select>
                      <br />
                      {/* {Object.keys(stateErr).map((key) => {
                return <div style={{ color: "red" }}>{stateErr[key]}</div>;
              })} */}
                    </div>
                    <div className="col-4">
                      <label htmlFor="Region">
                        <b>जिला</b>
                      </label>
                      <br />
                      <Select
                        id="city"
                        showSearch
                        name="city"
                        style={{ width: 150, border: "1px solid #600" }}
                        placeholder="-- Select --"
                        value={city}
                        onChange={changeCity}
                      >
                        {cities.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Select>
                      <br />
                      {/* {Object.keys(cityErr).map((key) => {
                return <div style={{ color: "red" }}>{cityErr[key]}</div>;
              })} */}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div class="col-12">
                      <label htmlFor="CityVillage">
                        <b>शहर / गांव</b>
                      </label>
                      <br />
                      <Input
                        id="CityVillage"
                        style={elementsBorder}
                        name="CityVillage"
                        value={cityVillage}
                        onChange={(event) => setCityVillage(event.target.value)}
                        className="rounded"
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-3">
                      <Button
                        type="primary"
                        size="large"
                        className="submit"
                        onClick={handleSubmit}
                      >
                        Search Now
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        size="large"
                        className="reset"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <img src="https://golapurvajainsamaj.com/public/sadi/images/search-bg.png" />
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12 column">
              <div
                class="who-we-are"
                style={{
                  paddingLeft: "5em",
                  paddingRight: "6em",
                }}
              >
                <div class="title style3">
                  <h2>
                    <span>Profile Search</span>
                  </h2>
                </div>
                <div className="row">
                  <div class="col-md-3 column">
                    <div className="form-group">
                      <label htmlFor="Name">
                        <b>Name</b>
                      </label>
                      <br />
                      <Input
                        id="Name"
                        name="Name"
                        className="rounded"
                        value={memberName}
                        onChange={(event) => setMemberName(event.target.value)}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="Gender">
                        <b>Gender</b>
                      </label>
                      <br />
                      <Select
                        showSearch
                        id="Gender"
                        placeholder="-- Select --"
                        name="gender"
                        className="ant-selectMatrimony rounded"
                        value={gender}
                        onChange={(event) => setGender(event.target.value)}
                      >
                        <Option value="1">Male</Option>
                        <Option value="2">Female</Option>
                      </Select>
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="AgeFrom">
                        <b>Age From</b>
                      </label>
                      <br />
                      <Select
                        showSearch
                        id="AgeFrom"
                        placeholder="-- Select --"
                        name="AgeFrom"
                        className="ant-selectMatrimony rounded"
                        value={ageFrom}
                        onChange={(event) => setAgeFrom(event.target.value)}
                      >
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                      </Select>
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="AgeTo">
                        <b>Age To</b>
                      </label>
                      <br />
                      <Select
                        showSearch
                        id="AgeTo"
                        placeholder="-- Select --"
                        name="AgeTo"
                        className="ant-selectMatrimony rounded"
                        value={ageTo}
                        onChange={(event) => setAgeTo(event.target.value)}
                      >
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                      </Select>
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="SanskarNo">
                        <b>Sanskar No.</b>
                      </label>
                      <br />
                      <Input
                        id="SanskarNo"
                        placeholder=""
                        name="SanskarNo"
                        className="rounded"
                        value={sanskarNo}
                        onChange={(event) => setSanskarNo(event.target.value)}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="Occupation">
                        <b>Occupation</b>
                      </label>
                      <br />
                      <Select
                        showSearch
                        id="Occupation"
                        placeholder="-- Select --"
                        name="Occupation"
                        className="ant-selectMatrimony rounded"
                        value={occupation}
                        onChange={(event) => setOccupation(event.target.value)}
                      >
                        <option value="Business">Business</option>
                        <option value="Job">Job</option>
                        <option value="Professional">Professional</option>
                        <option value="Self Employed">Self Employed</option>
                        <option value="Student">Student</option>
                        <option value="House Work">House Work</option>
                        <option value="Retired Person">Retired Person</option>
                      </Select>
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="Education">
                        <b>Education</b>
                      </label>
                      <br />
                      <Select
                        showSearch
                        id="Education"
                        placeholder="-- Select --"
                        name="Education"
                        className="ant-selectMatrimony rounded"
                        value={education}
                        onChange={(event) => setEducation(event.target.value)}
                      >
                        <option value="10">10th</option>
                        <option value="12">12th</option>
                        <option value="graduate">Graduate</option>
                        <option value="post_graduate">Post Graduate</option>
                        <option value="phd">Ph.D</option>
                        <option value="other">Other</option>
                      </Select>
                    </div>
                    <div class="form-group mt-3">
                      <label htmlFor="State">
                        <b>राज्य</b>
                      </label>
                      <br />
                      <Select
                        id="state"
                        showSearch
                        name="state"
                        className="ant-selectMatrimony rounded"
                        placeholder="-- राज्य --"
                        optionFilterProp="children"
                        value={state}
                        onChange={changeState}
                      >
                        {states.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div class="form-group mt-3">
                      <label htmlFor="district">
                        <b>जिला</b>
                      </label>
                      <br />
                      <Select
                        id="district"
                        showSearch
                        name="district"
                        className="ant-selectMatrimony rounded"
                        placeholder="-- जिला --"
                        optionFilterProp="children"
                        value={city}
                        onChange={changeCity}
                      >
                        {cities.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Select>
                    </div>

                    <div class="form-group mt-3">
                      <label htmlFor="cityVillage">
                        <b>शहर / गांव</b>
                      </label>
                      <Input
                        id="cityVillage"
                        placeholder="शहर / गांव"
                        name="pincode"
                        className="rounded"
                        value={cityVillage}
                        onChange={(event) => setCityVillage(event.target.value)}
                      />
                    </div>

                    <div class="form-group mt-3 mb-3">
                      <Button
                        type="primary"
                        size="large"
                        className="submit"
                        onClick={handleSubmit}
                      >
                        Search Now
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        size="large"
                        className="reset"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-9 column">
                    {profileMembers.length == 0 ? (
                      <div>No Records found!</div>
                    ) : profileMembers.length > 0 ? (
                      profileMembers.slice(minValue, maxValue).map((e, key) => {
                        return (
                          <div>
                            <div
                              class=""
                              style={{
                                background: "#fff",
                                border: "1px solid #ccc",
                                marginBottom: "20px",
                              }}
                            >
                              <div class="row">
                                <div
                                  class="container d-flex align-items-center"
                                  style={{ width: "100%" }}
                                >
                                  <div class="col-md-4 col-sm-6 pl-0">
                                    <br />
                                    <div class="img-hover-border d-inline-block">
                                      <img
                                        class="img-fullwidth"
                                        style={{
                                          padding: "0 5px 0 0",
                                          width: "100%",
                                        }}
                                        src={imagePath + "/" + e.files2}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div style={{ height: "100%", width: "65%" }}>
                                    <br />
                                    <table class="table table-bordered table-striped membersFont">
                                      <colgroup>
                                        {" "}
                                        <col class="col-5" /> <col />{" "}
                                      </colgroup>
                                      <tbody>
                                        <tr>
                                          <td scope="row">प्रत्याशी का नाम</td>
                                          <td>{e.name}</td>
                                        </tr>

                                        <tr>
                                          <td scope="row">पिता का नाम</td>
                                          <td>{e.sfname}</td>
                                        </tr>
                                        <tr>
                                          <td scope="row">लिंग</td>
                                          <td>{e.gender}</td>
                                        </tr>

                                        <tr>
                                          <td scope="row">शहर / गांव</td>
                                          <td>{e.scity1}</td>
                                        </tr>

                                        <tr>
                                          <td scope="row">शिक्षा</td>
                                          <td>{e.seducation}</td>
                                        </tr>

                                        <tr>
                                          <td scope="row">ऊंचाई</td>
                                          <td>{e.heights.height}</td>
                                        </tr>
                                        <tr>
                                          <td scope="row">व्यवसाय / सर्विस</td>
                                          <td>{e.occupation}</td>
                                        </tr>
                                        <tr>
                                          <td scope="row">
                                            संस्कार पत्रिका क्रमांक
                                          </td>
                                          <td>{e.sanskar_no}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      ""
                    )}
                    <div className="text-center">
                      {profileMembers.length > 0 ? (
                        <Pagination
                          total={totalCount}
                          current={current}
                          pageSize={pageSize}
                          onChange={onChange}
                          style={{ bottom: "0px" }}
                        />
                      ) : (
                        ""
                      )}
                      ;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ProfileSearch;

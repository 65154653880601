import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Input } from "antd";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import axios from "axios";
const VerifyMember = (props) => {
  const history = useHistory();
  let userId = 0;
  let token = "";
  let userFamilyParentId = "";
  const [formState, setFormState] = useState({
    family_id: "",
  });
  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const getUserDetails = async (e) => {
    try {
      const postData = {
        mobile: props.mobileNo,
        password: props.password,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/login",
        body,
        config
      );
      if (res.data.success == 1) {
        userId = res.data.user.id;
        token = res.data.access_token;
        userFamilyParentId = res.data.user.family_id;
      } else {
        alert("Error while logging in");
        return false;
      }
    } catch (err) {
      console.error(err.response.data);
    }
  };

  const CheckFamilyHeadHandler = async (event) => {
    debugger;
    event.preventDefault();
    const familyhead = {
      familyHeadId: formState.family_id,
    };

    await getUserDetails();

    if (familyhead.familyHeadId != userFamilyParentId) {
      alert("Family Head Id does not match with our records!");
      return false;
    } else {
      history.push({ pathname: "/family_member", user_id: userId });
    }
    console.log(familyhead);
  };
  return (
    <Modal show={true} className="modal_overlay">
      <ModalTitle>{props.title}</ModalTitle>
      <ModalBody>
        <form className="">
          <div className="form-group">
            <Input
              id="FamilyId"
              placeholder="Family Head"
              className="form-control rounded"
              name="family_id"
              value={formState.family_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-12 mt-2">
            <Button type="primary" size="large" onClick={CheckFamilyHeadHandler} className="submit">
              Submit
            </Button>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <button onClick={props.OnVerifyHandler}>Close</button>
      </ModalFooter>
    </Modal>
  );
};

export default VerifyMember;

import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import PageHeader from "./../../common/PageHeader";
import FirstTimeLogin from "./Components/FirstTimeLogin";
import VerifyMember from "./Components/VerifyMember";
import { Button, DatePicker, Input, Select, Upload } from "antd";
import axios from "axios";

const SignIn = (props) => {
  const { history } = props;
  let isHead = "";
  let familyId = 0;
  let userID = 0;
  let token = "";
  const insertedUserId =
    props.location.state != undefined
      ? props.location.state.lastInsertedUser
      : "";
  const [formState, setFormState] = useState({
    UserId: 0,
    Token: 0,
  });
  const [verifyMember, setVerifyMember] = useState("");
  const [firstTimeLogin, setFirstTimeLogin] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [mobileNoErr, setmobileNoErr] = useState({});

  const [password, setPassword] = useState("");
  const [passwordErr, setpasswordErr] = useState({});

  const formValidation = () => {
    const mobileNoErr = {};
    const passwordErr = {};
    let isValid = true;

    if (mobileNo.trim().length == 0) {
      mobileNoErr.EmptyMobileNo = "Fill the mobile No";
      isValid = false;
    }

    if (password.trim().length == 0) {
      passwordErr.EmptyPassword = "Fill the password";
      isValid = false;
    }

    setmobileNoErr(mobileNoErr);
    setpasswordErr(passwordErr);
    return isValid;
  };

  const getUserDetails = async (e) => {
    try {
      const postData = {
        mobile: mobileNo,
        password: password,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/login",
        body,
        config
      );
      if (res.data.success == 1) {
        isHead = res.data.user.is_head;
        userID = res.data.user.id;
        token = res.data.access_token;
        familyId = res.data.user.family_id;
        localStorage.setItem("loggedUserId", userID);
        localStorage.setItem("loggedToken", token);
        localStorage.setItem("user-info", res.data.user.fullname);
      } else {
        alert("Error while logging in");
        return false;
      }
    } catch (err) {
      console.error(err.response.data);
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      await getUserDetails();
      if (isHead == null) {
        setFirstTimeLogin({
          title: "Are you family head?",
          UserId: userID,
          Token: token,
        });
      } else {
        history.push("/home", {
          familyId: familyId,
          userId: userID,
          token: token,
        });
      }
    }
  };
  const showpopupHandler = () => {
    setFirstTimeLogin(null);
  };
  const VerifyHandler = () => {
    setVerifyMember(null);
  };
  const VerifyPopupHandler = () => {
    setFirstTimeLogin(null);
    setVerifyMember({
      title: "Please Enter Family Id",
      mobileNo: mobileNo,
      password: password,
    });
  };
  return (
    <Fragment>
      <PageHeader pageheadertitle={"Login"} />
      <section>
        <div className="block gray">
          <div className="container">
            <div className="row">
              {firstTimeLogin && (
                <FirstTimeLogin
                  title={firstTimeLogin.title}
                  UserId={firstTimeLogin.UserId}
                  Token={firstTimeLogin.Token}
                  onShowpopupHandler={showpopupHandler}
                  onVerifyMember={VerifyPopupHandler}
                  lastInsertedUser={insertedUserId}
                />
              )}
              {verifyMember && (
                <VerifyMember
                  title={verifyMember.title}
                  OnVerifyHandler={VerifyHandler}
                />
              )}
              <div className="col-md-12 column">
                <div className="clearfix"></div>
                <br />
                <div className="ask-question">
                  <p>Login</p>
                  <form className="contact-form white-bg">
                    <div className="form-group">
                      <Input
                        id="mobileNo"
                        placeholder="Mobile No"
                        name="memberName"
                        className="rounded"
                        value={mobileNo}
                        onChange={(event) => setmobileNo(event.target.value)}
                      />
                      <br />
                      {Object.keys(mobileNoErr).map((key) => {
                        return (
                          <div style={{ color: "red", textAlign: "left" }}>
                            {mobileNoErr[key]}
                          </div>
                        );
                      })}
                    </div>
                    <div className="form-group">
                      <Input
                        id="password"
                        placeholder="password"
                        name="memberName"
                        type="password"
                        className="rounded"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <br />
                      {Object.keys(passwordErr).map((key) => {
                        return (
                          <div style={{ color: "red", textAlign: "left" }}>
                            {passwordErr[key]}
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12">
                      <p>
                        <Link to="/forgotpass" title="">
                          Forgot Password
                        </Link>
                      </p>
                    </div>
                    <div className="col-md-12">
                      <Button
                        type="primary"
                        size="large"
                        onClick={handleSignIn}
                        className="submit"
                      >
                        Login
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SignIn;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Topheader from './Topheader';
import Navbar from './Navbar';
const Header = props => {
  return (
      <header className='stick'>
        <Topheader />
        <Navbar />
      </header>
  );
};
export default Header;

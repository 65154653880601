import React, { Component, useState, useEffect } from "react";
import render from "react-dom";
import { useLocation } from "react-router-dom";
import { Button, DatePicker, Input, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import axios from "axios";
import { Pagination } from "antd";

const Team = (props) => {
  const location = useLocation();
  const { teamId } = location.state != undefined ? location.state : "";
  const headingStyle = {
    color: "white",
    backgroundColor: "#4D4D4D",
    paddingTop: "6em",
    paddingBottom: "1em",
  };

  const [teamList, setTeamList] = useState([]);
  const teams = teamList.length > 0 ? teamList.map((team) => team) : 0;

  const [total, setTotal] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const totalCount = total;
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [imagePath, setImagePath] = useState([]);
  const path = imagePath;

  const getTeamsData = async (e) => {
    try {
      const postData = {
        area_name_id: teamId,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/mahashabha/getcommoncards",
        body,
        config
      );
      if (res.data.data.data.length > 0) {
        setTeamList(res.data.data.data);
        setImagePath(res.data.imagepath);
        setTotal(res.data.data.total);
      } else if (res.data.data.data.length == 0) {
        setTeamList(0);
        setImagePath(0);
        setTotal(0);
        alert("No members found!");
      } else {
        alert("Error while fetching member details");
      }
    } catch (err) {
      console.error(err.response.data);
    }
  };

  useEffect(() => {
    getTeamsData();
    onChange(1);
  }, [location.state]);

  const onChange = (value) => {
    setCurrent(value);
    setMinValue((value - 1) * pageSize);
    setMaxValue(value * pageSize);
  };
  return (
    <div style={{ backgroundColor: "#FFFBCC" }}>
      <div
        className="mt-3"
        style={{ backgroundColor: "#FFFBCC", height: "25em" }}
      >
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            संयोजक{" "}
            <b style={{ color: "#e47257" }}>
              टीममुख्य
              <p style={{ color: "White" }}>
                मुख्य पृष्ठ /&nbsp;
                {teamId == 3
                  ? "निदेशक मंडल"
                  : teamId == 4
                  ? "महासभा के ट्रस्टी"
                  : teamId == 5
                  ? "प्रबंधन कार्यकारणी"
                  : teamId == 6
                  ? "शिरोमणि संरक्षक"
                  : teamId == 7
                  ? "परम संरक्षक"
                  : teamId == 8
                  ? "विशिष्ट संरक्षक"
                  : teamId == 9
                  ? "गौरव संरक्षक"
                  : teamId == 10
                  ? "संरक्षक"
                  : teamId == 11
                  ? "सम्मानीय सदस्य"
                  : teamId == 12
                  ? "नए सदस्य"
                  : teamId == 13
                  ? "संयोजक टीम"
                  : teamId == 14
                  ? "कानून संबंधी"
                  : teamId == 15
                  ? "व्यापार और रोजगार संबंधी"
                  : teamId == 16
                  ? "चिकित्सा संबंधी"
                  : teamId == 17
                  ? "बीमा संबंधी"
                  : teamId == 18
                  ? "छात्रवति संबंधी"
                  : teamId == 19
                  ? "शिक्षा संबंधी"
                  : teamId == 20
                  ? "सरकारी / गैर सरकारी योजनाए"
                  : teamId == 21
                  ? "स्मृति शेष"
                  : "हमारे आधार  स्तब्ध"}{" "}
              </p>
            </b>
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="row">
        <h2 class="text-center"><strong>{teamId == 3
                  ? "निदेशक मंडल"
                  : teamId == 4
                  ? "महासभा के ट्रस्टी"
                  : teamId == 5
                  ? "प्रबंधन कार्यकारणी"
                  : teamId == 6
                  ? "शिरोमणि संरक्षक"
                  : teamId == 7
                  ? "परम संरक्षक"
                  : teamId == 8
                  ? "विशिष्ट संरक्षक"
                  : teamId == 9
                  ? "गौरव संरक्षक"
                  : teamId == 10
                  ? "संरक्षक"
                  : teamId == 11
                  ? "सम्मानीय सदस्य"
                  : teamId == 12
                  ? "नए सदस्य"
                  : teamId == 13
                  ? "संयोजक टीम"
                  : teamId == 14
                  ? "कानून संबंधी"
                  : teamId == 15
                  ? "व्यापार और रोजगार संबंधी"
                  : teamId == 16
                  ? "चिकित्सा संबंधी"
                  : teamId == 17
                  ? "बीमा संबंधी"
                  : teamId == 18
                  ? "छात्रवति संबंधी"
                  : teamId == 19
                  ? "शिक्षा संबंधी"
                  : teamId == 20
                  ? "सरकारी / गैर सरकारी योजनाए"
                  : teamId == 21
                  ? "स्मृति शेष"
                  : "हमारे आधार  स्तब्ध"}</strong></h2>
          {teams.length > 0
            ? teams.slice(minValue, maxValue).map((e, key) => {
                return (
                  <div className="col-md-3 ">
                    <div className="teamMember">
                      <img
                        className="img-responsive inline-block memberImg"
                        src={
                          e.getmember.profile_image == null
                            ? "https://api.golapurvajainsamaj.com/public/upload/janaganana/1645452638.png"
                            : e.getmember.profile_image
                        }
                        alt=""
                      />
                      <i className="teamMemberName mt-2">
                        {e.getmember.fullname}
                      </i>
                      <i className="teamMemberDetails">
                        {" "}
                        {e.getmember.mobile}{" "}
                      </i>
                      <i className="teamMemberDetails">
                        {e.getmember.city_name}{" "}
                      </i>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <div className="text-center">
        <Pagination
          total={totalCount}
          current={current}
          pageSize={pageSize}
          onChange={onChange}
          style={{ bottom: "0px" }}
        />
      </div>
    </div>
  );
};

export default Team;

import React, { Component, useState, useEffect } from "react";
import render from "react-dom";
import { Button, DatePicker, Input, Select } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import moment from "moment";
import "../../App.css";

const headingStyle = {
  color: "white",
  backgroundColor: "#4D4D4D",
  paddingTop: "6em",
  paddingBottom: "1em",
};

const FamilyMembers = () => {
  let countriesList;
  let statesList;
  let citiesList;
  let token;
  const location = useLocation();
  const { userIdToBeEdited } =
    location.state != undefined ? location.state : "";
  const [selectedCountry, setSelectedCountry] = useState([]);
  const countries = selectedCountry.map((country) => country);
  const fileList = [];
  const [selectedState, setSelectedState] = useState([]);
  const states = selectedState.map((state) => state);

  const [selectedCity, setSelectedCity] = useState([]);
  const cities = selectedCity.map((city) => city);

  const [memberName, setMemberName] = useState("");
  const [memberNameErr, setMemberNameErr] = useState({});
  const [headRelation, setHeadRelation] = useState("");
  const [headRelationErr, setHeadRelationErr] = useState({});
  const [fatherHusbandName, setfatherHusbandName] = useState("");
  const [fatherHusbandNameErr, setfatherHusbandNameErr] = useState({});
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState({});
  const [dob, setDob] = useState("");
  const [dobErr, setDobErr] = useState({});
  const [maritalStatus, setMaritalStatus] = useState("");
  const [maritalStatusErr, setMaritalStatusErr] = useState({});
  const [mobileNo, setMobileNo] = useState("");
  const [mobileNoErr, setMobileNoErr] = useState({});
  const [address, setAddress] = useState("");
  const [addressErr, setAddressErr] = useState({});
  const [country, setCountry] = useState();
  const [countryErr, setCountryErr] = useState({});
  const [state, setState] = useState();
  const [stateErr, setStateErr] = useState({});
  const [city, setCity] = useState();
  const [cityErr, setCityErr] = useState({});
  const [pinCode, setPinCode] = useState();
  const [pinCodeErr, setPinCodeErr] = useState({});
  const [education, setEducation] = useState();
  const [educationErr, setEducationErr] = useState({});
  const [otherEducation, setOtherEducation] = useState();
  const [otherEducationErr, setOtherEducationErr] = useState({});
  const [service, setService] = useState();
  const [serviceErr, setServiceErr] = useState({});
  const [office, setOffice] = useState();
  const [officeErr, setOfficeErr] = useState({});
  const [accomodation, setaccomodation] = useState();
  const [accomodationErr, setaccomodationErr] = useState({});
  //const [panCard, setPanCard] = useState();
  const [panCardErr, setPanCardErr] = useState({});
  const [bloodGroup, setBloodGroup] = useState();
  const [bloodGroupErr, setBloodGroupErr] = useState({});
  const [photo, setPhoto] = useState();
  const [file, setFile] = React.useState("");
  const [fileErr, setFileErr] = useState({});
  const [imagePath, setImagePath] = useState("");
  const [image, setImage] = useState("");
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const dateFormatList = ["YYYY/MM/DD"];

  const handleUpload = (event) => {
    setIsImageUploaded(true);
    setImage("");
    setImagePath("");
    setFile(event.target.files[0]);
  };
  /**
   * Component to display thumbnail of image.
   */
  const ImageThumb = ({ image }) => {
    return (
      <img
        style={{ height: "100px" }}
        src={URL.createObjectURL(image)}
        alt={image.name}
      />
    );
  };

  const getMemberDetails = async (e) => {
    try {
      const postData = {
        id: userIdToBeEdited,
        user_id: localStorage.getItem("loggedUserId"),
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("loggedToken")}`,
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/viewmember",
        body,
        config
      );
      if (res.data.success == 1) {
        setOtherEducation(res.data.data.othereducation);
        setHeadRelation(res.data.data.relation);
        setMemberName(res.data.data.fullname);
        setfatherHusbandName(res.data.data.father_name);
        setGender(res.data.data.gender);
        setDob(res.data.data.dob);
        setMaritalStatus(res.data.data.marital_status);
        setMobileNo(res.data.data.mobile);
        setAddress(res.data.data.full_address);
        setCountry(countriesList[0].name);
        await changeCountry();

        let state = statesList.find((x) => x.id == res.data.data.state_id);
        if (state != undefined) {
          setState(state.name);
          await changeState(state.id);
        }

        if (
          res.data.data.district_id != null &&
          res.data.data.district_id != "0"
        ) {
          if (citiesList != undefined) {
            let district = citiesList.find(
              (x) => x.id == res.data.data.district_id
            );
            setCity(district.name);
          }
        }
        setPinCode(res.data.data.zip_code);
        setOtherEducation(res.data.data.othereducation);
        setEducation(res.data.data.education);
        setService(res.data.data.occupation);
        setOffice(res.data.data.office);
        setaccomodation(res.data.data.awash);
        //setPanCard(res.data.data.)
        setBloodGroup(res.data.data.blood_group);

        if (res.data.data.profile_image != null) {
          setImagePath(
            "https://api.golapurvajainsamaj.com/public/upload/janaganana/"
          );
          setImage(res.data.data.profile_image);
        }
      } else {
        alert("Error while fetching member details");
        return false;
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };

  const getCountries = async () => {
    await axios
      .post("https://api.golapurvajainsamaj.com/api/v1/staticlists/getcountry")
      .then((response) => {
        setSelectedCountry(response.data.data);
        countriesList = response.data.data;
      });
  };

  const getStates = async () => {
    await axios
      .post("https://api.golapurvajainsamaj.com/api/v1/staticlists/getstate")
      .then((response) => {
        setSelectedState(response.data.data);
        statesList = response.data.data;
      });
  };

  const getCities = async (e) => {
    await axios
      .post(
        "https://api.golapurvajainsamaj.com/api/v1/staticlists/getcity?state_id=" +
          e
      )
      .then((response) => {
        setSelectedCity(response.data.data);
        citiesList = response.data.data;
      });
  };

  useEffect(async () => {
    if (localStorage.getItem("loggedToken") != undefined) {
      await getCountries();
      await getStates();
      if (userIdToBeEdited != undefined) await getMemberDetails();
    } else {
      history.push({ pathname: "/login" });
    }
  }, [setSelectedCountry]);

  const changeCountry = async (event) => {
    if (event != undefined) setCountry(event);
    await getStates();
  };

  const changeState = async (event) => {
    setState(event);
    await getCities(event);
  };

  const changeCity = (event) => {
    setCity(event);
  };

  const saveMemberDetails = async (e) => {
    try {
      const formData = new FormData();
      if (userIdToBeEdited != "") formData.append("id", userIdToBeEdited);
      formData.append("user_id", localStorage.getItem("loggedUserId"));
      formData.append("relation", headRelation);
      formData.append("fullname", memberName);
      formData.append("wife_father", fatherHusbandName);
      formData.append("gender", gender);
      formData.append("dob", dob);
      formData.append("marital_status", maritalStatus);
      formData.append("mobile", mobileNo);
      formData.append("full_address", address);
      formData.append("country_id", country);
      formData.append("state_id", state);
      formData.append("district_id", city);
      formData.append("zip_code", pinCode);
      formData.append("education", education);
      formData.append("othereducation", otherEducation);
      formData.append("occupation", service);
      formData.append("office", office);
      formData.append("blood_group", bloodGroup);
      formData.append("profile_image", file);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            token == undefined ? localStorage.getItem("loggedToken") : token
          }`,
        },
      };
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/addeditmember",
        formData,
        config
      );
      if (res.data.success == 1) {
        alert(res.data.message);
      } else {
        alert("Error while updating the entries");
        return false;
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      // after api is called
      saveMemberDetails();
    }
  };

  const formValidation = () => {
    const memberNameErr = {};
    const headRelationErr = {};
    const fatherHusbandErr = {};
    const genderErr = {};
    const dobErr = {};
    const maritalStatusErr = {};
    const mobileNoErr = {};
    const addressErr = {};
    const countryErr = {};
    const stateErr = {};
    const cityErr = {};
    const pinCodeErr = {};
    const educationErr = {};
    const otherEducation = {};
    const serviceErr = {};
    const officeErr = {};
    const accomodationErr = {};
    const panCardErr = {};
    const bloodGroupErr = {};
    const fileErr = {};
    let isValid = true;

    if (memberName == null) {
      memberNameErr.EmptyMemberName = "Fill the member name";
      isValid = false;
    }

    if (headRelation == undefined) {
      headRelationErr.EmptyHeadRelation = "Select the relation with head";
      isValid = false;
    }

    if (fatherHusbandName == null) {
      fatherHusbandErr.EmptyFatherHusbandName =
        "Fill the father or husband name";
      isValid = false;
    }

    if (gender == undefined) {
      genderErr.EmptyGender = "Select the gender";
      isValid = false;
    }

    if (dob == "") {
      dobErr.EmptyDob = "Select the DOB";
      isValid = false;
    }

    if (maritalStatus == undefined) {
      maritalStatusErr.EmptyMaritalStatus = "Select the Marital Status";
      isValid = false;
    }

    if (mobileNo == null) {
      mobileNoErr.EmptyMobileNo = "Fill the mobile no";
      isValid = false;
    }

    if (address == null) {
      addressErr.EmptyAddress = "Fill the address";
      isValid = false;
    }

    if (country == undefined) {
      countryErr.EmptyCountry = "Select the Country";
      isValid = false;
    }

    if (state == undefined) {
      stateErr.EmptyState = "Select the State";
      isValid = false;
    }

    if (city == undefined) {
      cityErr.EmptyCity = "Select the City";
      isValid = false;
    }

    if (pinCode == undefined || pinCode == null) {
      pinCodeErr.EmptyCity = "Fill the pin code";
      isValid = false;
    }

    if (education == undefined) {
      educationErr.EmptyEducation = "Select the pin code";
      isValid = false;
    }

    if (otherEducation == undefined || otherEducation == null) {
      otherEducationErr.EmptyOtherEducation = "Fill the education";
      isValid = false;
    }

    if (service == undefined) {
      serviceErr.EmptyService = "Select the service";
      isValid = false;
    }

    if (office == undefined) {
      officeErr.EmptyOffice = "Fill the office";
      isValid = false;
    }

    if (accomodation == undefined) {
      accomodationErr.EmptyOffice = "Select the accomodation";
      isValid = false;
    }

    if (bloodGroup == undefined) {
      bloodGroupErr.EmptyBloodGroup = "Select the blood group";
      isValid = false;
    }

    if (file == "") {
      fileErr.EmptyPhoto = "Upload the photo";
      isValid = false;
    }

    setMemberNameErr(memberNameErr);
    setHeadRelationErr(headRelationErr);
    setfatherHusbandNameErr(fatherHusbandErr);
    setGenderErr(genderErr);
    setDobErr(dobErr);
    setMaritalStatusErr(maritalStatusErr);
    setMobileNoErr(mobileNoErr);
    setAddressErr(addressErr);
    setCountryErr(countryErr);
    setStateErr(stateErr);
    setCityErr(cityErr);
    setPinCodeErr(pinCodeErr);
    setEducationErr(educationErr);
    setOtherEducationErr(otherEducationErr);
    setServiceErr(serviceErr);
    setOfficeErr(officeErr);
    setaccomodationErr(accomodationErr);
    setPanCardErr(panCardErr);
    setBloodGroupErr(bloodGroupErr);
    setFileErr(fileErr);
    return isValid;
  };
  return (
    <div>
      <div className="mt-3" style={{ backgroundColor: "#FFFBCC" }}>
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            पारिवारिक{" "}
            <b style={{ color: "#e47257" }}>
              विवरण
              <p style={{ color: "White" }}>मुख्य पृष्ठ / पारिवारिक विवरण</p>
            </b>
          </h2>
        </div>
        <form>
          <div className="row" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="HeadRelation">
                <b>मुखिया से रिश्ता</b>
              </label>
              <br />
              <Select
                showSearch
                id="HeadRelation"
                placeholder="-- Select --"
                name="headRelation"
                className="rounded"
                value={headRelation}
                onChange={(event) => setHeadRelation(event)}
              >
                <option value="Grandfather">Grandfather</option>
                <option value="Grandmother">Grandmother</option>
                <option value="Father">Father</option>
                <option value="Father in law">Father in law</option>
                <option value="Mother">Mother</option>
                <option value="Mother in law">Mother in law</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Sister in law">Sister in law</option>
                <option value="Husband">Husband</option>
                <option value="Wife">Wife</option>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="Daughter in law">Daughter in law</option>
                <option value="Grandson">Grandson</option>
                <option value="Granddaughter">Granddaughter</option>
                <option value="Uncle">Uncle</option>
                <option value="Aunt">Aunt</option>
                <option value="Other">Other</option>
              </Select>
              <br />
              {Object.keys(headRelationErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{headRelationErr[key]}</div>
                );
              })}
            </div>
            <div className="col-3">
              <label htmlFor="MemberName">
                <b>नाम</b>
              </label>
              <Input
                id="MemberName"
                placeholder="नाम"
                name="memberName"
                className="rounded"
                value={memberName}
                onChange={(event) => setMemberName(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="HusbandFatherName">
                <b>पिता / पति का नाम</b>
              </label>
              <Input
                id="HusbandFatherName"
                placeholder="पिता / पति का नाम"
                name="memberName"
                className="rounded"
                value={fatherHusbandName}
                onChange={(event) => setfatherHusbandName(event.target.value)}
              />
              <br />
              {Object.keys(fatherHusbandNameErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>
                    {fatherHusbandNameErr[key]}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="Gender">
                <b>लिंग</b>
              </label>
              <br />
              <Select
                showSearch
                id="Gender"
                placeholder="-- Select --"
                name="gender"
                className="rounded"
                value={gender}
                onChange={(event) => setGender(event)}
              >
                <Option value="1">Male</Option>
                <Option value="2">Female</Option>
              </Select>
              <br />
              {Object.keys(genderErr).map((key) => {
                return <div style={{ color: "red" }}>{genderErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="DOB">
                <b>जन्म तिथि</b>
              </label>
              <br />
              <DatePicker
                id="DOB"
                style={{ width: "100%" }}
                name="DOB"
                format={dateFormatList}
                value={moment(dob, dateFormatList[0])}
                className="rounded"
                onChange={(event) => setDob(event)}
              />
              <br />
              {Object.keys(dobErr).map((key) => {
                return <div style={{ color: "red" }}>{dobErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="MaritalStatus">
                <b>वैवाहिक स्थिति</b>
              </label>
              <br />
              <Select
                showSearch
                id="MaritalStatus"
                placeholder="-- Select --"
                name="maritalStatus"
                className="rounded"
                value={maritalStatus}
                onChange={(event) => setMaritalStatus(event)}
              >
                <Option value="1">Single</Option>
                <Option value="2">Married</Option>
                <Option value="3">Divorced</Option>
                <Option value="4">Widow</Option>
              </Select>
              <br />
              {Object.keys(maritalStatusErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{maritalStatusErr[key]}</div>
                );
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="MobileNo">
                <b>मोबाइल नंबर</b>
              </label>
              <br />
              <Input
                id="MobileNo"
                placeholder="मोबाइल नंबर"
                name="mobileNo"
                className="rounded"
                value={mobileNo}
                onChange={(event) => setMobileNo(event.target.value)}
              />
              <br />
              {Object.keys(mobileNoErr).map((key) => {
                return <div style={{ color: "red" }}>{mobileNoErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="address">
                <b>पता</b>
              </label>
              <Input
                id="address"
                placeholder="पता"
                name="address"
                className="rounded"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
              <br />
              {Object.keys(addressErr).map((key) => {
                return <div style={{ color: "red" }}>{addressErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="country">
                <b>देश</b>
              </label>
              <br />
              <Select
                id="country"
                showSearch
                name="country"
                className="rounded"
                placeholder="-- देश --"
                optionFilterProp="children"
                value={country}
                onChange={changeCountry}
              >
                {countries.map((e, key) => {
                  return (
                    <option key={key} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </Select>
              <br />
              {Object.keys(countryErr).map((key) => {
                return <div style={{ color: "red" }}>{countryErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="State">
                <b>राज्य</b>
              </label>
              <br />
              <Select
                id="state"
                showSearch
                name="state"
                placeholder="-- राज्य --"
                className="rounded"
                optionFilterProp="children"
                value={state}
                onChange={changeState}
              >
                {states.map((e, key) => {
                  return (
                    <option key={key} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </Select>
              <br />
              {Object.keys(stateErr).map((key) => {
                return <div style={{ color: "red" }}>{stateErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="Region">
                <b>जिला</b>
              </label>
              <br />
              <Select
                id="city"
                showSearch
                name="city"
                placeholder="-- जिला --"
                optionFilterProp="children"
                className="rounded"
                value={city}
                onChange={changeCity}
              >
                {cities.map((e, key) => {
                  return (
                    <option key={key} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </Select>
              <br />
              {Object.keys(cityErr).map((key) => {
                return <div style={{ color: "red" }}>{cityErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="Pincode">
                <b>पिनकोड</b>
              </label>
              <Input
                id="Pincode"
                placeholder="पिनकोड"
                name="pincode"
                className="rounded"
                value={pinCode}
                onChange={(event) => setPinCode(event.target.value)}
              />
              <br />
              {Object.keys(pinCodeErr).map((key) => {
                return <div style={{ color: "red" }}>{pinCodeErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="Education">
                <b>शैक्षणिक पात्रता</b>
              </label>
              <br />
              <Select
                showSearch
                id="Education"
                placeholder="-- Select --"
                name="education"
                className="rounded"
                value={education}
                onChange={(event) => setEducation(event)}
              >
                <option value="Nill">Nill</option>
                <option value="Nursery">Nursery</option>
                <option value="KG1">KG1</option>
                <option value="KG2">KG2</option>
                <option value="1st">1st</option>
                <option value="2nd">2nd</option>
                <option value="3rd">3rd</option>
                <option value="4th">4th</option>
                <option value="5th">5th</option>
                <option value="6th">6th</option>
                <option value="7th">7th</option>
                <option value="8th">8th</option>
                <option value="9th">9th</option>
                <option value="10">10th</option>
                <option value="12">12th</option>
                <option value="graduate Running">Graduate Running</option>
                <option value="graduate">Graduate</option>
                <option value="post_graduate" selected="selected">
                  Post Graduate
                </option>
                <option value="Post Graduate Running">
                  Post Graduate Running
                </option>
                <option value="phd">Ph.D</option>
                <option value="other">Other</option>
              </Select>
              <br />
              {Object.keys(educationErr).map((key) => {
                return <div style={{ color: "red" }}>{educationErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="OtherEducation">
                <b>अन्य शिक्षा</b>
              </label>
              <Input
                id="OtherEducation"
                placeholder="अन्य शिक्षा"
                name="otherEducation"
                className="rounded"
                value={otherEducation}
                onChange={(event) => setOtherEducation(event.target.value)}
              />
              <br />
              {Object.keys(otherEducationErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{otherEducationErr[key]}</div>
                );
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="Service">
                <b>व्यवसाय / सर्विस</b>
              </label>
              <br />
              <Select
                showSearch
                id="Service"
                placeholder="-- Select --"
                name="service"
                className="rounded"
                value={service}
                onChange={(event) => setService(event)}
              >
                <option value="Business">Business</option>
                <option value="Job">Job</option>
                <option value="Professional">Professional</option>
                <option value="Self Employed">Self Employed</option>
                <option value="Student">Student</option>
                <option value="House Work">House Work</option>
                <option value="Retired Person">Retired Person</option>
                <option value="House Wife">House Wife</option>
                <option value="Government Job">Government Job</option>
              </Select>
              <br />
              {Object.keys(serviceErr).map((key) => {
                return <div style={{ color: "red" }}>{serviceErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="Office">
                <b>कार्यालय / प्रतिष्ठान का नाम & पता</b>
              </label>
              <Input
                id="Office"
                placeholder="कार्यालय / प्रतिष्ठान का नाम & पता"
                name="office"
                className="rounded"
                value={office}
                onChange={(event) => setOffice(event.target.value)}
              />
              <br />
              {Object.keys(officeErr).map((key) => {
                return <div style={{ color: "red" }}>{officeErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="Accomodation">
                <b>आवास</b>
              </label>
              <br />
              <Select
                showSearch
                id="Accomodation"
                placeholder="-- Select --"
                name="accomodation"
                className="rounded"
                value={accomodation}
                onChange={(event) => setaccomodation(event)}
              >
                <Option value="1">Rent</Option>
                <Option value="1">House</Option>
              </Select>
              <br />
              {Object.keys(accomodationErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{accomodationErr[key]}</div>
                );
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            {/* <div className="col-3">
              <label for="PanCard">
                <b>पेन कार्ड है</b>
              </label>
              <Select
                showSearch
                id="PanCard"
                placeholder="-- Select --"
                name="panCard"
                className="rounded"
                value={panCard}
                onChange={(event) => setPanCard(event)}
                style={{ width: 280 }}
              >
                <Option value="1">Yes</Option>
                <Option value="2">No</Option>
              </Select>
              <br />
              {Object.keys(panCardErr).map((key) => {
                return <div style={{ color: "red" }}>{panCardErr[key]}</div>;
              })}
            </div> */}
            <div className="col-3">
              <label htmlFor="BloodGroup">
                <b>रक्त समूह</b>
              </label>
              <br />
              <Select
                showSearch
                id="BloodGroup"
                placeholder="-- Select --"
                name="bloodGroup"
                className="rounded"
                value={bloodGroup}
                onChange={(event) => setBloodGroup(event)}
              >
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
              </Select>
              <br />
              {Object.keys(bloodGroupErr).map((key) => {
                return <div style={{ color: "red" }}>{bloodGroupErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-6">
              <input type="file" onChange={handleUpload} />
              <br />
              <span>(*) Please Upload Passport Size Photo..</span>
              <br />
              {Object.keys(fileErr).map((key) => {
                return <div style={{ color: "red" }}>{fileErr[key]}</div>;
              })}
              {/* <p>Filename: {file.name}</p>
              <p>File type: {file.type}</p>
              <p>File size: {file.size} bytes</p> */}
              <br />
              {isImageUploaded ? (
                file && <ImageThumb image={file} />
              ) : imagePath == "" ? (
                file && <ImageThumb image={file} />
              ) : (
                <img style={{ height: "100px" }} src={imagePath + image}></img>
              )}
            </div>
            <div className="col-6">
              <Button
                type="primary"
                size="large"
                onClick={handleSubmit}
                className="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FamilyMembers;

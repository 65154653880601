import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import LeftHeader from "./LeftHeader";
import WithoutLoggedInHeader from "./WithoutLoggedInHeader";
import LoggedInHeader from "./LoggedInHeader";

const Topheader = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState(false);
  const history = useHistory();
  useEffect(() => {
    let user = localStorage.getItem("user-info");
    if (user != null) {
      setIsLoggedIn(true);
      setUserName(user);
      history.push({ pathname: "/", user_id: user.id });
    }
  }, []);

  return (
    <div className="topbar dark">
      <div className="container">
        <LeftHeader />
        {!isLoggedIn ? (
          <WithoutLoggedInHeader isUserLoggedIn={isLoggedIn} />
        ) : (
          <LoggedInHeader
            isUserLoggedIn={isLoggedIn}
            loggedUserName={userName}
          />
        )}
        ;
      </div>
    </div>
  );
};
export default Topheader;

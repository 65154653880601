import React, { useState, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate";
import PageHeader from "../../common/PageHeader";
import istyleded from "./Signup.module.css";
import { Button, Input } from "antd";
import axios from "axios";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";

const SignUp = (props) => {
  const [otpByUser, setotpByUser] = useState("");

  const [fullName, setfullName] = useState("");
  const [fullNameErr, setfullNameErr] = useState({});

  const [mobileNo, setmobileNo] = useState("");
  const [mobileNoErr, setmobileNoErr] = useState({});

  const [password, setPassword] = useState("");
  const [passwordErr, setpasswordErr] = useState({});

  const [show, setShow] = useState(false);
  //const [otpByUser, setotpByUser] = useState(false);
  const [otpFromApi, setOtpFromApi] = useState(true);
  const [showOtpError, setShowOtpError] = useState(false);
  const Error = () => (
    <div id="error" className="text-danger">
      The entered OTP does not match
    </div>
  );
  const handleClose = () => {
    if (otpByUser == otpFromApi) {
      saveUserDetails();
      setShow(false);
      setShowOtpError(false);
    } else {
      setShowOtpError(true);
    }
  };

  const handleOtpChange = (ev) => setotpByUser(ev.target.value);

  const saveUserDetails = async (e) => {
    try {
      const postData = {
        fullname: fullName,
        mobile: mobileNo,
        password: password,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/register",
        body,
        config
      );
      if (res.data.success == 1) {
        alert("User registered successfully!");
        history.push({
          pathname: "/login",
          lastInsertedUser: res.data.lastinst.id,
        });
      } else {
        alert("Error while creating user");
        return false;
      }
    } catch (err) {
      console.error(err.response.data);
    }
  };

  const formValidation = () => {
    const fullnameErr = {};
    const mobileNoErr = {};
    const passwordErr = {};
    let isValid = true;

    if (fullName.trim().length == 0) {
      fullNameErr.EmptyFullName = "Fill the Name";
      isValid = false;
    }

    if (mobileNo.trim().length == 0) {
      mobileNoErr.EmptyMobileNo = "Fill the mobile No";
      isValid = false;
    }

    if (password.trim().length == 0) {
      passwordErr.EmptyPassword = "Fill the password";
      isValid = false;
    }

    setfullNameErr(fullNameErr);
    setmobileNoErr(mobileNoErr);
    setpasswordErr(passwordErr);
    return isValid;
  };

  const submitButton = async (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      try {
        const postData = {
          mobile: mobileNo,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const body = JSON.stringify(postData);
        const res = await axios.post(
          "https://api.golapurvajainsamaj.com/api/v1/users/generateotp",
          body,
          config
        );
        if (res.data.success == 1) {
          setOtpFromApi(res.data.otp);
          setShow(true);
          setotpByUser(true);
        }
      } catch (err) {
        console.error(err.response.data);
      }
    }
  };

  const navigation = useHistory();
  const { history } = props;
  return (
    <Fragment>
      <PageHeader pageheadertitle={"Signup"} />
      <section>
        <div className="block gray">
          <div className="container">
            <div className="row">
              <div className="col-md-12 column">
                <div className="clearfix"></div>
                <br />
                <div className="ask-question">
                  <p>Register</p>
                  <form className="contact-form white-bg">
                    <div className="form-group">
                      <Input
                        id="FullName"
                        placeholder="FullName"
                        class="rounded"
                        name="fullname"
                        value={fullName}
                        onChange={(event) => setfullName(event.target.value)}
                      />
                      <br />
                      {Object.keys(fullNameErr).map((key) => {
                        return (
                          <div style={{ color: "red", textAlign: "left" }}>
                            {fullNameErr[key]}
                          </div>
                        );
                      })}
                    </div>
                    <div className="form-group">
                      <Input
                        id="Mobile"
                        placeholder="Mobile"
                        class="rounded"
                        name="mobile"
                        value={mobileNo}
                        onChange={(event) => setmobileNo(event.target.value)}
                      />
                      <br />
                      {Object.keys(mobileNoErr).map((key) => {
                        return (
                          <div style={{ color: "red", textAlign: "left" }}>
                            {mobileNoErr[key]}
                          </div>
                        );
                      })}
                    </div>
                    <div className="form-group">
                      <Input
                        id="Password"
                        placeholder="password"
                        class="rounded"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <br />
                      {Object.keys(passwordErr).map((key) => {
                        return (
                          <div style={{ color: "red", textAlign: "left" }}>
                            {passwordErr[key]}
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12">
                      <p>
                        <Link to="/login" title="">
                          Login
                        </Link>
                      </p>
                    </div>
                    <div className="col-md-12">
                      <Button
                        type="primary"
                        onClick={submitButton}
                        size="large"
                        className="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>OTP Verification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-12 column">
                  <div className="clearfix"></div>
                  <form>
                    <div class="form-group">
                      <Input
                        type="number"
                        maxLength={6}
                        id="OTP"
                        placeholder="Enter the OTP received"
                        class="rounded"
                        name="OTP"
                        value={otpByUser || ""}
                        onChange={handleOtpChange}
                      />
                      {showOtpError ? <Error /> : null}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </Fragment>
  );
};

SignUp.propTypes = {
  history: PropTypes.object,
};

export default SignUp;

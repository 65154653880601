import React, {Fragment} from 'react';
import Routes from './Routes';
function App() {
  return (
      <Fragment>
          <Routes />
      </Fragment>
  );
}
export default App;

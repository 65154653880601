import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import istyleded from "./Header.module.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
const LoggedInHeader = (props) => {
  const history = useHistory();
  const logOut = () => {
    localStorage.clear();
    history.push({ pathname: "/login", user_id: 0 });
  };

  const changePassword = () => {
    history.push({
      pathname: "/changePassword",
      user_id:
        localStorage.getItem("loggedUserId") != undefined
          ? localStorage.getItem("loggedUserId")
          : 0,
    });
  };

  const home = () => {
    history.push({
      pathname: "/home",
      user_id:
        localStorage.getItem("loggedUserId") != undefined
          ? localStorage.getItem("loggedUserId")
          : 0,
    });
  };

  return (
    <Fragment>
      <Nav        
        className={`inline-list registration-btn ${istyleded.float_right}`}
      >
        <NavDropdown title={props.loggedUserName}>
          <NavDropdown.Item onClick={home}>Our Profile</NavDropdown.Item>
          <NavDropdown.Item onClick={changePassword}>
            Change Password
          </NavDropdown.Item>
          <NavDropdown.Item onClick={logOut}>Sign Out</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Fragment>
  );
};
export default LoggedInHeader;

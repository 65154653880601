import React, { Component, useState, useEffect } from "react";
import render from "react-dom";
import { Button, DatePicker, Input, Select } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import "../../App.css";

const headingStyle = {
  color: "white",
  backgroundColor: "#4D4D4D",
  paddingTop: "6em",
  paddingBottom: "1em",
};

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState([]);
  useEffect(() => {
    getAboutUsData();
  }, []);

  const getAboutUsData = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/home",
        config
      );
      if (res.data.success == 1) {
        setAboutUs(res.data.abouts);
      } else {
        alert("Error while fetching member details");
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: "#FFFBCC" }}>
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            हमारा{" "}
            <b style={{ color: "#e47257" }}>
              परिचय
              <p style={{ color: "White" }}>मुख्य पृष्ठ / हमारा परिचय</p>
            </b>
          </h2>
        </div>
        <div style={{ backgroundColor: "#FFFBCC" }}>
          <div class="container">
            <div class="row">
              <div class="col-md-12 column">
                <div class="who-we-are">
                  <div class="row">
                    <div class="col-md-12 column">
                      <div className="who-we-text">
                        {aboutUs.map((each) => (
                          <div
                            className="eventDesc"
                            dangerouslySetInnerHTML={{
                              __html: each.bodytext,
                            }}
                          />
                        ))}
                        ;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

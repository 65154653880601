import React, { Component, useState, useEffect } from "react";
import render from "react-dom";
import { Button, DatePicker, Input, Select } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import "../../App.css";
import moment from "moment";

const headingStyle = {
  color: "white",
  backgroundColor: "#4D4D4D",
  paddingTop: "6em",
  paddingBottom: "1em",
};

const AddMember = () => {
  let countriesList;
  let statesList;
  let citiesList;
  let token;
  const history = useHistory();
  const location = useLocation();
  const { userIdToBeEdited } =
    location.state != undefined ? location.state : "";
  const [selectedCountry, setSelectedCountry] = useState([]);
  const countries = selectedCountry.map((country) => country);
  const [selectedState, setSelectedState] = useState([]);
  const states = selectedState.map((state) => state);

  const [selectedCity, setSelectedCity] = useState([]);
  const cities = selectedCity.map((city) => city);

  const [memberName, setMemberName] = useState("");
  const [memberNameErr, setMemberNameErr] = useState({});

  const [spouseName, setSpouseName] = useState("");
  const [spouseNameErr, setSpouseNameErr] = useState({});
  const [fatherHusbandName, setfatherHusbandName] = useState("");
  const [fatherHusbandNameErr, setfatherHusbandNameErr] = useState({});

  const [motherName, setMotherName] = useState("");
  const [motherNameErr, setmotherNameErr] = useState({});

  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState({});
  const [dob, setDob] = useState("");
  const [dobErr, setDobErr] = useState({});
  const [maritalStatus, setMaritalStatus] = useState("");
  const [maritalStatusErr, setMaritalStatusErr] = useState({});

  const [marriageDate, setmarriageDate] = useState("");
  const [marriageDateErr, setMarriageDateErr] = useState({});

  const [mobileNo, setMobileNo] = useState("");
  const [mobileNoErr, setMobileNoErr] = useState({});

  const [whatsapp, setWhatsapp] = useState("");
  const [whatsappErr, setWhatsappErr] = useState({});

  const [emailId, setEmailId] = useState("");
  const [emailIdErr, setEmailIdErr] = useState({});

  const [address, setAddress] = useState("");
  const [addressErr, setAddressErr] = useState({});
  const [country, setCountry] = useState();
  const [countryErr, setCountryErr] = useState({});
  const [state, setState] = useState();
  const [stateErr, setStateErr] = useState({});
  const [city, setCity] = useState();
  const [cityErr, setCityErr] = useState({});

  // const [tehsil, setTehsil] = useState();
  // const [tehsilErr, setTehsilErr] = useState({});
  const [cityVillage, setCityVillage] = useState();
  const [cityVillageErr, setCityVillageErr] = useState({});
  const [pinCode, setPinCode] = useState();
  const [pinCodeErr, setPinCodeErr] = useState({});
  const [oldLiving, setOldLiving] = useState();
  const [oldLivingErr, setOldLivingErr] = useState({});
  const [education, setEducation] = useState();
  const [educationErr, setEducationErr] = useState({});
  const [otherEducation, setOtherEducation] = useState();
  const [otherEducationErr, setOtherEducationErr] = useState({});
  const [annualIncome, setAnnualIncome] = useState();
  const [annualIncomeErr, setAnnualIncomeErr] = useState({});
  const [service, setService] = useState();
  const [serviceErr, setServiceErr] = useState({});
  const [office, setOffice] = useState();
  const [officeErr, setOfficeErr] = useState({});
  const [accomodation, setaccomodation] = useState();
  const [accomodationErr, setaccomodationErr] = useState({});
  const [povertyLineCard, setPovertyLineCard] = useState();
  const [povertyLineCardErr, setPovertyLineCardErr] = useState({});
  const [panCard, setPanCard] = useState();
  const [panCardErr, setPanCardErr] = useState({});
  const [bloodGroup, setBloodGroup] = useState();
  const [bloodGroupErr, setBloodGroupErr] = useState({});
  const [totalWomenMembers, setTotalWomenMembers] = useState();
  const [totalWomenMemberErr, settotalWomenMemberErr] = useState({});
  const [totalMenMembers, setTotalMenMembers] = useState();
  const [totalMenMemberErr, setTotalMenMemberErr] = useState({});
  const [coordinatorName, setCoordinatorName] = useState();
  const [coordinatorNameErr, setCoordinatorNameErr] = useState({});
  const [phoneNo, setPhoneNo] = useState();
  const [phoneNoErr, setPhoneNoErr] = useState({});
  const [photo, setPhoto] = useState();
  const [file, setFile] = React.useState("");
  const [fileErr, setFileErr] = useState({});
  const [imagePath, setImagePath] = useState("");
  const [image, setImage] = useState("");
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const dateFormatList = ["YYYY/MM/DD"];

  const handleUpload = (event) => {
    setIsImageUploaded(true);
    setImage("");
    setImagePath("");
    setFile(event.target.files[0]);
  };
  /**
   * Component to display thumbnail of image.
   */
  const ImageThumb = ({ image }) => {
    return (
      <img
        style={{ height: "100px" }}
        src={URL.createObjectURL(image)}
        alt={image.name}
      />
    );
  };

  const getMemberDetails = async (e) => {
    try {
      const postData = {
        id: userIdToBeEdited,
        user_id: localStorage.getItem("loggedUserId"),
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("loggedToken")}`,
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/viewmember",
        body,
        config
      );
      if (res.data.success == 1) {
        setDob(res.data.data.dob);
        setMaritalStatus(res.data.data.marital_status);
        setMemberName(res.data.data.fullname);
        setSpouseName(res.data.data.wife_father);
        setMotherName(res.data.data.mother_name);
        setOldLiving(res.data.data.previousplace);
        setPovertyLineCard(res.data.data.garibirekha);
        setPhoneNo(res.data.data.leadphone);
        setOtherEducation(res.data.data.othereducation);
        setMemberName(res.data.data.fullname);
        setfatherHusbandName(res.data.data.wife_father);
        setTotalWomenMembers(res.data.data.femalecond);
        setTotalMenMembers(res.data.data.malecond);
        setAnnualIncome(res.data.data.income);
        setEmailId(res.data.data.email);
        setGender(res.data.data.gender);
        setMobileNo(res.data.data.mobile);
        setAddress(res.data.data.full_address);
        setCityVillage(res.data.data.city_name);
        setmarriageDate(res.data.data.dom);
        setCountry(countriesList[0].name);
        await changeCountry();

        let state = statesList.find((x) => x.id == res.data.data.state_id);
        if (state != undefined) {
          setState(state.name);
          await changeState(state.id);
        }

        if (
          res.data.data.district_id != null &&
          res.data.data.district_id != "0"
        ) {
          if (citiesList != undefined) {
            let district = citiesList.find(
              (x) => x.id == res.data.data.district_id
            );
            setCity(district.name);
          }
        }
        setPinCode(res.data.data.zip_code);
        setEducation(res.data.data.education);
        setService(res.data.data.occupation);
        setOffice(res.data.data.office);
        setaccomodation(res.data.data.awash);
        setBloodGroup(res.data.data.blood_group);

        if (res.data.data.profile_image != null) {
          setImagePath(
            "https://api.golapurvajainsamaj.com/public/upload/janaganana/"
          );
          setImage(res.data.data.profile_image);
        }
      } else {
        alert("Error while fetching member details");
        return false;
      }
    } catch (err) {
      if (err == undefined) {
        alert("Error occurred while fetching member details");
        return false;
      }
      if (err.response.status == 401) {
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };

  const getCountries = async () => {
    await axios
      .post("https://api.golapurvajainsamaj.com/api/v1/staticlists/getcountry")
      .then((response) => {
        setSelectedCountry(response.data.data);
        countriesList = response.data.data;
      });
  };

  const getStates = async () => {
    await axios
      .post("https://api.golapurvajainsamaj.com/api/v1/staticlists/getstate")
      .then((response) => {
        setSelectedState(response.data.data);
        statesList = response.data.data;
      });
  };

  const getCities = async (e) => {
    await axios
      .post(
        "https://api.golapurvajainsamaj.com/api/v1/staticlists/getcity?state_id=" +
          e
      )
      .then((response) => {
        setSelectedCity(response.data.data);
        citiesList = response.data.data;
      });
  };

  useEffect(async () => {
    if (localStorage.getItem("loggedToken") != undefined) {
      await getCountries();
      await getStates();
      if (userIdToBeEdited != undefined) await getMemberDetails();
    } else {
      history.push({ pathname: "/login" });
    }
  }, [setSelectedCountry]);

  const changeCountry = async (event) => {
    if (event != undefined) setCountry(event);
    await getStates();
  };

  const changeState = async (event) => {
    setState(event);
    await getCities(event);
  };

  const changeCity = (event) => {
    setCity(event);
  };

  const saveMemberDetails = async (e) => {
    try {
      const formData = new FormData();
      formData.append("id", userIdToBeEdited);
      formData.append("user_id", localStorage.getItem("loggedUserId"));
      formData.append("fullname", memberName);
      formData.append("wife_father", fatherHusbandName);
      formData.append("mother_name", motherName);
      formData.append("gender", gender);
      formData.append("dob", dob);
      formData.append("marital_status", maritalStatus);
      formData.append("dom", marriageDate);
      formData.append("mobile", mobileNo);
      formData.append("email", emailId);
      formData.append("full_address", address);
      formData.append("country_id", country);
      formData.append("state_id", state);
      formData.append("district_id", city);
      formData.append("city_name", cityVillage);
      formData.append("zip_code", pinCode);
      formData.append("previousplace", oldLiving);
      formData.append("education", education);
      formData.append("othereducation", otherEducation);
      formData.append("occupation", service);
      formData.append("office", office);
      formData.append("income", annualIncome);
      formData.append("awash", accomodation);
      formData.append("garibirekha", povertyLineCard);
      formData.append("femalecond", totalWomenMembers);
      formData.append("malecond", totalMenMembers);
      formData.append("blood_group", bloodGroup);
      formData.append("profile_image", file);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            token == undefined ? localStorage.getItem("loggedToken") : token
          }`,
        },
      };
      // const body = formData;
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/addedithead",
        formData,
        config
      );
      if (res.data.success == 1) {
        alert(res.data.message);
        history.push({ pathname: "/home" });
      } else {
        alert("Error while updating the entries");
        return false;
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      saveMemberDetails();
    }
  };

  const formValidation = () => {
    const memberNameErr = {};
    const spouseNameErr = {};
    const fatherHusbandNameErr = {};
    const genderErr = {};
    const dobErr = {};
    const maritalStatusErr = {};
    const mobileNoErr = {};
    const addressErr = {};
    const countryErr = {};
    const stateErr = {};
    const cityErr = {};
    const pinCodeErr = {};
    const educationErr = {};
    const otherEducation = {};
    const serviceErr = {};
    const officeErr = {};
    const accomodationErr = {};
    const panCardErr = {};
    const bloodGroupErr = {};
    const motherNameErr = {};
    const marriageDateErr = {};
    const whatsappErr = {};
    const emailIdErr = {};
    //const tehsilErr = {};
    const cityVillageErr = {};
    const oldLivingErr = {};
    const povertyLineCardErr = {};
    const coordinatorNameErr = {};
    const phoneNoErr = {};
    const annualIncomeErr = {};
    const totalWomenMemberErr = {};
    const totalMenMemberErr = {};
    const fileErr = {};
    let isValid = true;

    if (memberName == "") {
      memberNameErr.EmptyMemberName = "Fill the member name";
      isValid = false;
    }

    if (spouseName == "") {
      spouseNameErr.EmptySpuseName = "Fill the spouse name";
      isValid = false;
    }

    if (motherName == "") {
      motherNameErr.EmptyMotherName = "Fill the mother name";
      isValid = false;
    }

    if (marriageDate == "") {
      marriageDateErr.EmptyMarriageDate = "Select the marriage date";
      isValid = false;
    }

    if (whatsapp == "") {
      whatsappErr.EmptyWhatsApp = "Fill the Whatsapp number";
      isValid = false;
    }

    if (emailId == "") {
      emailIdErr.EmptyEmailId = "FIll the Email Id";
      isValid = false;
    }

    // if(tehsil == undefined){
    //   tehsilErr.EmptyTehsil = "Select the tehsil";
    //   isValid = false;
    // }

    if (cityVillage == undefined) {
      cityVillageErr.EmptyCityVillage = "Fill the City/Village name";
      isValid = false;
    }

    if (oldLiving == undefined) {
      oldLivingErr.EmptyOldLiving = "Fill the Old living";
      isValid = false;
    }

    if (povertyLineCard == undefined) {
      povertyLineCardErr.EmptyPovertyLine = "Select the Poverty Line";
      isValid = false;
    }

    if (fatherHusbandName == "") {
      fatherHusbandNameErr.EmptyFatherHusbandName =
        "Fill the Father/Husband name";
      isValid = false;
    }

    if (gender == "") {
      genderErr.EmptyGender = "Select the gender";
      isValid = false;
    }

    if (dob == "") {
      dobErr.EmptyDob = "Select the DOB";
      isValid = false;
    }

    if (maritalStatus == "") {
      maritalStatusErr.EmptyMaritalStatus = "Select the Marital Status";
      isValid = false;
    }

    if (mobileNo == "") {
      mobileNoErr.EmptyMobileNo = "Fill the mobile no";
      isValid = false;
    }

    if (totalWomenMembers == undefined) {
      totalWomenMemberErr.EmptyTotalWomenMembers = "Select the women members";
      isValid = false;
    }

    if (totalMenMembers == undefined) {
      totalMenMemberErr.EmptyTotalMenMembers = "Select the men members";
      isValid = false;
    }

    if (address == "") {
      addressErr.EmptyAddress = "Fill the address";
      isValid = false;
    }

    if (annualIncome == undefined) {
      annualIncomeErr.EmptyAnnualIncome = "Select the annual income";
      isValid = false;
    }

    if (country == undefined) {
      countryErr.EmptyCountry = "Select the Country";
      isValid = false;
    }

    if (state == undefined) {
      stateErr.EmptyState = "Select the State";
      isValid = false;
    }

    if (city == undefined) {
      cityErr.EmptyCity = "Select the City";
      isValid = false;
    }

    if (pinCode == undefined || pinCode == null) {
      pinCodeErr.EmptyPinCode = "Fill the pin code";
      isValid = false;
    }

    if (education == undefined) {
      educationErr.EmptyEducation = "Select the pin code";
      isValid = false;
    }

    if (otherEducation == undefined || otherEducation == null) {
      otherEducationErr.EmptyOtherEducation = "Fill the education";
      isValid = false;
    }

    if (service == undefined) {
      serviceErr.EmptyService = "Select the service";
      isValid = false;
    }

    if (office == undefined) {
      officeErr.EmptyOffice = "Fill the office";
      isValid = false;
    }

    if (accomodation == undefined) {
      accomodationErr.EmptyAccomodation = "Select the accomodation";
      isValid = false;
    }

    // if (panCard == undefined || panCard == null) {
    //   panCardErr.EmptyPanCard = "Select the Pan Card";
    //   isValid = false;
    // }

    if (bloodGroup == undefined) {
      bloodGroupErr.EmptyBloodGroup = "Select the blood group";
      isValid = false;
    }

    if (coordinatorName == undefined) {
      coordinatorNameErr.EmptyCoordinatorName = "Fill the coordinator name";
      isValid = false;
    }

    if (phoneNo == undefined) {
      phoneNoErr.EmptyPhoneNo = "Fill the phone no";
      isValid = false;
    }

    if (file == "") {
      fileErr.EmptyPhoto = "Upload the photo";
      isValid = false;
    }

    setMemberNameErr(memberNameErr);
    setfatherHusbandNameErr(fatherHusbandNameErr);
    setGenderErr(genderErr);
    setDobErr(dobErr);
    setMaritalStatusErr(maritalStatusErr);
    setMobileNoErr(mobileNoErr);
    setAddressErr(addressErr);
    setCountryErr(countryErr);
    setStateErr(stateErr);
    setCityErr(cityErr);
    setPinCodeErr(pinCodeErr);
    setEducationErr(educationErr);
    setOtherEducationErr(otherEducationErr);
    setServiceErr(serviceErr);
    setOfficeErr(officeErr);
    setaccomodationErr(accomodationErr);
    setPanCardErr(panCardErr);
    setBloodGroupErr(bloodGroupErr);
    setSpouseNameErr(spouseNameErr);
    setmotherNameErr(motherNameErr);
    setMarriageDateErr(marriageDateErr);
    setWhatsappErr(whatsappErr);
    setEmailIdErr(emailIdErr);
    //setTehsilErr(tehsilErr);
    setCityVillageErr(cityVillageErr);
    setOldLivingErr(oldLivingErr);
    setPovertyLineCardErr(povertyLineCardErr);
    setCoordinatorNameErr(coordinatorNameErr);
    setPhoneNoErr(phoneNoErr);
    setAnnualIncomeErr(annualIncomeErr);
    settotalWomenMemberErr(totalWomenMemberErr);
    setTotalMenMemberErr(totalMenMemberErr);
    setFileErr(fileErr);

    return isValid;
  };
  return (
    <div>
      <div className="mt-3" style={{ backgroundColor: "#FFFBCC" }}>
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            पारिवारिक{" "}
            <b style={{ color: "#e47257" }}>
              विवरण
              <p style={{ color: "White" }}>मुख्य पृष्ठ / पारिवारिक विवरण</p>
            </b>
          </h2>
        </div>
        <form>
          <div className="row" style={{ paddingLeft: "10em" }}>
            <div className="col-4">
              <label htmlFor="HeadRelation">
                <b>परिवार मुखिया का नाम</b>
              </label>
              <br />
              <Input
                id="MemberName"
                placeholder="नाम"
                name="memberName"
                className="rounded"
                value={memberName}
                onChange={(event) => setMemberName(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>

            <div className="col-5">
              <label htmlFor="SpouseName">
                <b>पत्नी/पति का नाम</b>
              </label>
              <Input
                id="SpouseName"
                placeholder="पत्नी/पति का नाम"
                name="spouseName"
                className="rounded"
                value={spouseName}
                onChange={(event) => setSpouseName(event.target.value)}
              />
              <br />
              {Object.keys(spouseNameErr).map((key) => {
                return <div style={{ color: "red" }}>{spouseNameErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-4">
              <label htmlFor="FatherHusbandName">
                <b>पिता / पति का नाम</b>
              </label>
              <Input
                id="FatherHusbandName"
                placeholder="पिता / पति का नाम"
                name="fatherHusbandName"
                className="rounded"
                value={fatherHusbandName}
                onChange={(event) => setfatherHusbandName(event.target.value)}
              />
              <br />
              {Object.keys(fatherHusbandNameErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>
                    {fatherHusbandNameErr[key]}
                  </div>
                );
              })}
            </div>
            <div className="col-5">
              <label htmlFor="MotherName">
                <b>माता का नाम</b>
              </label>
              <Input
                id="MotherName"
                placeholder="माता का नाम"
                name="motherName"
                className="rounded"
                value={motherName}
                onChange={(event) => setMotherName(event.target.value)}
              />
              <br />
              {Object.keys(motherNameErr).map((key) => {
                return <div style={{ color: "red" }}>{motherNameErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="Gender">
                <b>लिंग</b>
              </label>
              <br />
              <Select
                showSearch
                id="Gender"
                placeholder="-- Select --"
                name="gender"
                className="rounded"
                value={gender}
                onChange={(event) => setGender(event)}
              >
                <Option value="1">Male</Option>
                <Option value="2">Female</Option>
              </Select>
              <br />
              {Object.keys(genderErr).map((key) => {
                return <div style={{ color: "red" }}>{genderErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="DOB">
                <b>जन्म तिथि</b>
              </label>
              <br />
              <DatePicker
                id="DOB"
                name="DOB"
                format={dateFormatList}
                value={moment(dob, dateFormatList[0])}
                className="rounded"
                onChange={(event) => setDob(event)}
              />
              <br />
              {Object.keys(dobErr).map((key) => {
                return <div style={{ color: "red" }}>{dobErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="MaritalStatus">
                <b>वैवाहिक स्थिति</b>
              </label>
              <br />
              <Select
                showSearch
                id="MaritalStatus"
                placeholder="-- Select --"
                name="maritalStatus"
                className="rounded"
                value={maritalStatus}
                onChange={(event) => setMaritalStatus(event)}
              >
                <Option value="1">Single</Option>
                <Option value="2">Married</Option>
                <Option value="3">Divorced</Option>
                <Option value="4">Widow</Option>
              </Select>
              <br />
              {Object.keys(maritalStatusErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{maritalStatusErr[key]}</div>
                );
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="MarriageDate">
                <b>वैवाहिक वर्षगाठ</b>
              </label>
              <br />
              <DatePicker
                id="MarriageDate"
                name="MarriageDate"
                format={dateFormatList}
                value={moment(marriageDate, dateFormatList[0])}
                className="rounded"
                onChange={(event) => setmarriageDate(event)}
              />
              <br />
              {Object.keys(marriageDateErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{marriageDateErr[key]}</div>
                );
              })}
            </div>
            <div className="col-3">
              <label htmlFor="MobileNo">
                <b>मोबाइल नंबर</b>
              </label>
              <br />
              <Input
                id="MobileNo"
                type="number"
                placeholder="मोबाइल नंबर"
                name="mobileNo"
                className="rounded"
                value={mobileNo}
                onChange={(event) => setMobileNo(event.target.value)}
              />
              <br />
              {Object.keys(mobileNoErr).map((key) => {
                return <div style={{ color: "red" }}>{mobileNoErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="whatsapp">
                <b>व्हाट्सअप नंबर</b>
              </label>
              <Input
                id="whatsapp"
                type="number"
                placeholder="व्हाट्सअप नंबर"
                name="whatsapp"
                className="rounded"
                value={whatsapp}
                onChange={(event) => setWhatsapp(event.target.value)}
              />
              <br />
              {Object.keys(whatsappErr).map((key) => {
                return <div style={{ color: "red" }}>{whatsappErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="emailId">
                <b>ई - मेल आय. डी.</b>
              </label>
              <Input
                id="emailId"
                type="email"
                placeholder="ई - मेल आय. डी."
                name="emaiId"
                className="rounded"
                value={emailId}
                onChange={(event) => setEmailId(event.target.value)}
              />
              <br />
              {Object.keys(emailIdErr).map((key) => {
                return <div style={{ color: "red" }}>{emailIdErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="address">
                <b>पता</b>
              </label>
              <Input
                id="address"
                placeholder="पता"
                name="address"
                className="rounded"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
              <br />
              {Object.keys(addressErr).map((key) => {
                return <div style={{ color: "red" }}>{addressErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="country">
                <b>देश</b>
              </label>
              <br />
              <Select
                id="country"
                showSearch
                name="country"
                className="rounded"
                placeholder="-- देश --"
                optionFilterProp="children"
                value={country}
                onChange={changeCountry}
              >
                {countries.map((e, key) => {
                  return (
                    <option key={key} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </Select>
              <br />
              {Object.keys(countryErr).map((key) => {
                return <div style={{ color: "red" }}>{countryErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="State">
                <b>राज्य</b>
              </label>
              <br />
              <Select
                id="state"
                showSearch
                name="state"
                placeholder="-- राज्य --"
                className="rounded"
                optionFilterProp="children"
                value={state}
                onChange={changeState}
              >
                {states.map((e, key) => {
                  return (
                    <option key={key} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </Select>
              <br />
              {Object.keys(stateErr).map((key) => {
                return <div style={{ color: "red" }}>{stateErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="Region">
                <b>जिला</b>
              </label>
              <br />
              <Select
                id="city"
                showSearch
                name="city"
                className="rounded"
                placeholder="-- जिला --"
                optionFilterProp="children"
                value={city}
                onChange={changeCity}
              >
                {cities.map((e, key) => {
                  return (
                    <option key={key} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </Select>
              <br />
              {Object.keys(cityErr).map((key) => {
                return <div style={{ color: "red" }}>{cityErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="cityVillage">
                <b>शहर / गांव</b>
              </label>
              <Input
                id="cityVillage"
                placeholder="शहर / गांव"
                name="pincode"
                className="rounded"
                value={cityVillage}
                onChange={(event) => setCityVillage(event.target.value)}
              />
              <br />
              {Object.keys(cityVillageErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{cityVillageErr[key]}</div>
                );
              })}
            </div>
            <div className="col-3">
              <label htmlFor="Pincode">
                <b>पिनकोड</b>
              </label>
              <Input
                id="Pincode"
                type="number"
                placeholder="पिनकोड"
                name="pincode"
                className="rounded"
                value={pinCode}
                onChange={(event) => setPinCode(event.target.value)}
              />
              <br />
              {Object.keys(pinCodeErr).map((key) => {
                return <div style={{ color: "red" }}>{pinCodeErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="PhoneNo">
                <b>फोन न.</b>
              </label>
              <Input
                id="PhoneNo"
                type="number"
                placeholder="फोन न."
                name="PhoneNo"
                className="rounded"
                value={phoneNo}
                onChange={(event) => setPhoneNo(event.target.value)}
              />
              <br />
              {Object.keys(phoneNoErr).map((key) => {
                return <div style={{ color: "red" }}>{phoneNoErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="oldLiving">
                <b>पूर्व निवासी</b>
              </label>
              <Input
                id="oldLiving"
                placeholder="पूर्व निवासी"
                name="oldLiving"
                className="rounded"
                value={oldLiving}
                onChange={(event) => setOldLiving(event.target.value)}
              />
              <br />
              {Object.keys(oldLivingErr).map((key) => {
                return <div style={{ color: "red" }}>{oldLivingErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="Education">
                <b>शैक्षणिक पात्रता</b>
              </label>
              <br />
              <Select
                showSearch
                id="Education"
                placeholder="-- Select --"
                name="education"
                className="rounded"
                value={education}
                onChange={(event) => setEducation(event)}
              >
                <option value="Nill">Nill</option>
                <option value="Nursery">Nursery</option>
                <option value="KG1">KG1</option>
                <option value="KG2">KG2</option>
                <option value="1st">1st</option>
                <option value="2nd">2nd</option>
                <option value="3rd">3rd</option>
                <option value="4th">4th</option>
                <option value="5th">5th</option>
                <option value="6th">6th</option>
                <option value="7th">7th</option>
                <option value="8th">8th</option>
                <option value="9th">9th</option>
                <option value="10">10th</option>
                <option value="12">12th</option>
                <option value="graduate Running">Graduate Running</option>
                <option value="graduate">Graduate</option>
                <option value="post_graduate">Post Graduate</option>
                <option value="Post Graduate Running">
                  Post Graduate Running
                </option>
                <option value="phd">Ph.D</option>
                <option value="other">Other</option>
              </Select>
              <br />
              {Object.keys(educationErr).map((key) => {
                return <div style={{ color: "red" }}>{educationErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="OtherEducation">
                <b>अन्य शिक्षा</b>
              </label>
              <Input
                id="OtherEducation"
                placeholder="अन्य शिक्षा"
                name="otherEducation"
                className="rounded"
                value={otherEducation}
                onChange={(event) => setOtherEducation(event.target.value)}
              />
              <br />
              {Object.keys(otherEducationErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{otherEducationErr[key]}</div>
                );
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="Service">
                <b>व्यवसाय / सर्विस</b>
              </label>
              <br />
              <Select
                showSearch
                id="Service"
                placeholder="-- Select --"
                name="service"
                className="rounded"
                value={service}
                onChange={(event) => setService(event)}
              >
                <option value="Business">Business</option>
                <option value="Job">Job</option>
                <option value="Professional">Professional</option>
                <option value="Self Employed">Self Employed</option>
                <option value="Student">Student</option>
                <option value="House Work">House Work</option>
                <option value="Retired Person">Retired Person</option>
                <option value="House Wife">House Wife</option>
                <option value="Government Job">Government Job</option>
              </Select>
              <br />
              {Object.keys(serviceErr).map((key) => {
                return <div style={{ color: "red" }}>{serviceErr[key]}</div>;
              })}
            </div>
            <div className="col-3">
              <label htmlFor="annualIncome">
                <b>पारिवारिक वार्षिक आय</b>
              </label>
              <br />
              <Select
                showSearch
                id="annualIncome"
                placeholder="-- Select --"
                name="annualIncome"
                className="rounded"
                value={annualIncome}
                onChange={(event) => setAnnualIncome(event)}
              >
                <option value="Nill">Nill</option>
                <option value="Less_than_50_Thousands" selected="selected">
                  Less than 50 Thousands
                </option>
                <option value="INR_50_Thousands_to_1_Lakh">
                  INR 50 Thousands to 1 Lakh
                </option>
                <option value="INR_50_Thousands_to_3_Lakh">
                  INR 50 Thousands to 3 Lakh
                </option>
                <option value="INR_1_Lakh_to_3_Lakh">
                  INR 1 Lakh to 3 Lakh
                </option>
                <option value="INR_3_Lakh_to_10_Lakh">
                  INR 3 Lakh to 10 Lakh
                </option>
                <option value="INR_3_Lakh_to_5_Lakh">
                  INR 3 Lakh to 5 Lakh
                </option>
                <option value="INR_5_Lakh_to_6_Lakh">
                  INR 5 Lakh to 6 Lakh
                </option>
                <option value="INR_6_Lakh_to_8_Lakh">
                  INR 6 Lakh to 8 Lakh
                </option>
                <option value="INR_8_Lakh_to_10_Lakh">
                  INR 8 Lakh to 10 Lakh
                </option>
                <option value="INR_10_Lakh_to_15_Lakh">
                  INR 10 Lakh to 15 Lakh
                </option>
                <option value="More_than_10_Lakh">More than 10 Lakh</option>
                <option value="More_then_15_Lakh">More than 15 Lakh</option>
                <option value="No_Income">No Income</option>
              </Select>
              <br />
              {Object.keys(annualIncomeErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{annualIncomeErr[key]}</div>
                );
              })}
            </div>
            <div className="col-3">
              <label htmlFor="Office">
                <b>कार्यालय / प्रतिष्ठान का नाम & पता</b>
              </label>
              <Input
                id="Office"
                placeholder="कार्यालय / प्रतिष्ठान का नाम & पता"
                name="office"
                className="rounded"
                value={office}
                onChange={(event) => setOffice(event.target.value)}
              />
              <br />
              {Object.keys(officeErr).map((key) => {
                return <div style={{ color: "red" }}>{officeErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="Accomodation">
                <b>आवास</b>
              </label>
              <br />
              <Select
                showSearch
                id="Accomodation"
                placeholder="-- Select --"
                name="accomodation"
                className="rounded"
                value={accomodation}
                onChange={(event) => setaccomodation(event)}
              >
                <Option value="0">Rent</Option>
                <Option value="1">House</Option>
              </Select>
              <br />
              {Object.keys(accomodationErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{accomodationErr[key]}</div>
                );
              })}
            </div>
            <div className="col-3">
              <label htmlFor="PovertyLineCard">
                <b>गरीबी रेखा का कार्ड</b>
              </label>
              <br />
              <Select
                showSearch
                id="PovertyLineCard"
                placeholder="-- Select --"
                name="PovertyLineCard"
                className="rounded"
                value={povertyLineCard}
                onChange={(event) => setPovertyLineCard(event)}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
              <br />
              {Object.keys(povertyLineCardErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{povertyLineCardErr[key]}</div>
                );
              })}
            </div>
            <div className="col-3">
              <label htmlFor="PanCard">
                <b>पेन कार्ड है</b>
              </label>
              <br />
              <Select
                showSearch
                id="PanCard"
                placeholder="-- Select --"
                name="PanCard"
                className="rounded"
                value={panCard}
                onChange={(event) => setPanCard(event)}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
              <br />
              {Object.keys(panCardErr).map((key) => {
                return <div style={{ color: "red" }}>{panCardErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="totalWomenMembers">
                <b>कुल महिला सदस्य</b>
              </label>
              <br />
              <Select
                showSearch
                id="totalWomenMembers"
                placeholder="-- Select --"
                name="totalWomenMembers"
                className="rounded"
                value={totalWomenMembers}
                onChange={(event) => setTotalWomenMembers(event)}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
              </Select>
              <br />
              {Object.keys(totalWomenMemberErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{totalWomenMemberErr[key]}</div>
                );
              })}
            </div>
            <div className="col-3">
              <label htmlFor="totalMenMembers">
                <b>कुल पुरुष सदस्य</b>
              </label>
              <br />
              <Select
                showSearch
                id="totalMenMembers"
                placeholder="-- Select --"
                name="totalMenMembers"
                className="rounded"
                value={totalMenMembers}
                onChange={(event) => setTotalMenMembers(event)}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
              </Select>
              <br />
              {Object.keys(totalMenMemberErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{totalMenMemberErr[key]}</div>
                );
              })}
            </div>
            <div className="col-3">
              <label htmlFor="BloodGroup">
                <b>रक्त समूह</b>
              </label>
              <br />
              <Select
                showSearch
                id="BloodGroup"
                placeholder="-- Select --"
                name="bloodGroup"
                className="rounded"
                value={bloodGroup}
                onChange={(event) => setBloodGroup(event)}
              >
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
              </Select>
              <br />
              {Object.keys(bloodGroupErr).map((key) => {
                return <div style={{ color: "red" }}>{bloodGroupErr[key]}</div>;
              })}
            </div>
          </div>
          <div className="row mt-3 mb-5" style={{ paddingLeft: "10em" }}>
            <div className="col-3">
              <label htmlFor="coordinatorName">
                <b>Coordinator Name</b>
              </label>
              <Input
                id="coordinatorName"
                placeholder="Coordinator Name"
                name="coordinatorName"
                className="rounded"
                value={coordinatorName}
                onChange={(event) => setCoordinatorName(event.target.value)}
              />
              <br />
              {Object.keys(coordinatorNameErr).map((key) => {
                return (
                  <div style={{ color: "red" }}>{coordinatorNameErr[key]}</div>
                );
              })}
            </div>
          </div>
          <div className="row mt-3" style={{ paddingLeft: "10em" }}>
            <div className="col-6">
              <input type="file" onChange={handleUpload} />
              <br />
              <span>(*) Please Upload Passport Size Photo..</span>
              <br />
              {Object.keys(fileErr).map((key) => {
                return <div style={{ color: "red" }}>{fileErr[key]}</div>;
              })}
              {/* <p>Filename: {file.name}</p>
              <p>File type: {file.type}</p>
              <p>File size: {file.size} bytes</p> */}
              <br />
              {isImageUploaded ? (
                file && <ImageThumb image={file} />
              ) : imagePath == "" ? (
                file && <ImageThumb image={file} />
              ) : (
                <img style={{ height: "100px" }} src={imagePath + image}></img>
              )}
            </div>
            <div className="col-6">
              <Button
                type="primary"
                size="large"
                onClick={handleSubmit}
                className="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMember;

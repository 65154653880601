import React, { Component, useState, useEffect } from "react";
import render from "react-dom";
import { Button, DatePicker, Input, Select } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import '../../App.css';

const { TextArea } = Input;

const headingStyle = {
  color: "white",
  backgroundColor: "#4D4D4D",
  paddingTop: "6em",
  paddingBottom: "1em",
};

const hr = {
  marginTop: "20px",
  marginBottom: "20px",
  border: "0",
  borderTop: "1px solid #EEEEEE",
};

const Chetna = () => {
  let countriesList;
  let statesList;
  let citiesList;
  let token;
  const location = useLocation();
  const { userIdToBeEdited } =
    location.state != undefined ? location.state : "";
  const [selectedCountry, setSelectedCountry] = useState([]);
  const countries = selectedCountry.map((country) => country);
  const fileList = [];
  const [selectedState, setSelectedState] = useState([]);
  const states = selectedState.map((state) => state);

  const [selectedCity, setSelectedCity] = useState([]);
  const cities = selectedCity.map((city) => city);

  const [memberName, setMemberName] = useState("");
  const [memberNameErr, setMemberNameErr] = useState({});
  const [headRelation, setHeadRelation] = useState("");
  const [headRelationErr, setHeadRelationErr] = useState({});
  const [fatherHusbandName, setfatherHusbandName] = useState("");
  const [fatherHusbandNameErr, setfatherHusbandNameErr] = useState({});
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState({});
  const [dob, setDob] = useState("");
  const [dobErr, setDobErr] = useState({});
  const [maritalStatus, setMaritalStatus] = useState("");
  const [maritalStatusErr, setMaritalStatusErr] = useState({});
  const [mobileNo, setMobileNo] = useState("");
  const [mobileNoErr, setMobileNoErr] = useState({});
  const [address, setAddress] = useState("");
  const [addressErr, setAddressErr] = useState({});
  const [country, setCountry] = useState();
  const [countryErr, setCountryErr] = useState({});
  const [state, setState] = useState();
  const [stateErr, setStateErr] = useState({});
  const [city, setCity] = useState();
  const [cityErr, setCityErr] = useState({});
  const [pinCode, setPinCode] = useState();
  const [pinCodeErr, setPinCodeErr] = useState({});
  const [education, setEducation] = useState();
  const [educationErr, setEducationErr] = useState({});
  const [otherEducation, setOtherEducation] = useState();
  const [otherEducationErr, setOtherEducationErr] = useState({});
  const [service, setService] = useState();
  const [serviceErr, setServiceErr] = useState({});
  const [office, setOffice] = useState();
  const [officeErr, setOfficeErr] = useState({});
  const [accomodation, setaccomodation] = useState();
  const [accomodationErr, setaccomodationErr] = useState({});
  //const [panCard, setPanCard] = useState();
  const [panCardErr, setPanCardErr] = useState({});
  const [bloodGroup, setBloodGroup] = useState();
  const [bloodGroupErr, setBloodGroupErr] = useState({});
  const [photo, setPhoto] = useState();
  const [file, setFile] = React.useState("");
  const [fileErr, setFileErr] = useState({});
  const dateFormatList = ["YYYY/MM/DD"];

  const handleUpload = (event) => {
    setFile(event.target.files[0]);
  };
  /**
   * Component to display thumbnail of image.
   */
  const ImageThumb = ({ image }) => {
    debugger;
    return (
      <img
        style={{ height: "100px" }}
        src={URL.createObjectURL(image)}
        alt={image.name}
      />
    );
  };

  const getMemberDetails = async (e) => {
    try {
      const postData = {
        id: userIdToBeEdited,
        user_id: localStorage.getItem("loggedUserId"),
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("loggedToken")}`,
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/viewmember",
        body,
        config
      );
      if (res.data.success == 1) {
        setOtherEducation(res.data.data.othereducation);
        setHeadRelation(res.data.data.relation);
        setMemberName(res.data.data.fullname);
        setfatherHusbandName(res.data.data.father_name);
        setGender(res.data.data.gender);
        setDob(res.data.data.dob);
        setMaritalStatus(res.data.data.marital_status);
        setMobileNo(res.data.data.mobile);
        setAddress(res.data.data.full_address);
        let country = countriesList.find(
          (x) => x.id == res.data.data.country_id
        );
        if (country != undefined) {
          setCountry(country.name);
          await changeCountry();
        }

        let state = statesList.find((x) => x.id == res.data.data.state_id);
        if (state != undefined) {
          setState(state.name);
          await changeState(res.data.data.state_id);
        }

        if (res.data.data.district_id != null) {
          if (citiesList != undefined) {
            let district = citiesList.find(
              (x) => x.id == res.data.data.district_id
            );
            setCity(district.name);
          }
        }
        setPinCode(res.data.data.zip_code);
        setEducation(res.data.data.education);
        setService(res.data.data.occupation);
        setOffice(res.data.data.office);
        setaccomodation(res.data.data.awash);
        //setPanCard(res.data.data.)
        setBloodGroup(res.data.data.blood_group);

        /* TODO */
        if (res.data.data.profile_image != null) {
          //file.name = res.data.data.profile_image;
          // setFile(
          //   res.data.data.profile_image
          // );
        }
      } else {
        alert("Error while fetching member details");
        return false;
      }
    } catch (err) {
      if(err.response.status == 401){
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };

  const getCountries = async () => {
    await axios
      .post("https://api.golapurvajainsamaj.com/api/v1/staticlists/getcountry")
      .then((response) => {
        setSelectedCountry(response.data.data);
        countriesList = response.data.data;
      });
  };

  const getStates = async () => {
    await axios
      .post("https://api.golapurvajainsamaj.com/api/v1/staticlists/getstate")
      .then((response) => {
        setSelectedState(response.data.data);
        statesList = response.data.data;
      });
  };

  const getCities = async (e) => {
    await axios
      .post(
        "https://api.golapurvajainsamaj.com/api/v1/staticlists/getcity?state_id=" +
          e
      )
      .then((response) => {
        setSelectedCity(response.data.data);
        citiesList = response.data.data;
      });
  };

  useEffect(async () => {
    await getCountries();
    await getStates();
    if (userIdToBeEdited != undefined) await getMemberDetails();
  }, [setSelectedCountry]);

  const changeCountry = async (event) => {
    setCountry(event);
    await getStates();
  };

  const changeState = async (event) => {
    setState(event);
    await getCities(event);
  };

  const changeCity = (event) => {
    setCity(event);
  };

  const saveMemberDetails = async (e) => {
    try {
      const postData = {
        id: userIdToBeEdited,
        user_id: localStorage.getItem("loggedUserId"),
        relation: headRelation,
        fullname: memberName,
        wife_father: fatherHusbandName,
        gender: gender,
        dob: dob,
        marital_status: maritalStatus,
        mobile: mobileNo,
        full_address: address,
        country_id: country,
        state_id: state,
        district_id: city,
        zip_code: pinCode,
        education: education,
        othereducation: otherEducation,
        occupation: service,
        office: office,
        blood_group: bloodGroup,
        profile_image: photo,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            token == undefined ? localStorage.getItem("loggedToken") : token
          }`,
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/addeditmember",
        body,
        config
      );
      if (res.data.success == 1) {
        alert(res.data.message);
      } else {
        alert("Error while updating the entries");
        return false;
      }
    } catch (err) {
      if(err.response.status == 401){
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      // after api is called
      saveMemberDetails();
    }
  };

  const formValidation = () => {
    const memberNameErr = {};
    const headRelationErr = {};
    const fatherHusbandErr = {};
    const genderErr = {};
    const dobErr = {};
    const maritalStatusErr = {};
    const mobileNoErr = {};
    const addressErr = {};
    const countryErr = {};
    const stateErr = {};
    const cityErr = {};
    const pinCodeErr = {};
    const educationErr = {};
    const otherEducation = {};
    const serviceErr = {};
    const officeErr = {};
    const accomodationErr = {};
    const panCardErr = {};
    const bloodGroupErr = {};
    const fileErr = {};
    let isValid = true;

    if (memberName == null) {
      memberNameErr.EmptyMemberName = "Fill the member name";
      isValid = false;
    }

    if (headRelation == undefined) {
      headRelationErr.EmptyHeadRelation = "Select the relation with head";
      isValid = false;
    }

    if (fatherHusbandName == null) {
      fatherHusbandErr.EmptyFatherHusbandName =
        "Fill the father or husband name";
      isValid = false;
    }

    if (gender == undefined) {
      genderErr.EmptyGender = "Select the gender";
      isValid = false;
    }

    if (dob == "") {
      dobErr.EmptyDob = "Select the DOB";
      isValid = false;
    }

    if (maritalStatus == undefined) {
      maritalStatusErr.EmptyMaritalStatus = "Select the Marital Status";
      isValid = false;
    }

    if (mobileNo == null) {
      mobileNoErr.EmptyMobileNo = "Fill the mobile no";
      isValid = false;
    }

    if (address == null) {
      addressErr.EmptyAddress = "Fill the address";
      isValid = false;
    }

    if (country == undefined) {
      countryErr.EmptyCountry = "Select the Country";
      isValid = false;
    }

    if (state == undefined) {
      stateErr.EmptyState = "Select the State";
      isValid = false;
    }

    if (city == undefined) {
      cityErr.EmptyCity = "Select the City";
      isValid = false;
    }

    if (pinCode == undefined || pinCode == null) {
      pinCodeErr.EmptyCity = "Fill the pin code";
      isValid = false;
    }

    if (education == undefined) {
      educationErr.EmptyEducation = "Select the pin code";
      isValid = false;
    }

    if (otherEducation == undefined || otherEducation == null) {
      otherEducationErr.EmptyOtherEducation = "Fill the education";
      isValid = false;
    }

    if (service == undefined) {
      serviceErr.EmptyService = "Select the service";
      isValid = false;
    }

    if (office == undefined) {
      officeErr.EmptyOffice = "Fill the office";
      isValid = false;
    }

    if (accomodation == undefined) {
      accomodationErr.EmptyOffice = "Select the accomodation";
      isValid = false;
    }

    // if (panCard == undefined || panCard == null) {
    //   panCardErr.EmptyPanCard = "Fill the Pac Card";
    //   isValid = false;
    // }

    if (bloodGroup == undefined) {
      bloodGroupErr.EmptyBloodGroup = "Select the blood group";
      isValid = false;
    }

    if (file == "") {
      fileErr.EmptyPhoto = "Upload the photo";
      isValid = false;
    }

    setMemberNameErr(memberNameErr);
    setHeadRelationErr(headRelationErr);
    setfatherHusbandNameErr(fatherHusbandErr);
    setGenderErr(genderErr);
    setDobErr(dobErr);
    setMaritalStatusErr(maritalStatusErr);
    setMobileNoErr(mobileNoErr);
    setAddressErr(addressErr);
    setCountryErr(countryErr);
    setStateErr(stateErr);
    setCityErr(cityErr);
    setPinCodeErr(pinCodeErr);
    setEducationErr(educationErr);
    setOtherEducationErr(otherEducationErr);
    setServiceErr(serviceErr);
    setOfficeErr(officeErr);
    setaccomodationErr(accomodationErr);
    setPanCardErr(panCardErr);
    setBloodGroupErr(bloodGroupErr);
    setFileErr(fileErr);
    return isValid;
  };
  return (
    <div>
      <div className="mt-3" style={{ backgroundColor: "#FFFBCC" }}>
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            चेतना{" "}
            <b style={{ color: "#e47257" }}>
              सम्मान
              <p style={{ color: "White" }}>मुख्य पृष्ठ / चेतना सम्मान</p>
            </b>
          </h2>
        </div>
        <div className="mt-5">
          <div
            className="col-md-12 text-center"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <h3 style={{ fontFamily: "montseerat", color: "#303030" }}>
              चेतना सम्मान समारोह - 2020
            </h3>
            <hr style={hr} />
            <h3 style={{ fontFamily: "montseerat", color: "#303030" }}>
              फ़ॉर्म भरने की अंतिम तिथि - 10 सितंबर 2020
            </h3>
            <hr style={hr} />
            <h3 style={{ fontFamily: "montseerat", color: "#303030" }}>
              न्यूनतम मापदंड
            </h3>
            <hr style={hr} />
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                10वीं बोर्ड परीक्षा - 90% या अधिक अंक प्राप्त करने पर।
              </li>
              <li class="list-group-item">
                12वीं बोर्ड परीक्षा-गणित/वाणिज्य समूह-85%, जीव विज्ञान/कृषि
                विज्ञान समूह-80%, एवं कला समूह-75% या अधिक अंक प्राप्त करने पर।
              </li>
              <li class="list-group-item">
                10वीं एवं 12वीं के प्रथम तीन को ₹1100 एवं प्रमाण पत्र से
                सम्मानित किया जाएगा इस हेतु चयनित होने पर ही पुरस्कृत किया
                जाएगा।
              </li>
            </ul>
            <marquee>
              <p>
                आवेदन करने वाले समस्त प्रतिभाशाली छात्र- छात्राओ से विशेष आग्रह
                है की वे अपने परिवार, रिश्तेदारों एवं समाज के ज्यादा से ज्यादा
                परिवारों के लिए वेबसाईट/एप के माध्यम से समाज की ऑनलाइन जनगणना के
                फ़ॉर्म भरने मे अपना प्रत्यक्ष एवं परोक्ष रूप से सहयोग जरूर करे
                !!!
              </p>
            </marquee>
          </div>
        </div>
        <form>
          <div
            className="row"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="Name">
                <b>नाम</b>
              </label>
              <Input
                id="Name"
                placeholder="नाम"
                name="memberName"
                className="rounded"
                value={memberName}
                onChange={(event) => setMemberName(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="DOB">
                <b>जन्म तिथि</b>
              </label>
              <br />
              <DatePicker
                id="DOB"
                style={{ width: "100%" }}
                name="DOB"
                format={dateFormatList}
                value={dob}
                className="rounded"
                onChange={(event) => setDob(event)}
              />
              <br />
              {Object.keys(dobErr).map((key) => {
                return <div style={{ color: "red" }}>{dobErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="Gender">
                <b>लिंग / Gender</b>
              </label>
              <br />
              <Select
                showSearch
                id="Gender"
                placeholder="-- Select --"
                name="gender"
                className="rounded"
                value={gender}
                onChange={(event) => setGender(event)}
                style={{ width: 513 }}
              >
                <Option value="1">Male</Option>
                <Option value="2">Female</Option>
              </Select>
              <br />
              {Object.keys(genderErr).map((key) => {
                return <div style={{ color: "red" }}>{genderErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="FatherName">
                <b>पिता का नाम / Father's Name</b>
              </label>
              <Input
                id="FatherName"
                name="memberName"
                className="rounded"
                value={memberName}
                onChange={(event) => setMemberName(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="FatherName">
                <b>माता का नाम / Mother's Name</b>
              </label>
              <Input
                id="MotherName"
                name="motherName"
                className="rounded"
                value={memberName}
                onChange={(event) => setMemberName(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="address">
                <b>पता / Residential Address</b>
              </label>
              <Input
                id="address"
                placeholder="पता"
                name="address"
                className="rounded"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
              <br />
              {Object.keys(addressErr).map((key) => {
                return <div style={{ color: "red" }}>{addressErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="City">
                <b>शहर / City</b>
              </label>
              <Input
                id="City"
                name="city"
                className="rounded"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="district">
                <b>जिला / District</b>
              </label>
              <Input
                id="district"
                placeholder="पता"
                name="district"
                className="rounded"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <br />
              {Object.keys(addressErr).map((key) => {
                return <div style={{ color: "red" }}>{addressErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="State">
                <b>प्रदेश / State</b>
              </label>
              <Input
                id="State"
                name="state"
                className="rounded"
                value={state}
                onChange={(event) => setState(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="emailId">
                <b>ई-मेल आइडी / E-Mail ID</b>
              </label>
              <Input
                id="emailId"
                name="emailId"
                className="rounded"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <br />
              {Object.keys(addressErr).map((key) => {
                return <div style={{ color: "red" }}>{addressErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="MobileNo">
                <b>मोबाईल नंबर / Mobile Number</b>
              </label>
              <Input
                id="MobileNo"
                name="MobileNo"
                className="rounded"
                value={mobileNo}
                onChange={(event) => setMobileNo(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="OtherQualifications">
                <b>अन्य उपलब्धि / Other Qualifications</b>
              </label>
              <Input
                id="OtherQualifications"
                name="OtherQualifications"
                className="rounded"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <br />
              {Object.keys(addressErr).map((key) => {
                return <div style={{ color: "red" }}>{addressErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3 mb-5"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="OtherQualifications">
                <b>Photo</b>
              </label>
              <br />
              <input value={file} type="file" onChange={handleUpload} />
              <br />
              <span>(*) Please Upload Passport Size Photo..</span>
              <br />
              {Object.keys(fileErr).map((key) => {
                return <div style={{ color: "red" }}>{fileErr[key]}</div>;
              })}
              {/* <p>Filename: {file.name}</p>
              <p>File type: {file.type}</p>
              <p>File size: {file.size} bytes</p> */}
              <br />
              {file && <ImageThumb image={file} />}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{
              paddingLeft: "13em",
              paddingRight: "10em",
              textAlign: "center",
            }}
          >
            <p
              style={{
                fontSize: "35px",
                fontFamily: "arimo",
                color: "#505050",
                lineHeight: "28px",
                letterSpacing: "0.3",
              }}
            >
              10वीं / 12वीं बोर्ड
            </p>
            <p
              style={{
                fontSize: "35px",
                fontFamily: "arimo",
                color: "#505050",
                lineHeight: "28px",
                letterSpacing: "0.3",
                fontSize: "15px",
              }}
            >
              मा.शि.म. एवं सी.बी.एस.ई
            </p>
          </div>
          <div
            className="row"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="NameOfExam">
                <b>परीक्षा का नाम / Name Of Exam</b>
              </label>
              <Input
                id="NameOfExam"
                placeholder="नाम"
                name="NameOfExam"
                className="rounded"
                value={memberName}
                onChange={(event) => setMemberName(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="NameOfExamBoard">
                <b>परीक्षा बोर्ड का नाम / Name Of Exam Board</b>
              </label>
              <br />
              <Input
                id="NameOfExamBoard"
                name="NameOfExamBoard"
                className="rounded"
                value={memberName}
                onChange={(event) => setMemberName(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="MaximumMarks">
                <b>पूर्णांक / Maximum Marks</b>
              </label>
              <br />
              <Input
                id="MaximumMarks"
                name="MaximumMarks"
                className="rounded"
                value={memberName}
                onChange={(event) => setMemberName(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="GettingMarks">
                <b>प्राप्तांक / Getting Marks</b>
              </label>
              <Input
                id="GettingMarks"
                name="GettingMarks"
                className="rounded"
                value={memberName}
                onChange={(event) => setMemberName(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="Percentage">
                <b>प्रतिशत / Percentage</b>
              </label>
              <Input
                id="Percentage"
                name="Percentage"
                className="rounded"
                value={memberName}
                onChange={(event) => setMemberName(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="position">
                <b>प्रावीण्य सूची मे स्थान देश मे</b>
              </label>
              <Input
                id="position"
                name="position"
                className="rounded"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
              <br />
              {Object.keys(addressErr).map((key) => {
                return <div style={{ color: "red" }}>{addressErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="positionInState">
                <b>प्रावीण्य सूची मे स्थान प्रदेश मे</b>
              </label>
              <Input
                id="positionInState"
                name="positionInState"
                className="rounded"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="positionInSambhaag">
                <b>प्रावीण्य सूची मे स्थान संभाग मे</b>
              </label>
              <Input
                id="positionInSambhaag"
                name="positionInSambhaag"
                className="rounded"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <br />
              {Object.keys(addressErr).map((key) => {
                return <div style={{ color: "red" }}>{addressErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="positionInDistrict">
                <b>प्रावीण्य सूची मे स्थान जिला मे</b>
              </label>
              <Input
                id="positionInDistrict"
                name="positionInDistrict"
                className="rounded"
                value={state}
                onChange={(event) => setState(event.target.value)}
              />
              <br />
              {Object.keys(memberNameErr).map((key) => {
                return <div style={{ color: "red" }}>{memberNameErr[key]}</div>;
              })}
            </div>
            <div className="col-6">
              <label htmlFor="previousAcheivements">
                <b>यदि पूर्व मे चेतना पुरस्कार प्राप्त किया हो तो उल्लेख करे</b>
              </label>
              <Input
                id="previousAcheivements"
                name="previousAcheivements"
                className="rounded"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <br />
              {Object.keys(addressErr).map((key) => {
                return <div style={{ color: "red" }}>{addressErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-6">
              <label htmlFor="OtherQualifications">
                <b>Photo</b>
              </label>
              <br />
              <input value={file} type="file" onChange={handleUpload} />
              <br />
              <span>(*) Please Upload Passport Size Photo..</span>
              <br />
              {Object.keys(fileErr).map((key) => {
                return <div style={{ color: "red" }}>{fileErr[key]}</div>;
              })}
              {/* <p>Filename: {file.name}</p>
              <p>File type: {file.type}</p>
              <p>File size: {file.size} bytes</p> */}
              <br />
              {file && <ImageThumb image={file} />}
            </div>
          </div>
          <div
            className="row"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-12">
              <label htmlFor="Comments">
                <b>गोलापूर्व समाज के विकास के लिए सुझाव</b>
              </label>
              <TextArea
                rows={7}
                id="Comments"
                name="Comments"
                className="rounded"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <br />
              {Object.keys(addressErr).map((key) => {
                return <div style={{ color: "red" }}>{addressErr[key]}</div>;
              })}
            </div>
          </div>
          <div
            className="row mt-2"
            style={{ paddingLeft: "14em", paddingRight: "10em" }}
          >
            <div className="col-12 form-check">
              <input type="checkbox" class="form-check-input" id="terms" />
              <label
                style={{
                  fontSize: "15px",
                  fontFamily: "arimo",
                  color: "#505050",
                }}
                class="form-check-label"
                for="terms"
              >
                मैं यह घोषणा करता / करती हूँ की मेरे द्वारा दी गई जानकारी
                पूर्णतः सत्य है। किसी भी प्रकार के असत्यता पाये जाने पर अखिल
                भारतवर्षीय दिगंबर जैन गोलापूर्व महासभा के द्वारा निर्धारित दंड
                का पात्र रहूँगा / रहूँगी।
              </label>
            </div>
          </div>
          <div
            className="col-12 mt-3"
            style={{ paddingLeft: "14em", paddingRight: "10em" }}
          >
            <Button type="primary" size="large" className="submit">
              Submit
            </Button>
          </div>
          <div
            className="row mt-2"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-12 text-center">
              <a href="#">
                <h3 style={{ fontFamily: "montseerat" }}>
                  फॉर्म डाउनलोड करने के लिए यहा क्लिक करे
                </h3>
              </a>
            </div>
          </div>
          <div
            className="row mt-2"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-12 text-center">
              <h3 style={{ fontFamily: "montseerat" }}>आवश्यक सूचना</h3>
            </div>
            <hr style={hr} />
            <ul class="list-group list-group-flush text-center">
              <li class="list-group-item">
                कोविड-19 के कारण कार्यक्रम आयोजित न होने की स्थिति में सम्मान
                पत्र आपके घर पहुँचाया जाएगा।
              </li>
              <li class="list-group-item">
                आवेदन करने वाले समस्त छात्र-छात्राओं से अनुरोध है की वे अपने
                माता पिता से जनगणना संबंधी/संबंधित जानकारी वेबसाईट में अनिवार्य
                रूप से भरवाएं।
              </li>
            </ul>
          </div>
          <div
            className="row mt-4"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div className="col-12 text-center">
              <h3 style={{ fontFamily: "montseerat" }}>संपर्क करें</h3>
            </div>
          </div>
          <div
            className="row mt-4"
            style={{ paddingLeft: "13em", paddingRight: "10em" }}
          >
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <p>श्री विनोद जी जैन,कोतमा</p>
                  <p>(संयोजक)</p>
                  <p>फोन नंबर: 9329404333</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <p>डॉ. अरविन्द जैन, सागर</p>
                  <p>(सह-संयोजक)</p>
                  <p>फोन नंबर: 9406532221</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-center">
              <h3 style={{ fontFamily: "montseerat",paddingTop:'1em' }}>तकनीकी सहयोग के लिए संपर्क करें</h3>
            </div>
          <div
            className="row mt-4"
            style={{ paddingLeft: "13em", paddingRight: "10em",paddingBottom:"2em" }}
          >
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <p>श्री राजेश जैन</p>
                  <p>(संयोजक)</p>
                  <p>फोन नंबर: 9303270923</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <p>श्री विवेक जैन</p>
                  <p>(सह-संयोजक)</p>
                  <p>फोन नंबर: 9754340315</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <p>श्री दीपेश जैन</p>
                  <p>(सह-संयोजक)</p>
                  <p>फोन नंबर: 9993818201</p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Chetna;

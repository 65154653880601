import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import axios from "axios";

const FirstTimeLogin = (props) => {
  const history = useHistory();
  let userId = "";
  let token = "";
  
  const AddMemberHandler = async (e) => {
    try {
      if (props.UserId != undefined) {
        userId = props.UserId;
        token = props.Token;
      } else {
        userId = props.lastInsertedUser;
      }

      const postData = {
        user_id: userId,
        is_head: 1,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/updatehead",
        body,
        config
      );
      if (res.data.success == "1") {
        history.push({ pathname: "/member_profile", user_id: userId });
      } else {
        alert(
          "Ann error has occurred while saving the family head information!"
        );
        return false;
      }
    } catch (err) {
      if(err.response.status == 401){
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };
  return (
    <Modal show={true} className="modal_overlay">
      <ModalTitle>{props.title}</ModalTitle>
      <ModalBody>
        <button className="btn btn-primary" onClick={AddMemberHandler}>
          Yes
        </button>{" "}
        <button className="btn btn-primary" onClick={props.onVerifyMember}>
          No
        </button>
      </ModalBody>
      <ModalFooter>
        <button onClick={props.onShowpopupHandler}>Close</button>
      </ModalFooter>
    </Modal>
  );
};

export default FirstTimeLogin;

import React from "react";
import { Switch, Redirect, Route, Link } from "react-router-dom";

import { RouteWithLayout } from "./components";
//import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
//import { Main as MainLayout} from './layouts';
import Mainlayout from "./layouts/Frontend/Mainlayout";

import {
  LandingPage as LandingPageView,
  Home as HomeView,
  SignIn as SignInView,
  SignUp as SignUpView,
  ForGotPass as ForGotPassView,
  AddMember as AddMemberView,
  FamilyMembers as FamilyMembersView,
  Team as TeamView,
  ProfileSearch as ProfileView,
  Chetna as ChetnaView,
  ChangePassword as ChangePasswordView,
  Janganna as JangannaView,
  Mahasabha as MahasabhaView,
  AddMahasabha as AddMahasabhaView,
  AboutUs as AboutUsView,
  Objective as ObjectiveView,
  ShubhkaamnaMessage as ShubhkaamnaMessageView,
  PhotoGallery as PhotoGalleryView,
  CopyMember as CopyMemberView,
  ManageMarriage as ManageMarriageView,
  AddMarriage as AddMarriageView
} from "./views";

const Routes = () => {
  return (
    <Switch>
      {/* <Redirect exact from="/" to="/sign-in" /> */}
      {/* <Route path="/" exact component={HomeView} /> */}
      {/* <Route path="/sign-in" exact component={SignInView} /> */}
      <RouteWithLayout
        component={LandingPageView}
        exact
        layout={Mainlayout}
        path="/"
      />

      <RouteWithLayout
        component={HomeView}
        exact
        layout={Mainlayout}
        path="/home"
      />
      <RouteWithLayout
        component={AddMemberView}
        exact
        layout={Mainlayout}
        path="/member_profile"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={Mainlayout}
        path="/login"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={Mainlayout}
        path="/register"
      />
      <RouteWithLayout
        component={ForGotPassView}
        exact
        layout={Mainlayout}
        path="/forgotpass"
      />

      <RouteWithLayout
        component={FamilyMembersView}
        exact
        layout={Mainlayout}
        path="/family_member"
      />

<RouteWithLayout
        component={TeamView}
        exact
        layout={Mainlayout}
        path="/team"
      />

<RouteWithLayout
        component={ProfileView}
        exact
        layout={Mainlayout}
        path="/matrimonyProfile"
      />

<RouteWithLayout
        component={ChetnaView}
        exact
        layout={Mainlayout}
        path="/chetna"
      />

<RouteWithLayout
        component={ChangePasswordView}
        exact
        layout={Mainlayout}
        path="/changePassword"
      />

<RouteWithLayout
        component={JangannaView}
        exact
        layout={Mainlayout}
        path="/janganna"
      />

<RouteWithLayout
        component={MahasabhaView}
        exact
        layout={Mainlayout}
        path="/Mahasabha"
      />

<RouteWithLayout
        component={AddMahasabhaView}
        exact
        layout={Mainlayout}
        path="/AddMahasabha"
      />

<RouteWithLayout
        component={AboutUsView}
        exact
        layout={Mainlayout}
        path="/AboutUs"
      />

<RouteWithLayout
        component={ObjectiveView}
        exact
        layout={Mainlayout}
        path="/Objective"
      />

<RouteWithLayout
        component={ShubhkaamnaMessageView}
        exact
        layout={Mainlayout}
        path="/ShubhkaamnaMessage"
      />

<RouteWithLayout
        component={PhotoGalleryView}
        exact
        layout={Mainlayout}
        path="/PhotoGallery"
      />

<RouteWithLayout
        component={CopyMemberView}
        exact
        layout={Mainlayout}
        path="/CopyMember"
      />

<RouteWithLayout
        component={ManageMarriageView}
        exact
        layout={Mainlayout}
        path="/ManageMarriage"
      />

<RouteWithLayout
        component={AddMarriageView}
        exact
        layout={Mainlayout}
        path="/AddMarriage"
      />

      {/*<RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />

      <RouteWithLayout
        component={PromoOfferView}
        exact
        layout={MainLayout}
        path="/promocode/:PromoId"
      />
      <RouteWithLayout
        component={AddPromoView}
        exact
        layout={MainLayout}
        path="/promocode/:PromoId/add-promocode"
      />
      <RouteWithLayout
        component={AddPromoView}
        exact
        layout={MainLayout}
        path="/promocode/:PromoId/:PromoCodeId"
      />

      <RouteWithLayout
        component={CompanyListView}
        exact
        layout={MainLayout}
        path="/company"
      />

      <RouteWithLayout
        component={UserRollView}
        exact
        layout={MainLayout}
        path="/company/roll/:companyRollId"
      />
      <RouteWithLayout
        component={AddRollView}
        exact
        layout={MainLayout}
        path="/company/roll/:companyRollId/add-roll"
      />
      <RouteWithLayout
        component={AddRollView}
        exact
        layout={MainLayout}
        path="/company/roll/:companyRollId/:RollId"
      />
      <RouteWithLayout
        component={AddCompanyView}
        exact
        layout={MainLayout}
        path="/company/edit/:companyEditId"
      />
      <RouteWithLayout
        component={CompanyUserView}
        exact
        layout={MainLayout}
        path="/company/:companyId"
      />
      <RouteWithLayout
        component={AddCompanyUserView}
        exact
        layout={MainLayout}
        path="/company/:companyId/add-user"
      />
      <RouteWithLayout
        component={AddCompanyUserView}
        exact
        layout={MainLayout}
        path="/company/:companyId/:companyUserId"
      />
      <RouteWithLayout
        component={AddCompanyView}
        exact
        layout={MainLayout}
        path="/add-Company"
      />
      <RouteWithLayout
        component={CustomerView}
        exact
        layout={MainLayout}
        path="/company/user/customer/:compId"
      />
      <RouteWithLayout
        component={CompanyCustomerView}
        exact
        layout={MainLayout}
        path="/company/user/customer/:compId/:userId"
      />
      <RouteWithLayout
        component={BillingCancelSubscriptionView}
        exact
        layout={MainLayout}
        path="/company/user/customer/:compId/:userId/cancel-subscription"
      />
      <RouteWithLayout
        component={BillingCancelSubscribeOfferview}
        exact
        layout={MainLayout}
        path="/company/user/customer/:compId/:userId/cancel-subscriptionoffer"
      />
      <RouteWithLayout
        component={ReportsCustomerView}
        exact
        layout={MainLayout}
        path="/company/reports/customer/:compId"
      />
      <RouteWithLayout
        component={CompanyCustomerView}
        exact
        layout={MainLayout}
        path="/company/reports/customer/:compId/:userId"
      />
      <RouteWithLayout
        component={BillingCancelSubscriptionView}
        exact
        layout={MainLayout}
        path="/company/reports/customer/:compId/:userId/cancel-subscription"
      />
      <RouteWithLayout
        component={BillingCancelSubscribeOfferview}
        exact
        layout={MainLayout}
        path="/company/reports/customer/:compId/:userId/cancel-subscriptionoffer"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={AddUserview}
        exact
        layout={MainLayout}
        path="/add-user"
      />
      <RouteWithLayout
        component={PermissionView}
        exact
        layout={MainLayout}
        path="/permission"
      />

      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SubscriptionView}
        exact
        layout={MainLayout}
        path="/subscription"
      />
      <RouteWithLayout
        component={AddSubscriptionView}
        exact
        layout={MainLayout}
        path="/subscription/:subscribeId"
      />
      <RouteWithLayout
        component={AddSubscriptionView}
        exact
        layout={MainLayout}
        path="/add-subscription"
      />
      <RouteWithLayout
        component={MenuPermissionView}
        exactView
        layout={MainLayout}
        path="/menu-permission"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      /> */}
      <Link to="/not-found" />
    </Switch>
  );
};

export default Routes;

import React, { Component, useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Button, DatePicker, Input, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import axios from "axios";
import "../../App.css";
import moment from "moment";

const AddMarriage = () => {
  let countriesList;
  let statesList;
  let citiesList;
  let token;
  const history = useHistory();
  const location = useLocation();
  const { userIdToBeEdited } =
    location.state != undefined ? location.state : "";
  const dateFormatList = ["YYYY/MM/DD"];
  const [patrika, setPatrika] = useState("");
  const [filePayslip, setFilePayslip] = React.useState("");
  const [filePayslipErr, setFilePayslipErr] = useState({});
  const [transactionId, setTransactionId] = useState("");
  const [transactionIdErr, setTransactionIdErr] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [education, setEducation] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState({});
  const [mobileNo, setMobileNo] = useState("");
  const [mobileNoErr, setMobileNoErr] = useState({});
  const [dob, setDob] = useState("");
  const [dobErr, setDobErr] = useState({});
  const [dobWords, setDobWords] = useState("");
  const [dobWordsErr, setDobWordsErr] = useState({});
  const [dobTime, setDobTime] = useState("");
  const [dobTimeErr, setDobTimeErr] = useState({});
  const [dobPlace, setDobPlace] = useState("");
  const [dobPlaceErr, setDobPlaceErr] = useState({});
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [complexion, setComplexion] = useState("");
  const [kundali, setKundali] = useState("");
  const [mangleek, setMangkleek] = useState("");
  const [upajit, setUpajit] = useState("");
  const [upajitBandhan, setUpajitBandhan] = useState("");
  const [nijvansh, setNijvansh] = useState("");
  const [nijvanshErr, setNijvanshErr] = useState({});
  const [mamaVansh, setMamaVansh] = useState("");
  const [mamaVanshErr, setMamaVanshErr] = useState({});
  const [nakshatra, setNakshatra] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [disablility, setDisablility] = useState("");
  const [priority, setPriority] = useState("");

  // Parivaarik vivaran constants//
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [makaanNo, setMakaanNo] = useState("");
  const [gali, setGali] = useState("");
  const [cityVillage, setCityVillage] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const countries = selectedCountry.map((country) => country);
  const [selectedState, setSelectedState] = useState([]);
  const states = selectedState.map((state) => state);
  const [selectedCity, setSelectedCity] = useState([]);
  const cities = selectedCity.map((city) => city);
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [pinCode, setPinCode] = useState("");
  const [paarivarikPhone, setPaarivarikPhone] = useState("");
  const [paarivarikMobile, setPaarivarikMobile] = useState("");
  const [paarivarikOccupation, setPaarivarikOccupation] = useState("");
  const [paarivarikAnnualIncome, setPaarivarikAnnualIncome] = useState("");
  const [paarivarikBrotherMarried, setPaarivarikBrotherMarried] = useState(0);
  const [paarivarikBrotherUnMarried, setPaarivarikBrotherUnMarried] =
    useState(0);
  const [paarivarikSisterMarried, setPaarivarikSisterMarried] = useState(0);
  const [paarivarikSisterUnMarried, setPaarivarikSisterUnMarried] = useState(0);
  const [paarivarikAccomodation, setPaarivarikAccomodation] = useState(0);
  // Parivaarik vivaran constants//

  // Chacha Mama Foofa constants//
  const [otherName, setOtherName] = useState("");
  const [otherAddress, setOtherAddress] = useState("");
  const [otherPhone, setOtherPhone] = useState("");
  const [otherMobile, setOtherMobile] = useState("");
  const [otherName2, setOtherName2] = useState("");
  const [otherAddress2, setOtherAddress2] = useState("");
  const [otherPhone2, setOtherPhone2] = useState("");
  const [otherMobile2, setOtherMobile2] = useState("");
  const [fileOtherPhoto, setOtherPhoto] = React.useState("");
  const [terms, setTerms] = useState(false);
  const [termsErr, setTermsErr] = useState({});
  // Chacha Mama Foofa constants//

  // file uploads constants //
  const [marriageImagePath, setMarriageImagePath] = useState("");
  const [marriageImage, setMarriageImage] = useState("");
  const [paySlipImagePath, setPaySlipImagePath] = useState("");
  const [paySlipImage, setPaySlipImage] = useState("");
  const [isMarriageImageUploaded, setIsMarriageImageUploaded] = useState(false);
  const [isPaySlipImageUploaded, setIsPaySlipImageUploaded] = useState(false);
  // file upload constants //
  const headingStyle = {
    color: "white",
    backgroundColor: "#4D4D4D",
    paddingTop: "6em",
    paddingBottom: "1em",
  };

  const ImageThumb = ({ image }) => {
    return (
      <img
        style={{ height: "100px" }}
        src={URL.createObjectURL(image)}
        alt={image.name}
      />
    );
  };

  const handleUploadPaySlip = (event) => {
    setIsPaySlipImageUploaded(true);
    setPaySlipImage("");
    setPaySlipImagePath("");
    setFilePayslip(event.target.files[0]);
  };

  const handleUploadPhoto = (event) => {
    setIsMarriageImageUploaded(true);
    setMarriageImage("");
    setMarriageImagePath("");
    setOtherPhoto(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      // after api is called
      saveDetails();
    }
  };

  const saveDetails = async (e) => {
    try {
      const formData = new FormData();
      if (userIdToBeEdited != "") formData.append("id", userIdToBeEdited);
      formData.append("booknumber", patrika);
      formData.append("trans_id", transactionId);
      formData.append("trans_slip", filePayslip);
      formData.append("name", name);
      formData.append("gender", gender);
      formData.append("phone", phoneNo);
      formData.append("smobile", mobileNo);
      formData.append("email", email);
      formData.append("occupation", service);
      formData.append("seducation", education);
      formData.append("sincome", annualIncome);
      formData.append("sdob", dob);
      formData.append("sbday", dobWords);
      formData.append("sbirthtime", dobTime);
      formData.append("sbirthplace", dobPlace);
      formData.append("sheight", height);
      formData.append("sweight", weight);
      formData.append("scolor", complexion);
      formData.append("skundali", kundali);
      formData.append("smangalik", mangleek);
      formData.append("supprajati", upajit);
      formData.append("supjati", upajitBandhan);
      formData.append("sselfvansh", nijvansh);
      formData.append("smamavansh", mamaVansh);
      formData.append("snachatra", nakshatra);
      formData.append("sblood", bloodGroup);
      formData.append("sphysical", disablility);
      formData.append("sselect", priority);
      formData.append("sfname", fatherName);
      formData.append("smname", motherName);
      formData.append("shouse", makaanNo);
      formData.append("sgali", gali);
      formData.append("scity1", cityVillage);
      formData.append("scountry1", country);
      formData.append("sstate1", state);
      formData.append("sdistrict1", city);
      formData.append("spin", pinCode);
      formData.append("sphone1", phoneNo);
      formData.append("smobile1", paarivarikMobile);
      formData.append("soccupation2", paarivarikOccupation);
      formData.append("sincome2", paarivarikAnnualIncome);
      formData.append("bmerraige1", paarivarikBrotherMarried);
      formData.append("bmerraige2", paarivarikBrotherUnMarried);
      formData.append("smerraige1", paarivarikSisterMarried);
      formData.append("smerraige2", paarivarikSisterUnMarried);
      formData.append("sawash", paarivarikAccomodation);
      formData.append("sname1", otherName);
      formData.append("saddress1", otherAddress);
      formData.append("smobile2", otherMobile);
      formData.append("sname2", otherName2);
      formData.append("saddress2", otherAddress2);
      formData.append("smobile3", otherMobile2);
      formData.append("files2", fileOtherPhoto);
      formData.append("terms", terms);
      formData.append("user_id", localStorage.getItem("loggedUserId"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            token == undefined ? localStorage.getItem("loggedToken") : token
          }`,
        },
      };
      // const body = formData;
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/addeditmetrouser",
        formData,
        config
      );
      if (res.data.success == 1) {
        alert(res.data.message);
        history.push({ pathname: "/ManageMarriage" });
      } else {
        alert("Error while inserting the entries");
        return false;
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };

  const formValidation = () => {
    const transactionIdErr = {};
    const filePayslipErr = {};
    const nameErr = {};
    const genderErr = {};
    const mobileNoErr = {};
    const dobErr = {};
    const dobTimeErr = {};
    const dobWordsErr = {};
    const dobPlaceErr = {};
    const nijvanshErr = {};
    const mamaVanshErr = {};
    const termsErr = {};

    let isValid = true;
    if (transactionId == "") {
      transactionIdErr.EmptyTransactionId =
        "Please make payment and add Transaction and attached slip.";
      isValid = false;
    }

    if (filePayslip == "") {
      filePayslipErr.EmptyPaySlip = "Upload the pay slip";
      isValid = false;
    }

    if (name == "") {
      nameErr.EmptyName = "This field is required.";
      isValid = false;
    }

    if (gender == "") {
      genderErr.EmptyGender = "This field is required.";
      isValid = false;
    }

    if (mobileNo == "") {
      mobileNoErr.EmptyMobileNo = "This field is required.";
      isValid = false;
    }

    if (dob == "") {
      dobErr.EmptyDOB = "This field is required.";
      isValid = false;
    }

    if (dobTime == "") {
      dobTimeErr.EmptyDOBTime = "This field is required.";
      isValid = false;
    }

    if (dobPlace == "") {
      dobPlaceErr.EmptyDOBPlace = "This field is required.";
      isValid = false;
    }

    if (dobWords == "") {
      dobWordsErr.EmptyDOBWords = "This field is required.";
      isValid = false;
    }

    if (nijvansh == "") {
      nijvanshErr.EmptyNijvansh = "This field is required.";
      isValid = false;
    }

    if (mamaVansh == "") {
      mamaVanshErr.EmptyMamaVansh = "This field is required.";
      isValid = false;
    }

    if (terms == false) {
      termsErr.EmptyTerms = "Agree to terms and conditions";
      isValid = false;
    }

    setTransactionIdErr(transactionIdErr);
    setFilePayslipErr(filePayslipErr);
    setNameErr(nameErr);
    setGenderErr(genderErr);
    setMobileNoErr(mobileNoErr);
    setDobErr(dobErr);
    setDobTimeErr(dobTimeErr);
    setDobPlaceErr(dobPlaceErr);
    setDobWordsErr(dobWordsErr);
    setNijvanshErr(nijvanshErr);
    setMamaVanshErr(mamaVanshErr);
    setTermsErr(termsErr);
    return isValid;
  };

  const getMemberDetails = async (e) => {
    try {
      const postData = {
        id: userIdToBeEdited,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("loggedToken")}`,
        },
      };
      const body = JSON.stringify(postData);
      const res = await axios.post(
        "https://api.golapurvajainsamaj.com/api/v1/users/viewofusermetro",
        body,
        config
      );
      if (res.data.success == 1) {
        setPatrika(res.data.data.booknumber);
        setTransactionId(res.data.data.trans_id);
        setName(res.data.data.name);
        setGender(res.data.data.gender);
        setPhoneNo(res.data.data.phone);
        setMobileNo(res.data.data.smobile);
        setEmail(res.data.data.email);
        setService(res.data.data.soccupation);
        setEducation(res.data.data.seducation);
        setAnnualIncome(res.data.data.sincome);
        setDob(res.data.data.sdob);
        setDobWords(res.data.data.sbday);
        setDobTime(res.data.data.sbirthtime);
        setDobPlace(res.data.data.sbirthplace);
        setHeight(res.data.data.sheight);
        setWeight(res.data.data.sweight);
        setComplexion(res.data.data.scolor);
        setKundali(res.data.data.skundali);
        setMangkleek(res.data.data.smangalik);
        setUpajit(res.data.data.supprajati);
        setUpajitBandhan(res.data.data.supjati);
        setNijvansh(res.data.data.sselfvansh);
        setMamaVansh(res.data.data.smamavansh);
        setNakshatra(res.data.data.snachatra);
        setBloodGroup(res.data.data.sblood);
        setDisablility(res.data.data.sphysical);
        setPriority(res.data.data.sselect);

        // Paarivarik Vivaran //
        setFatherName(res.data.data.sfname);
        setMotherName(res.data.data.smname);
        setMakaanNo(res.data.data.shouse);
        setGali(res.data.data.sgali);
        setCityVillage(res.data.data.scity1);
        setCountry(countriesList[0].name);
        await changeCountry();

        let state = statesList.find((x) => x.id == res.data.data.sstate1);
        if (state != undefined) {
          setState(state.name);
          await changeState(state.id);
        }

        if (
          res.data.data.sdistrict1 != null &&
          res.data.data.sdistrict1 != "0"
        ) {
          if (citiesList != undefined) {
            let district = citiesList.find(
              (x) => x.id == res.data.data.sdistrict1
            );
            setCity(district.name);
          }
        }

        setPinCode(res.data.data.spin);
        setPaarivarikMobile(res.data.data.smobile1);
        setPaarivarikPhone(res.data.data.sphone1);
        setPaarivarikOccupation(res.data.data.soccupation2);
        setPaarivarikAnnualIncome(res.data.data.sincome2);
        setPaarivarikBrotherMarried(res.data.data.bmerraige1);
        setPaarivarikBrotherUnMarried(res.data.data.bmerraige2);
        setPaarivarikSisterMarried(res.data.data.smerraige1);
        setPaarivarikSisterUnMarried(res.data.data.smerraige2);
        setPaarivarikAccomodation(res.data.data.sawash);
        // Paarivarik Vivaran //

        // Others details
        setOtherName(res.data.data.sname1);
        setOtherAddress(res.data.data.saddress1);
        setOtherPhone(res.data.data.sphone2);
        setOtherMobile(res.data.data.smobile2);
        setOtherName2(res.data.data.sname2);
        setOtherAddress2(res.data.data.saddress2);
        setOtherMobile2(res.data.data.smobile2);
        setOtherPhone2(res.data.data.sphone3);
        setTerms(res.data.data.terms);
        // Others details

        if (res.data.profile_img != null) {
          setMarriageImagePath(res.data.profile_img);
          setMarriageImage(res.data.data.files2);
        }

        if (res.data.pay_slipimg != null) {
          setPaySlipImagePath(res.data.pay_slipimg);
          setPaySlipImage(res.data.data.trans_slip);
        }
      } else {
        alert("Error while fetching member details");
        return false;
      }
    } catch (err) {
      if (err == undefined) {
        alert("Error occurred while fetching member details");
        return false;
      }
      if (err.response.status == 401) {
        localStorage.clear();
        history.push({ pathname: "/login" });
      }
      console.error(err.response.data);
    }
  };

  const getCountries = async () => {
    await axios
      .post("https://api.golapurvajainsamaj.com/api/v1/staticlists/getcountry")
      .then((response) => {
        setSelectedCountry(response.data.data);
        countriesList = response.data.data;
      });
  };

  const getStates = async () => {
    await axios
      .post("https://api.golapurvajainsamaj.com/api/v1/staticlists/getstate")
      .then((response) => {
        setSelectedState(response.data.data);
        statesList = response.data.data;
      });
  };

  const getCities = async (e) => {
    await axios
      .post(
        "https://api.golapurvajainsamaj.com/api/v1/staticlists/getcity?state_id=" +
          e
      )
      .then((response) => {
        setSelectedCity(response.data.data);
        citiesList = response.data.data;
      });
  };

  useEffect(async () => {
    if (localStorage.getItem("loggedToken") != undefined) {
      await getCountries();
      await getStates();
      if (userIdToBeEdited != undefined) await getMemberDetails();
    } else {
      history.push({ pathname: "/login" });
    }
  }, [setSelectedCountry]);

  const changeCountry = async (event) => {
    if (event != undefined) setCountry(event);
    await getStates();
  };

  const changeState = async (event) => {
    setState(event);
    await getCities(event);
  };

  const changeCity = (event) => {
    setCity(event);
  };

  return (
    <div>
      <div style={{ backgroundColor: "#FFFBCC", height: "25em" }}>
        <div className="col-12 text-center">
          <h2 style={headingStyle}>
            पारिवारिक{" "}
            <b style={{ color: "#e47257" }}>
              विवरण
              <p style={{ color: "White" }}>मुख्य पृष्ठ / पारिवारिक विवरण</p>
            </b>
          </h2>
        </div>
      </div>
      <div>
        <div class="col-md-12 column">
          <div
            class="who-we-are"
            style={{
              backgroundColor: "#FFFBCC",
              paddingLeft: "5em",
              paddingRight: "6em",
            }}
          >
            <div class="form-group col-md-12">
              <p className="addMarriageP">
                सभी समाजजन से आग्रह है कि अपना फॉर्म मातृभाषा हिंदी में ही भरे
              </p>
              <p className="addMarriageP">
                जो भी प्रत्याशी हिंदी में फॉर्म नहीं भर पा रहे हैं वह इंग्लिश
                में भी भर सकते हैं
              </p>
              <p className="addMarriageSubP">
                यदि परिवार के सगे भाईयों या बहिनो की अर्थात एक परिवार से दो
                प्रविष्टि आती है तो उनके लिए एक पत्रिका ही दी जाएगी , साथ ही
                पंजीयन राशि जो कि 450/- + 200/-=650/- जमा करानी होगी
              </p>
              <p className="addMarriageSubP">
                अभिभावकों के लिए प्रत्याशी की प्रविष्टि के साथ 450/- की राशि जमा
                कराना आवश्यक है
              </p>
            </div>

            <form>
              <div className="row">
                <h3 style={{ color: "red" }}>प्रत्याशी विवरण</h3>
              </div>
              <div className="row">
                <div className="col-3">
                  <label htmlFor="Patrika">
                    <b>संस्कार पत्रिका क्रमांक 2020</b>
                  </label>
                  <br />
                  <Input
                    id="Patrika"
                    name="patrika"
                    className="rounded"
                    value={patrika}
                    onChange={(event) => setPatrika(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="TransID">
                    <b>Trans ID.</b>
                  </label>
                  <br />
                  <Input
                    id="TransID"
                    name="TransID"
                    className="rounded"
                    value={transactionId}
                    onChange={(event) => setTransactionId(event.target.value)}
                  />
                  <br />
                  {Object.keys(transactionIdErr).map((key) => {
                    return (
                      <div style={{ color: "red" }}>
                        {transactionIdErr[key]}
                      </div>
                    );
                  })}
                </div>
                <div className="col-3">
                  <label htmlFor="PaySlip">
                    <b>Pay Slip</b>
                  </label>
                  <br />
                  <input
                    id="PaySlip"
                    type="file"
                    onChange={handleUploadPaySlip}
                  />
                  <br />
                  {Object.keys(filePayslipErr).map((key) => {
                    return (
                      <div style={{ color: "red" }}>{filePayslipErr[key]}</div>
                    );
                  })}
                </div>
                <div className="col-3">
                  {isPaySlipImageUploaded ? (
                    filePayslip && <ImageThumb image={filePayslip} />
                  ) : paySlipImagePath == "" ? (
                    filePayslip && <ImageThumb image={filePayslip} />
                  ) : (
                    <img
                      style={{ height: "100px" }}
                      src={paySlipImagePath + "/" + paySlipImage}
                    ></img>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="Name">
                    <b>प्रत्याशी का नाम</b>
                  </label>
                  <br />
                  <Input
                    id="Name"
                    name="Name"
                    className="rounded"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                  <br />
                  {Object.keys(nameErr).map((key) => {
                    return <div style={{ color: "red" }}>{nameErr[key]}</div>;
                  })}
                </div>
                <div className="col-3">
                  <label htmlFor="Gender">
                    <b>लिंग</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    id="Gender"
                    placeholder="-- Select --"
                    name="gender"
                    className="rounded"
                    value={gender}
                    onChange={(event) => setGender(event)}
                  >
                    <Option value="1">Male</Option>
                    <Option value="2">Female</Option>
                  </Select>
                  <br />
                  {Object.keys(genderErr).map((key) => {
                    return <div style={{ color: "red" }}>{genderErr[key]}</div>;
                  })}
                </div>
                <div className="col-3">
                  <label htmlFor="PhoneNo">
                    <b>फोन न.</b>
                  </label>
                  <Input
                    id="PhoneNo"
                    type="number"
                    placeholder="फोन न."
                    name="PhoneNo"
                    className="rounded"
                    value={phoneNo}
                    onChange={(event) => setPhoneNo(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="MobileNo">
                    <b>मोबाइल नंबर</b>
                  </label>
                  <br />
                  <Input
                    id="MobileNo"
                    type="number"
                    placeholder="मोबाइल नंबर"
                    name="mobileNo"
                    className="rounded"
                    value={mobileNo}
                    onChange={(event) => setMobileNo(event.target.value)}
                  />
                  <br />
                  {Object.keys(mobileNoErr).map((key) => {
                    return (
                      <div style={{ color: "red" }}>{mobileNoErr[key]}</div>
                    );
                  })}
                </div>
                <div className="col-3">
                  <label htmlFor="emailId">
                    <b>ई - मेल आय. डी.</b>
                  </label>
                  <Input
                    id="emailId"
                    type="email"
                    placeholder="ई - मेल आय. डी."
                    name="emaiId"
                    className="rounded"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="service">
                    <b>व्यवसाय / सर्विस</b>
                  </label>
                  <Input
                    id="service"
                    type="service"
                    name="service"
                    className="rounded"
                    value={service}
                    onChange={(event) => setService(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="Education">
                    <b>शैक्षणिक पात्रता</b>
                  </label>
                  <br />
                  <Input
                    id="Education"
                    name="Education"
                    className="rounded"
                    value={education}
                    onChange={(event) => setEducation(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="annualIncome">
                    <b>मासिक आय रु</b>
                  </label>
                  <Input
                    id="annualIncome"
                    name="annualIncome"
                    className="rounded"
                    value={annualIncome}
                    onChange={(event) => setAnnualIncome(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="DOB">
                    <b>जन्म दिनांक</b>
                  </label>
                  <br />
                  <DatePicker
                    id="DOB"
                    name="DOB"
                    format={dateFormatList}
                    value={moment(dob, dateFormatList[0])}
                    className="rounded"
                    onChange={(event) => setDob(event)}
                  />
                  <br />
                  {Object.keys(dobErr).map((key) => {
                    return <div style={{ color: "red" }}>{dobErr[key]}</div>;
                  })}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="DOBWords">
                    <b>जन्म तिथि (शब्दों में)</b>
                  </label>
                  <br />
                  <Input
                    id="DOBWords"
                    name="DOBWords"
                    className="rounded"
                    value={dobWords}
                    onChange={(event) => setDobWords(event.target.value)}
                  />
                  <br />
                  {Object.keys(dobWordsErr).map((key) => {
                    return (
                      <div style={{ color: "red" }}>{dobWordsErr[key]}</div>
                    );
                  })}
                </div>
                <div className="col-3">
                  <label htmlFor="DOBT">
                    <b>जन्म समय</b>
                  </label>
                  <Input
                    id="DOBT"
                    name="DOBT"
                    className="rounded"
                    value={dobTime}
                    onChange={(event) => setDobTime(event.target.value)}
                  />
                  <br />
                  {Object.keys(dobTimeErr).map((key) => {
                    return (
                      <div style={{ color: "red" }}>{dobTimeErr[key]}</div>
                    );
                  })}
                </div>
                <div className="col-3">
                  <label htmlFor="DOBP">
                    <b>जन्म स्थान</b>
                  </label>
                  <Input
                    id="DOBP"
                    name="DOBP"
                    className="rounded"
                    value={dobPlace}
                    onChange={(event) => setDobPlace(event.target.value)}
                  />
                  <br />
                  {Object.keys(dobPlaceErr).map((key) => {
                    return (
                      <div style={{ color: "red" }}>{dobPlaceErr[key]}</div>
                    );
                  })}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="Height">
                    <b>उँचाई</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    id="Height"
                    placeholder="-- Select --"
                    name="Height"
                    className="rounded"
                    value={height}
                    onChange={(event) => setHeight(event)}
                  >
                    <option value="1">4' 0" (1.22 mts)</option>
                    <option value="2">4' 1" (1.24 mts)</option>
                    <option value="3">4' 2" (1.28 mts)</option>
                    <option value="4">4' 3" (1.31 mts)</option>
                    <option value="5">4' 4" (1.34 mts)</option>
                    <option value="6">4' 5" (1.35 mts)</option>
                    <option value="7">4' 6" (1.37 mts)</option>
                    <option value="8">4' 7" (1.40 mts)</option>
                    <option value="9">4' 8" (1.42 mts)</option>
                    <option value="10">4' 9" (1.45 mts)</option>
                    <option value="11">4' 10" (1.47 mts)</option>
                    <option value="12">4' 11" (1.50 mts)</option>
                    <option value="13">5' 0" (1.52 mts)</option>
                    <option value="14">5' 1" (1.55 mts)</option>
                    <option value="15">5' 2" (1.58 mts)</option>
                    <option value="16">5' 3" (1.60 mts)</option>
                    <option value="17">5' 4" (1.63 mts)</option>
                    <option value="18">5' 5" (1.65 mts)</option>
                    <option value="19">5' 6" (1.68 mts)</option>
                    <option value="20">5' 7" (1.70 mts)</option>
                    <option value="21">5' 8" (1.73 mts)</option>
                    <option value="22">5' 9" (1.75 mts)</option>
                    <option value="23">5' 10" (1.78 mts)</option>
                    <option value="24">5' 11" (1.80 mts)</option>
                    <option value="25">6' 0" (1.83 mts)</option>
                    <option value="26">6' 1" (1.85 mts)</option>
                    <option value="27">6' 2" (1.88 mts)</option>
                    <option value="28">6' 3" (1.91 mts)</option>
                    <option value="29">6' 4" (1.93 mts)</option>
                    <option value="30">6' 5" (1.96 mts)</option>
                    <option value="31">6' 6" (1.98 mts)</option>
                    <option value="32">6' 7" (2.01 mts)</option>
                    <option value="33">6' 8" (2.03 mts)</option>
                    <option value="34">6' 9" (2.06 mts)</option>
                    <option value="35">6' 10" (2.08 mts)</option>
                    <option value="36">6' 11" (2.11 mts)</option>
                    <option value="37">7' (2.13 mts) plus</option>
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="Weight">
                    <b>वजन Please Write (Kg) Format</b>
                  </label>
                  <br />
                  <Input
                    id="Weight"
                    name="Weight"
                    className="rounded"
                    value={weight}
                    onChange={(event) => setWeight(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="Complexion">
                    <b>वर्ण (रंग)</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    id="Complexion"
                    placeholder="-- Select --"
                    name="Complexion"
                    className="rounded"
                    value={complexion}
                    onChange={(event) => setComplexion(event)}
                  >
                    <option value="गोरा">गोरा</option>
                    <option value="गेहूंआ">गेहूंआ</option>
                    <option value="श्याम">श्याम</option>
                  </Select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="Kundali">
                    <b>कुंडली मिलान के पक्ष में</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    id="Kundali"
                    placeholder="-- Select --"
                    name="Kundali"
                    className="rounded"
                    value={kundali}
                    onChange={(event) => setKundali(event)}
                  >
                    <Option value="1">Yes</Option>
                    <Option value="2">No</Option>
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="Mangleek">
                    <b>मांगलिक</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    id="Mangleek"
                    placeholder="-- Select --"
                    name="Mangleek"
                    className="rounded"
                    value={mangleek}
                    onChange={(event) => setMangkleek(event)}
                  >
                    <Option value="1">Yes</Option>
                    <Option value="2">No</Option>
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="Upagit">
                    <b>उपजाति</b>
                  </label>
                  <br />
                  <Input
                    id="Upagit"
                    name="Upagit"
                    className="rounded"
                    value={upajit}
                    onChange={(event) => setUpajit(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="UpagitBandhan">
                    <b>उपजाति बंधन</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    id="UpagitBandhan"
                    placeholder="-- Select --"
                    name="UpagitBandhan"
                    className="rounded"
                    value={upajitBandhan}
                    onChange={(event) => setUpajitBandhan(event)}
                  >
                    <Option value="1">Yes</Option>
                    <Option value="2">No</Option>
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="Nijvansh">
                    <b>निजवंश</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    id="Nijvansh"
                    placeholder="-- Select --"
                    name="Nijvansh"
                    className="rounded"
                    value={nijvansh}
                    onChange={(event) => setNijvansh(event)}
                  >
                    <option value="1">इन्द्रमहाजन</option>
                    <option value="2">कनकपुरिया</option>
                    <option value="3">कपासिया</option>
                    <option value="4">करैया</option>
                    <option value="5">कहारिया</option>
                    <option value="6">कोठिया</option>
                    <option value="7">कोनिया</option>
                    <option value="8">खडेरे</option>
                    <option value="9">खाग</option>
                    <option value="10">खुर्देलिया</option>
                    <option value="11">खेरानी</option>
                    <option value="12">गंदर्भ (गंधकार)</option>
                    <option value="13">गढ़ौले</option>
                    <option value="14">गुबारिलहा</option>
                    <option value="15">गोदरे (मलैया)</option>
                    <option value="16">गौरहा</option>
                    <option value="17">गंगैरिया</option>
                    <option value="18">चंदेरिया (चांदलिया)</option>
                    <option value="19">चारखेरे</option>
                    <option value="20">चौंसरा</option>
                    <option value="21">छबेले</option>
                    <option value="22">छैलकटे</option>
                    <option value="23">जतहरिया</option>
                    <option value="24">जुझौतिया</option>
                    <option value="25">टीका के रावत</option>
                    <option value="26">टैंटवार</option>
                    <option value="27">तिगैले</option>
                    <option value="28">दंडधार</option>
                    <option value="29">दूगैले</option>
                    <option value="30">द्विजमहाजन</option>
                    <option value="31">निर्मोलक</option>
                    <option value="32">धना</option>
                    <option value="33">धामोनिया</option>
                    <option value="34">नाहर</option>
                    <option value="35">पटोरिया</option>
                    <option value="36">पटारया</option>
                    <option value="37">पटरसे</option>
                    <option value="38">पडेले</option>
                    <option value="39">पतरिया</option>
                    <option value="40">पतरोया</option>
                    <option value="41">पिपरैया</option>
                    <option value="42">पंचरत्न</option>
                    <option value="43">पचलोरे</option>
                    <option value="44">पैथवार</option>
                    <option value="45">पपोरहा</option>
                    <option value="46">प्रेमपुरिया</option>
                    <option value="47">फुसकेले</option>
                    <option value="48">बड़धरिया</option>
                    <option value="49">बदरौठिया</option>
                    <option value="50">बनोनिया</option>
                    <option value="51">बेरिया (बेड़िया)</option>
                    <option value="52">बचैया (मड़ैया)</option>
                    <option value="53">बोदरे</option>
                    <option value="54">मझगैया</option>
                    <option value="55">भरतपुरिया</option>
                    <option value="56">भिलसैया</option>
                    <option value="57">मरैया</option>
                    <option value="58">मझरैया</option>
                    <option value="59">मेधवार</option>
                    <option value="60">रस</option>
                    <option value="61">साधारण</option>
                    <option value="62">रतौले</option>
                    <option value="63">सनकुटा</option>
                    <option value="64">सपेले</option>
                    <option value="65">सौधानी</option>
                    <option value="66">सोंधले (सांधेलिय)</option>
                    <option value="67">सिरसपुरिया</option>
                    <option value="68">सोनी</option>
                    <option value="69">सौधनी</option>
                    <option value="70">सोरिया</option>
                    <option value="71">सरखडे</option>
                    <option value="72">सौतिया</option>
                    <option value="73">सपौलिहा</option>
                    <option value="74">शेखर</option>
                    <option value="75">हीरापुरिया</option>
                    <option value="76">लखनपुरिया</option>
                    <option value="77">रांधेलिया</option>
                    <option value="78">धुवेले</option>
                    <option value="79">बिलबिले</option>
                    <option value="80">गंगेरी</option>
                    <option value="81">अलेहा</option>
                    <option value="82">सड़ैया</option>
                    <option value="83">बादौनिया</option>
                    <option value="84">कंशैया</option>
                    <option value="85">इन में से कोई नहीं </option>
                  </Select>
                  <br />
                  {Object.keys(nijvanshErr).map((key) => {
                    return (
                      <div style={{ color: "red" }}>{nijvanshErr[key]}</div>
                    );
                  })}
                </div>
                <div className="col-3">
                  <label htmlFor="MamaVansh">
                    <b>मामा वंश</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    id="MamaVansh"
                    placeholder="-- Select --"
                    name="MamaVansh"
                    className="rounded"
                    value={mamaVansh}
                    onChange={(event) => setMamaVansh(event)}
                  >
                    <option value="1">इन्द्रमहाजन</option>
                    <option value="2">कनकपुरिया</option>
                    <option value="3">कपासिया</option>
                    <option value="4">करैया</option>
                    <option value="5">कहारिया</option>
                    <option value="6">कोठिया</option>
                    <option value="7">कोनिया</option>
                    <option value="8">खडेरे</option>
                    <option value="9">खाग</option>
                    <option value="10">खुर्देलिया</option>
                    <option value="11">खेरानी</option>
                    <option value="12">गंदर्भ (गंधकार)</option>
                    <option value="13">गढ़ौले</option>
                    <option value="14">गुबारिलहा</option>
                    <option value="15">गोदरे (मलैया)</option>
                    <option value="16">गौरहा</option>
                    <option value="17">गंगैरिया</option>
                    <option value="18">चंदेरिया (चांदलिया)</option>
                    <option value="19">चारखेरे</option>
                    <option value="20">चौंसरा</option>
                    <option value="21">छबेले</option>
                    <option value="22">छैलकटे</option>
                    <option value="23">जतहरिया</option>
                    <option value="24">जुझौतिया</option>
                    <option value="25">टीका के रावत</option>
                    <option value="26">टैंटवार</option>
                    <option value="27">तिगैले</option>
                    <option value="28">दंडधार</option>
                    <option value="29">दूगैले</option>
                    <option value="30">द्विजमहाजन</option>
                    <option value="31">निर्मोलक</option>
                    <option value="32">धना</option>
                    <option value="33">धामोनिया</option>
                    <option value="34">नाहर</option>
                    <option value="35">पटोरिया</option>
                    <option value="36">पटारया</option>
                    <option value="37">पटरसे</option>
                    <option value="38">पडेले</option>
                    <option value="39">पतरिया</option>
                    <option value="40">पतरोया</option>
                    <option value="41">पिपरैया</option>
                    <option value="42">पंचरत्न</option>
                    <option value="43">पचलोरे</option>
                    <option value="44">पैथवार</option>
                    <option value="45">पपोरहा</option>
                    <option value="46">प्रेमपुरिया</option>
                    <option value="47">फुसकेले</option>
                    <option value="48">बड़धरिया</option>
                    <option value="49">बदरौठिया</option>
                    <option value="50">बनोनिया</option>
                    <option value="51">बेरिया (बेड़िया)</option>
                    <option value="52">बचैया (मड़ैया)</option>
                    <option value="53">बोदरे</option>
                    <option value="54">मझगैया</option>
                    <option value="55">भरतपुरिया</option>
                    <option value="56">भिलसैया</option>
                    <option value="57">मरैया</option>
                    <option value="58">मझरैया</option>
                    <option value="59">मेधवार</option>
                    <option value="60">रस</option>
                    <option value="61">साधारण</option>
                    <option value="62">रतौले</option>
                    <option value="63">सनकुटा</option>
                    <option value="64">सपेले</option>
                    <option value="65">सौधानी</option>
                    <option value="66">सोंधले (सांधेलिय)</option>
                    <option value="67">सिरसपुरिया</option>
                    <option value="68">सोनी</option>
                    <option value="69">सौधनी</option>
                    <option value="70">सोरिया</option>
                    <option value="71">सरखडे</option>
                    <option value="72">सौतिया</option>
                    <option value="73">सपौलिहा</option>
                    <option value="74">शेखर</option>
                    <option value="75">हीरापुरिया</option>
                    <option value="76">लखनपुरिया</option>
                    <option value="77">रांधेलिया</option>
                    <option value="78">धुवेले</option>
                    <option value="79">बिलबिले</option>
                    <option value="80">गंगेरी</option>
                    <option value="81">अलेहा</option>
                    <option value="82">सड़ैया</option>
                    <option value="83">बादौनिया</option>
                    <option value="84">कंशैया</option>
                    <option value="85">इन में से कोई नहीं </option>
                  </Select>
                  <br />
                  {Object.keys(mamaVanshErr).map((key) => {
                    return (
                      <div style={{ color: "red" }}>{mamaVanshErr[key]}</div>
                    );
                  })}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="Nakshatra">
                    <b>नक्षत्र</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    id="Nakshatra"
                    placeholder="-- Select --"
                    name="Nakshatra"
                    className="rounded"
                    value={nakshatra}
                    onChange={(event) => setNakshatra(event)}
                  >
                    <option value="अश्विनी नक्षत्र">अश्विनी नक्षत्र</option>
                    <option value="भरणी नक्षत्र">भरणी नक्षत्र</option>
                    <option value="कृत्तिका नक्षत्र - 1">
                      कृत्तिका नक्षत्र - 1
                    </option>
                    <option value="कृत्तिका नक्षत्र - 3">
                      कृत्तिका नक्षत्र - 3
                    </option>
                    <option value="रोहिणी नक्षत्र">रोहिणी नक्षत्र</option>
                    <option value="मृगशिरा नक्षत्र - 1">
                      मृगशिरा नक्षत्र - 1
                    </option>
                    <option value="मृगशिरा नक्षत्र - 2">
                      मृगशिरा नक्षत्र - 2
                    </option>
                    <option value="आर्द्रा नक्षत्र">आर्द्रा नक्षत्र</option>
                    <option value="पुनर्वसु नक्षत्र - 1">
                      पुनर्वसु नक्षत्र - 1
                    </option>
                    <option value="पुनर्वसु नक्षत्र - 3">
                      पुनर्वसु नक्षत्र - 3
                    </option>
                    <option value="पुष्य नक्षत्र">पुष्य नक्षत्र</option>
                    <option value="आश्लेषा नक्षत्र">आश्लेषा नक्षत्र</option>
                    <option value="पूर्वा फाल्गुनी नक्षत्र">
                      पूर्वा फाल्गुनी नक्षत्र
                    </option>
                    <option value="उत्तरा फाल्गुनी नक्षत्र - 1">
                      उत्तरा फाल्गुनी नक्षत्र - 1
                    </option>
                    <option value="उत्तरा फाल्गुनी नक्षत्र - 3">
                      उत्तरा फाल्गुनी नक्षत्र - 3
                    </option>
                    <option value="हस्त नक्षत्र">हस्त नक्षत्र</option>
                    <option value="चित्रा नक्षत्र - 1">
                      चित्रा नक्षत्र - 1
                    </option>
                    <option value="चित्रा नक्षत्र - 2">
                      चित्रा नक्षत्र - 2
                    </option>
                    <option value="स्वाति नक्षत्र">स्वाति नक्षत्र</option>
                    <option value="विशाखा नक्षत्र - 1">
                      विशाखा नक्षत्र - 1
                    </option>
                    <option value="विशाखा नक्षत्र - 3">
                      विशाखा नक्षत्र - 3
                    </option>
                    <option value="अनुराधा नक्षत्र">अनुराधा नक्षत्र</option>
                    <option value="ज्येष्ठा नक्षत्र">ज्येष्ठा नक्षत्र</option>
                    <option value="मूल नक्षत्र">मूल नक्षत्र</option>
                    <option value="पूर्वाषाढ़ा नक्षत्र - 1">
                      पूर्वाषाढ़ा नक्षत्र
                    </option>
                    <option value="उत्तराषाढ़ा नक्षत्र">
                      उत्तराषाढ़ा नक्षत्र
                    </option>
                    <option value="श्रवण नक्षत्र">श्रवण नक्षत्र</option>
                    <option value="धनिष्ठा नक्षत्र -1">
                      धनिष्ठा नक्षत्र -1
                    </option>
                    <option value="धनिष्ठा नक्षत्र - 2">
                      धनिष्ठा नक्षत्र - 2
                    </option>
                    <option value="शतभिषा नक्षत्र">शतभिषा नक्षत्र</option>
                    <option value="उत्तराभाद्रपद नक्षत्र">
                      उत्तराभाद्रपद नक्षत्र
                    </option>
                    <option value="रेवती नक्षत्र">रेवती नक्षत्र</option>
                    <option value="पूर्वाभाद्रपद नक्षत्र - 1">
                      पूर्वाभाद्रपद नक्षत्र - 1
                    </option>
                    <option value="पूर्वाभाद्रपद नक्षत्र - 3">
                      पूर्वाभाद्रपद नक्षत्र - 3
                    </option>
                    <option value="मघा नक्षत्र">मघा नक्षत्र</option>
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="BloodGroup">
                    <b>ब्लड ग्रुप</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    id="BloodGroup"
                    placeholder="-- Select --"
                    name="bloodGroup"
                    className="rounded"
                    value={bloodGroup}
                    onChange={(event) => setBloodGroup(event)}
                  >
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="Disability">
                    <b>शारीरिक दोष (यदि हो, तो लिखे)</b>
                  </label>
                  <br />
                  <Input
                    id="Disability"
                    name="Disability"
                    className="rounded"
                    value={disablility}
                    onChange={(event) => setDisablility(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="Priority">
                    <b>चयन में प्राथमिकता</b>
                  </label>
                  <br />
                  <Input
                    id="Priority"
                    name="Priority"
                    className="rounded"
                    value={priority}
                    onChange={(event) => setPriority(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-5">
                <h3 style={{ color: "red" }}>परिवारिक विवरण</h3>
              </div>
              <div className="row">
                <div className="col-3">
                  <label htmlFor="FatherName">
                    <b>पिता का नाम श्री</b>
                  </label>
                  <br />
                  <Input
                    id="FatherName"
                    name="FatherName"
                    className="rounded"
                    value={fatherName}
                    onChange={(event) => setFatherName(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="MotherName">
                    <b>माता का नाम श्रीमती</b>
                  </label>
                  <br />
                  <Input
                    id="MotherName"
                    name="MotherName"
                    className="rounded"
                    value={motherName}
                    onChange={(event) => setMotherName(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="MakaanNo">
                    <b>मकान न.</b>
                  </label>
                  <br />
                  <Input
                    id="MakaanNo"
                    name="MakaanNo"
                    className="rounded"
                    value={makaanNo}
                    onChange={(event) => setMakaanNo(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="Gali">
                    <b>गली</b>
                  </label>
                  <br />
                  <Input
                    id="Gali"
                    name="Gali"
                    className="rounded"
                    //value={memberName}
                    //onChange={(event) => setMemberName(event.target.value)}
                  />
                  <br />
                  {/* {Object.keys(memberNameErr).map((key) => {
                    return (
                      <div style={{ color: "red" }}>{memberNameErr[key]}</div>
                    );
                  })} */}
                </div>
                <div className="col-3">
                  <label htmlFor="country">
                    <b>देश</b>
                  </label>
                  <br />
                  <Select
                    id="country"
                    showSearch
                    name="country"
                    className="rounded"
                    placeholder="-- देश --"
                    optionFilterProp="children"
                    value={country}
                    onChange={changeCountry}
                  >
                    {countries.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="CityVillage">
                    <b>शहर / गांव</b>
                  </label>
                  <Input
                    id="CityVillage"
                    type="CityVillage"
                    name="CityVillage"
                    className="rounded"
                    value={cityVillage}
                    onChange={(event) => setCityVillage(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="State">
                    <b>राज्य</b>
                  </label>
                  <br />
                  <Select
                    id="state"
                    showSearch
                    name="state"
                    placeholder="-- राज्य --"
                    className="rounded"
                    optionFilterProp="children"
                    value={state}
                    onChange={changeState}
                  >
                    {states.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="Region">
                    <b>जिला</b>
                  </label>
                  <br />
                  <Select
                    id="city"
                    showSearch
                    name="city"
                    className="rounded"
                    placeholder="-- जिला --"
                    optionFilterProp="children"
                    value={city}
                    onChange={changeCity}
                  >
                    {cities.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="Pincode">
                    <b>पिनकोड</b>
                  </label>
                  <Input
                    id="Pincode"
                    type="number"
                    placeholder="पिनकोड"
                    name="pincode"
                    className="rounded"
                    value={pinCode}
                    onChange={(event) => setPinCode(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="MobileNo">
                    <b>मोबाइल नंबर</b>
                  </label>
                  <br />
                  <Input
                    id="MobileNo"
                    type="number"
                    placeholder="मोबाइल नंबर"
                    name="mobileNo"
                    className="rounded"
                    value={paarivarikMobile}
                    onChange={(event) =>
                      setPaarivarikMobile(event.target.value)
                    }
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="phoneNo">
                    <b>फोन नंबर</b>
                  </label>
                  <Input
                    id="phoneNo"
                    type="email"
                    name="phoneNo"
                    className="rounded"
                    value={paarivarikPhone}
                    onChange={(event) => setPaarivarikPhone(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="service">
                    <b>व्यवसाय / सर्विस</b>
                  </label>
                  <Input
                    id="service"
                    type="service"
                    name="service"
                    className="rounded"
                    value={paarivarikOccupation}
                    onChange={(event) =>
                      setPaarivarikOccupation(event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="annualIncomeFamily">
                    <b>मासिक आय रु</b>
                  </label>
                  <br />
                  <Input
                    id="annualIncomeFamily"
                    name="annualIncomeFamily"
                    className="rounded"
                    value={paarivarikAnnualIncome}
                    onChange={(event) =>
                      setPaarivarikAnnualIncome(event.target.value)
                    }
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="brotherMarried">
                    <b>भाई - विवाहित</b>
                  </label>
                  <Select
                    showSearch
                    id="brotherMarried"
                    placeholder="-- Select --"
                    name="brotherMarried"
                    className="rounded"
                    value={paarivarikBrotherMarried}
                    onChange={(event) => setPaarivarikBrotherMarried(event)}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="brotherUnMarried">
                    <b>भाई - अविवाहित</b>
                  </label>
                  <Select
                    showSearch
                    id="brotherUnMarried"
                    placeholder="-- Select --"
                    name="brotherUnMarried"
                    className="rounded"
                    value={paarivarikBrotherUnMarried}
                    onChange={(event) => setPaarivarikBrotherUnMarried(event)}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </Select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="sisterMarried">
                    <b>बहन - विवाहित</b>
                  </label>
                  <Select
                    showSearch
                    id="sisterMarried"
                    placeholder="-- Select --"
                    name="sisterMarried"
                    className="rounded"
                    value={paarivarikSisterMarried}
                    onChange={(event) => setPaarivarikSisterMarried(event)}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="sisterUnMarried">
                    <b>बहन - अविवाहित</b>
                  </label>
                  <Select
                    showSearch
                    id="sisterUnMarried"
                    placeholder="-- Select --"
                    name="sisterUnMarried"
                    className="rounded"
                    value={paarivarikSisterUnMarried}
                    onChange={(event) => setPaarivarikSisterUnMarried(event)}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </Select>
                </div>
                <div className="col-3">
                  <label htmlFor="accomodation">
                    <b>आवास</b>
                  </label>
                  <Select
                    showSearch
                    id="accomodation"
                    placeholder="-- Select --"
                    name="accomodation"
                    className="rounded"
                    value={paarivarikAccomodation}
                    onChange={(event) => setPaarivarikAccomodation(event)}
                  >
                    <Option value="0">Rent</Option>
                    <Option value="1">House</Option>
                  </Select>
                </div>
              </div>
              <div className="row mt-5">
                <h3 style={{ color: "red" }}>
                  चाचा / मामा / फूफा एवं अन्य संपर्क सूत्र
                </h3>
              </div>
              <div className="row">
                <div className="col-3">
                  <label htmlFor="OtherMemberName1">
                    <b>श्रीमान</b>
                  </label>
                  <br />
                  <Input
                    id="OtherMemberName1"
                    name="OtherMemberName1"
                    className="rounded"
                    value={otherName}
                    onChange={(event) => setOtherName(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="OtherMemberAddress">
                    <b>पता</b>
                  </label>
                  <br />
                  <Input
                    id="OtherMemberAddress"
                    name="OtherMemberAddress"
                    className="rounded"
                    value={otherAddress}
                    onChange={(event) => setOtherAddress(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="OtherMemberPhone">
                    <b>फोन नंबर</b>
                  </label>
                  <br />
                  <Input
                    id="OtherMemberPhone"
                    name="OtherMemberPhone"
                    className="rounded"
                    value={otherPhone}
                    onChange={(event) => setOtherPhone(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-4">
                  <label htmlFor="OtherMemberMobile">
                    <b>मोबाइल नंबर</b>
                  </label>
                  <br />
                  <Input
                    id="OtherMemberMobile"
                    name="OtherMemberMobile"
                    className="rounded"
                    value={otherMobile}
                    onChange={(event) => setOtherMobile(event.target.value)}
                  />
                </div>
                <div className="col-5">
                  <label htmlFor="OtherMemberName2">
                    <b>श्रीमान</b>
                  </label>
                  <br />
                  <Input
                    id="OtherMemberName2"
                    name="OtherMemberName2"
                    className="rounded"
                    value={otherName2}
                    onChange={(event) => setOtherName2(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-4">
                  <label htmlFor="OtherMemberAddress2">
                    <b>पता</b>
                  </label>
                  <br />
                  <Input
                    id="OtherMemberAddress2"
                    name="OtherMemberAddress2"
                    className="rounded"
                    value={otherAddress2}
                    onChange={(event) => setOtherAddress2(event.target.value)}
                  />
                </div>
                <div className="col-5">
                  <label htmlFor="OtherMemberPhone2">
                    <b>फोन नंबर</b>
                  </label>
                  <br />
                  <Input
                    id="OtherMemberPhone2"
                    name="OtherMemberPhone2"
                    className="rounded"
                    value={otherPhone2}
                    onChange={(event) => setOtherPhone2(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-4">
                  <label htmlFor="OtherMemberMobile2">
                    <b>मोबाइल नंबर</b>
                  </label>
                  <br />
                  <Input
                    id="OtherMemberMobile2"
                    name="OtherMemberMobile2"
                    className="rounded"
                    value={otherMobile2}
                    onChange={(event) => setOtherMobile2(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="Photo">
                    <b>Photo</b>
                  </label>
                  <br />
                  <input id="Photo" type="file" onChange={handleUploadPhoto} />
                  <br />
                  <span>(*) Please Upload Passport Size Photo..</span>
                  <br />
                  {isMarriageImageUploaded ? (
                    fileOtherPhoto && <ImageThumb image={fileOtherPhoto} />
                  ) : marriageImagePath == "" ? (
                    fileOtherPhoto && <ImageThumb image={fileOtherPhoto} />
                  ) : (
                    <img
                      style={{ height: "100px" }}
                      src={marriageImagePath + "/" + marriageImage}
                    ></img>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-5">
                  <input
                    value={terms}
                    onChange={(event) => setTerms(event.target.value)}
                    type="checkbox"
                    class="form-check-input"
                    id="terms"
                  />
                  <label
                    style={{
                      fontSize: "15px",
                      fontFamily: "arimo",
                      color: "#505050",
                    }}
                    class="form-check-label"
                    for="terms"
                  >
                    &nbsp;&nbsp;I Agree Terms & Conditions / मुझे सारे नियम एवं
                    शर्ते मंजूर हें ।
                  </label>
                  <br />
                  {Object.keys(termsErr).map((key) => {
                    return <div style={{ color: "red" }}>{termsErr[key]}</div>;
                  })}
                </div>
                <div className="col-4">
                  <img
                    src="https://golapurvajainsamaj.com/public/qr.jpeg"
                    style={{ width: "270px", height: "600px" }}
                  ></img>
                </div>
              </div>
              <div className="col-6">
                <Button
                  type="primary"
                  size="large"
                  className="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
              <div className="bankDetailsFont mt-3">
                <p>
                  भारत सरकार की गाइडलाइन के अनुसार विजया बैंक का विलय, बैंक ऑफ
                  बड़ौदा में हो गया है ,अतः अब आखिल भारतवर्षीय दिगंबर जैन
                  गोलापूर्व महासभा का जो नया खाता संख्या हैं बैंक ऑफ बड़ौदा से
                  संचालित होगा वह आप सभी के लिए दिया जा रहा है। जिसका विवरण
                  निम्नानुसार है…...........
                </p>
                <p>AKHIL BHARAT VARSHIRY DIGAMBAR JAIN GOLAPOORV MAHASABHA</p>
                <p> BANK OF BARODA</p>
                <p>A/C:- 64890200000137</p>
                <p>IFSC:- BARB0VJSAMP</p>
                <hr />
              </div>
              <div class=" bankDetailsFont form-group col-md-12">
                <p>
                  सभी सम्मानीय महानुभाव से निवेदन है कि ऑनलाइन राशि जमा करने के
                  बाद अपना एवं पिताजी का नाम , राशि की रसीद एवं मोबाइल नंबर ,
                  श्री सुरेंद्र जी खुर्देलिया अथवा श्री महेंद्र जैन जी बहरोल के
                  व्हाट्सएप नंबर पर अवश्य भेजें
                </p>
                <hr />
              </div>
              <div className="row">
                <div class=" bankDetailsFont col-md-6">
                  <p>Contact Name: श्री महेंद्र कुमार जैन "बहरोल"</p>
                  <p>Contact Number/WhatsUp No: 9425452347</p>
                </div>
                <div class=" bankDetailsFont col-md-6">
                  <p>Contact Name: श्री सुरेन्द्र जैन खुर्देलिया</p>
                  <p>Contact Number/WhatsUp No: 9893198459</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMarriage;
